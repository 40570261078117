import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import * as Highcharts from 'highcharts';

import { ChartsConfig } from '../config-chart-pie';

export interface DataChart {
  title: string
  labels: Array<string>
  series: any
}

declare let require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html'
})

export class PieChartComponent implements OnInit, OnChanges{
  public _chartIndex = 'chartpie-' + Math.random().toString().slice(2, 18)
  public _validData: boolean = true;
  public _titulo: string = '';

  @Input() indexador: string;
  @Input() height: number;
  @Input() data: any;
  private dataInterna: any;
  @Input() title: string;

  @ViewChild("chart", { static: true }) lineChart: ElementRef;

  public chartConfig = new ChartsConfig()

  constructor() { }

  ngOnInit() {
    
    this.validData(this.data)
    this.carregaStrings()
    this.dataInterna = this.data
  }

  
  ngAfterViewInit () {
    this.fillChart();
  }

  fillChart() {
    if (this._validData) {
      this.chartConfig.options.series[0].data = this.data.series;
      Highcharts.chart(this.indexador, this.chartConfig.options);
      //Highcharts.chart(this._chartIndex, this.chartConfig.options);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    
    const data: SimpleChange = changes.pageConfig;
    //data.currentValue.title = data.currentValue.title
    //this.dataInterna = data.currentValue;
    // if (data) {
    //   this.validData(this.dataInterna)
    //   if (this._validData) {
    //     this.chartConfig.options.series[0].data = this.dataInterna.series;
    //     Highcharts.chart(this.indexador, this.chartConfig.options);
    //   } else {
    //     Highcharts.chart(this.indexador, {});
    //   }
    // } else {
    //   this.chartConfig.options.series[0].data = this.data.series;
    //   Highcharts.chart(this.indexador, this.chartConfig.options);
    // }
    this.fillChart();

  }

  carregaStrings () {
    
    if (this.title) {
        this._titulo = this.title
    }
  }
  
  

  validData(data: DataChart) {
    
    if (data) {
      if (this.isDataChartValid(data)) {
        this._validData = true
      } else {
        this._validData = false
      }
    } else {
      this._validData = false
    }
  }

  isDataChartValid(value: any) {
    
    //verifica se o tamanho do data e do labels tem o mesmo tamanho
    if (value.series) {
      return true
    } else {
      return false
    }
  }

}