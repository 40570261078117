import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-full-screen',
  templateUrl: './full-screen.component.html',
  
})
export class fullScreenComponent implements OnInit {
  btnText = 'Tela Cheia'
  body = document.getElementById('bodySystem')

  constructor() { }

  ngOnInit() {
  }



  fullScreen(){
    
    if (window.screenTop && window.screenY){
      this.btnText = 'Tela Cheia'
      document.exitFullscreen();
      this.body.classList.remove('sidebar-icon-only')      
    } else{
      this.btnText = 'Sair Tela Cheia'
      document.documentElement.requestFullscreen();
      // this.body.classList.add('sidebar-icon-only')   
    }
  }
}


