// modules shared
export * from './breadcrumbs/breadcrumbs.module'
export * from './button/back/back.module'
export * from './button/full-screen/full-screen.module'
export * from './pagination/pagination.module'
export * from './faq/faq.module'
export * from './gallery/gallery.module'
export * from './popup/popup.module'
export * from './pageTitle/pagetitle.module'
export * from './stretch-card/stretch-card.module'
export * from './timer/timer.module'
// export * from './dialog/dialog.module'

// components
// export * from './dialog/file-name-dialog.component'