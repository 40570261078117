export class ChartsConfig {
    //https://www.highcharts.com/demo
    colorLabels = '#7f7ba0'
    colorLabelsTxt = '#A0A0A3'
    colorTitleTxt = '#A0A0A3'
    colorSubTitleTxt = '#A0A0A3'
    colorBgChartStart = 'transparent'
    colorBgChartEnd = 'transparent'
    colorTooltipTxt = '#F0F0F0'
    colorTooltipBg = '#000000'
    colorLegendBg = 'rgba(0, 0, 0, 0.5)'
    colorLegendTxt = '#E0E0E3'
    colorLegendTxtHover = '#FFF'

    public options: any = {
        colors: [
            '#2d8dfd', '#09ada7', '#ff416b', '#7798BF', '#cabb44', '#ff0066',
            '#eeaaee', '#55BF3B', '#DF5353', '#7798BF', '#aaeeee'
        ],
        chart: {
            backgroundColor: {
                linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
                stops: [
                    [0, this.colorBgChartStart],
                    [1, this.colorBgChartEnd]
                ]
            },
            style: {
                fontFamily: '\'Unica One\', sans-serif',
            },
            type: 'pie',
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
          
            
        },
        title: {
            align: 'center',
            verticalAlign: 'middle',
            style: {
                color: this.colorTitleTxt,
                textTransform: 'uppercase',
                fontSize: '20px'
            },
            text: ''
        },
        subtitle: {
            style: {
                color: this.colorSubTitleTxt,
               
                textTransform: 'uppercase'
            }
        },
        
        tooltip: {
            backgroundColor: this.colorTooltipBg,
            style: {
                color: this.colorTooltipTxt
            },
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false
                },
                showInLegend: true
            }
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle',
      
            // backgroundColor: this.colorLegendBg,
            itemStyle: {
                color: this.colorLegendTxt
            },
            itemHoverStyle: {
                color: this.colorLegendTxtHover
            },
            itemHiddenStyle: {
                color: '#606063'
            },
            title: {
                style: {
                    color: '#C0C0C0'
                }
            }
        },
        credits: {
            style: {
                color: '#666',
                display: 'none'
            }
        },
        labels: {
            style: {
                color: '#707073'
            }
        },
        drilldown: {
            activeAxisLabelStyle: {
                color: '#F0F0F3'
            },
            activeDataLabelStyle: {
                color: '#F0F0F3'
            }
        },
        navigation: {
            buttonOptions: {
                symbolStroke: '#DDDDDD',
                theme: {
                    fill: '#505053'
                }
            }
        },
        // scroll charts
        rangeSelector: {
            buttonTheme: {
                fill: '#505053',
                stroke: '#000000',
                style: {
                    color: '#CCC'
                },
                states: {
                    hover: {
                        fill: '#707073',
                        stroke: '#000000',
                        style: {
                            color: 'white'
                        }
                    },
                    select: {
                        fill: '#000003',
                        stroke: '#000000',
                        style: {
                            color: 'white'
                        }
                    }
                }
            },
            inputBoxBorderColor: '#505053',
            inputStyle: {
                backgroundColor: '#333',
                color: 'silver'
            },
            labelStyle: {
                color: 'silver'
            }
        },
        navigator: {
            handles: {
                backgroundColor: '#666',
                borderColor: '#AAA'
            },
            outlineColor: '#CCC',
            maskFill: 'rgba(255,255,255,0.1)',
            series: {
                color: '#7798BF',
                lineColor: '#A6C7ED'
            },
            xAxis: {
                gridLineColor: '#505053'
            }
        },
        scrollbar: {
            barBackgroundColor: '#808083',
            barBorderColor: '#808083',
            buttonArrowColor: '#CCC',
            buttonBackgroundColor: '#606063',
            buttonBorderColor: '#606063',
            rifleColor: '#FFF',
            trackBackgroundColor: '#404043',
            trackBorderColor: '#404043'
        },
    
        series: [
            {
                innerSize: '70%',
                data: []
            }
        ]
      }
}
  