import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/auth/auth.guard';
import { AppGuard } from './core/guards/app.guard';

const appRoutes: Routes = [

  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',
  },
  {
    path: 'public',
    loadChildren: () => import('./layout/externo/appPublic.module').then(m => m.AppPublicModule),
  },
  
  { 
    path: 'login',
    loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule),
    canActivate: [AuthGuard]
  },
  { 
    path: 'app',
    loadChildren: () => import('./layout/interno/apploged.module').then(m => m.AppLogedModule),
    canActivate: [AppGuard],
    data: {
      breadcrumb: 'Home',
    }
  },
  

  /*   PAGINA 404 - redireciona quando cai em uma rota não existente   */
  {
    path: "**",
    loadChildren: () => import('./features/error404/error404.module').then(m => m.Error404Module)
  }

];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(appRoutes, {useHash: true})],
})
export class AppRoutingModule { }
