import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';

export interface Pagination {
  currentPage?: number
  nextPage?: string,
  previousPage?: string
  nextBtn?: boolean
  prevBtn?: boolean
}

export enum SearchMethod{
    Prev = 'Prev',
    Next = 'Next'
}

export interface PaginationConfig{
  pagination?: Pagination
    method?: string
}

@Component({
  selector: 'app-new-pagination',
  templateUrl: './new-pagination.component.html',
  styleUrls: ['./new-pagination.component.scss']
})
export class NewPaginationComponent implements OnInit, OnChanges {

  paginationConfig: PaginationConfig = {}
  @Input() pagination: Pagination;
  @Output() currentPage: EventEmitter<PaginationConfig> = new EventEmitter();

  constructor() { }

  ngOnInit() {

  }

  ngOnChanges(simpleChanges: SimpleChanges) { 
       
    this.configurePagination(<Pagination>simpleChanges.pagination.currentValue)
  }

  configurePagination(pagination: Pagination) {
    
    if(pagination.nextPage){
      this.pagination.nextPage = pagination.nextPage
      this.pagination.nextBtn = true
    }else{
      this.pagination.nextBtn = false
    }

    if(pagination.previousPage){
      this.pagination.previousPage = pagination.previousPage
      this.pagination.prevBtn = true
    }else{
      this.pagination.prevBtn = false
    }

    this.paginationConfig.pagination = this.pagination;

    // if (this.pagination.currentPage == totalPages) {
    //   this.pagination.nextBtn = false;
    //   if (this.pagination.currentPage > 1) {
    //     this.pagination.prevBtn = true
    //   }
    // } else if (this.pagination.currentPage < totalPages) {
    //   this.pagination.nextBtn = true;
    //   if (this.pagination.currentPage == 1) {
    //     this.pagination.prevBtn = false
    //   }
    // } else if (this.pagination.currentPage == 1) {
    //   this.pagination.prevBtn = false
    // }
  }

  configureCurrentPage(method) {
    // if (metodo == "prev") {
    //   this.pagination.currentPage--
    // } else if (metodo == "next") {
    //   this.pagination.currentPage++
    // } else {
    //   this.pagination.currentPage = 1
    // }
    
    const searchMethod = SearchMethod[method];
    this.paginationConfig.method = searchMethod
    this.currentPage.emit(this.paginationConfig)

  }

}
