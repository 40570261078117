import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { StorageService } from '../../core/storage/storage.service';


import { UtilsService } from '../../services/utils/utils.service';
import { AppStore } from '../../layout/interno/apploged.store';

// model
import { FilterLogsModal } from '../../models/FilterLogs';
import { GraficLine, GraficPie, GraficBar, LineSeries } from "../../models/Charts";

import { AddFilter } from '../../reducers/filter.actions';
import { MessageLogTotalGroup, TipoPesquisa } from 'src/app/features/monitor/messageLog/message-log.service';
import { SearchFilterMessageLog } from 'src/app/models/SearchFilterMessageLog';
import { Interval } from '../cadastros/instance-interval/instance-interval.service';
import { environment } from '../../../environments/environment';
import { filter } from 'rxjs/operators';


export interface FilterObj {
    Name: string,
    URL: string,
    FilterQuery: string,
    UserId: number
}

@Injectable({ providedIn: 'root' })

export class MonitorService {

    constructor(
        private storageService: StorageService,
        private httpClient: HttpClient,
        private utilsService: UtilsService,
        private appStore: AppStore,
        private store: Store<any>
        //private monitorStore: MonitorStore
    ) { }

    // dash

    verificaMenorIntervalo(intervals: Interval[]): Interval {

        let menorIntervalo
        if (intervals.some(interval => interval.Unity == 0)) {
            let unityHour = intervals.filter(function (inter) {
                return inter.Unity == 0
            })
            menorIntervalo = unityHour.sort((a, b) => (a.Value > b.Value) ? 1 : -1)
        } else {
            let unityDay = intervals.filter(function (inter) {
                return inter.Unity == 1
            })
            menorIntervalo = unityDay.sort((a, b) => (a.Value > b.Value) ? 1 : -1)
        }
        return menorIntervalo[0];

        //   //atribui o value do primeiro intervalo na variavel valueMenorIntervalo
        //   let valueMenorIntervalo = intervals[0].Value;
        //   let indexMenorIntervalo = 0

        //   //verifica se há intervalos de dias e horas
        //   let temIntervaloDeHoras = intervals.some(interval=> interval.Unity == 0)
        //   let temIntervaloDeDias = intervals.some(interval=> interval.Unity == 1)

        //   if(temIntervaloDeHoras && temIntervaloDeDias){
        //     for(let i = 0; i< intervals.length; i++){  

        //       let value = (intervals[i].Unity == 1? intervals[i].Value * 24: intervals[i].Value)

        //       //verifica se o Value atual é menor, do que Value anterior
        //       if(valueMenorIntervalo >= value ){

        //         valueMenorIntervalo = value
        //         indexMenorIntervalo = i;

        //       }
        //     }
        //   }else{
        //     for(let i = 0; i< intervals.length; i++){
        //       //verifica se o Value atual é menor, do que Value anterior
        //       if(valueMenorIntervalo >= intervals[i].Value ){
        //         valueMenorIntervalo = intervals[i].Value
        //         indexMenorIntervalo = i;
        //       }
        //     }
        //   }

        //return intervals[indexMenorIntervalo];

    }

    //verifica se as propriedade do SearchFilterMessageLog estão vazias
    checkSearchFilterMessageLogProperties(searchFilterMessageLog: SearchFilterMessageLog): boolean {
        let isEmpty: boolean
        if (searchFilterMessageLog) {
            let searchFilterMessageLogCopy: SearchFilterMessageLog = { ...searchFilterMessageLog }
            delete searchFilterMessageLogCopy.interval
            isEmpty = Object.values(searchFilterMessageLogCopy).every(
                x => {

                    return x == null || x == '' || x.length == 0
                });
        } else {
            isEmpty = true
        }

        return isEmpty;
    }

    setNewFilterSavedSearch(idSavedSearch: Number) {

        this.appStore.getResultSearch().subscribe(
            (res: any) => {

                let searchs = res
                let searchById = searchs.filter(x => x.id == idSavedSearch)
                //console.log('_____________________________________',idSavedSearch, searchById)
                if (searchById.lenght > 0) {
                    this.store.dispatch(AddFilter(searchById[0].filter));
                    // this.appStore.setResultSearch(searchById[0])
                }
            },
            err => {
                this.utilsService.showAlert('E', 'Erro', '', err);
            }
        );

        //this.store.dispatch(AddFilter(filter));
    }

    setIdIntegrationOnFilter(idIntegrarion: number, filter: FilterLogsModal) {
        filter.integrationsId = []
        filter.integrationsId.push(idIntegrarion)
        //this.appStore.setResFilterLogs(filter);
        this.store.dispatch(AddFilter(filter));
    }

    getIntegrationsFlows(filter: FilterLogsModal) {
        //this.appStore.setResFilterLogs(filter);
        //this.store.dispatch(AddFilter(filter));

        if (filter.period) {
            // return this.httpClient.get(`${environment.apiUrl}http://localhost:4200/assets/mockJson/dash/flow${filter.period}.json`)
            return this.httpClient.get(`${environment.apiUrl}${this.utilsService.CONSTANTS.MOOKENDPOINT.INTEGRATIONSFLOWS}${filter.period}.json`)
        }
        return this.httpClient.get(`${environment.apiUrl}${this.utilsService.CONSTANTS.MOOKENDPOINT.INTEGRATIONSFLOWS}.json`)
    }

    getIntegrations(userId) {
        return this.httpClient.post(this.utilsService.CONSTANTS.ENDPOINT.GETINTEGRANTIONSGROUP, { UserId: userId })
    }

    getChartPie(id) {
        return this.httpClient.post(this.utilsService.CONSTANTS.ENDPOINT.CHARTPIE, { IntegrationId: id })
    }

    getChartLine(id) {
        return this.httpClient.post(this.utilsService.CONSTANTS.ENDPOINT.CHARTLINE, { IntegrationId: id })
    }

    setPieChartDataFromIntegrations(listIntegrations: any) {

        let pieGrafic: GraficPie = new GraficPie()
        pieGrafic.series = [];

        pieGrafic.title = "Processos por Integração"
        listIntegrations.forEach(element => {
            pieGrafic.series.push(
                {
                    "name": element.Name,
                    "y": element.Processando + element.Sucesso + element.Reprocessar + element.Erro + element.Descartado
                }
            )
        });
        return pieGrafic
    }

    setPieChartDataFromMessageLogTotal(messageLogTotal: MessageLogTotalGroup[], tipoPesquisa?: TipoPesquisa) {

        let pieGrafic: GraficPie = new GraficPie()
        pieGrafic.series = [];

        // pieGrafic.title = "Total Mensagens por Fluxo"
        messageLogTotal.forEach(element => {
            pieGrafic.series.push(
                {
                    "name": (tipoPesquisa == TipoPesquisa.TOTALGROUP ? element.InstanceFlowGroupName : element.InstanceFlowName
                    ),
                    "y": element.BrCampos['Total']
                }
            )
        });
        return pieGrafic
    }

    setBarChartDataFromIntegrations(listIntegrations: any) {

        let barGrafic: GraficBar = new GraficBar()
        barGrafic.series = [];
        barGrafic.typeValue = '';

        // barGrafic.title = "Quantidade de erros por Integração"
        listIntegrations.forEach(element => {
            barGrafic.series.push(
                [
                    element.Name,
                    element.Erro
                ]
            )
        });
        return barGrafic
    }

    setBarChartDataFromMessageLogTotal(messageLogTotal: MessageLogTotalGroup[], tipoPesquisa?: TipoPesquisa) {

        let barGrafic: GraficBar = new GraficBar()
        barGrafic.series = [];
        barGrafic.typeValue = '';

        // barGrafic.title = "Quantidade de erros por Fluxo"
        messageLogTotal = messageLogTotal.sort((a, b) => b.TotalErro - a.TotalErro);
        messageLogTotal.forEach(element => {
            barGrafic.series.push(
                [
                    (tipoPesquisa == TipoPesquisa.TOTALGROUP ? element.InstanceFlowGroupName : element.InstanceFlowName),
                    element.TotalErro
                ]
            )
        });


        return barGrafic
    }


    getMessageLogs(filter: FilterLogsModal) {
        //this.appStore.setResFilterLogs(filter);
        if (filter.period) {
            // return this.httpClient.get(`${environment.apiUrl}http://localhost:4200/assets/mockJson/dash/messageLog${filter.period}.json`)
            return this.httpClient.get(`${environment.apiUrl}${this.utilsService.CONSTANTS.MOOKENDPOINT.INTEGRATIONSMESSAGELOGS}${filter.period}.json`)
        }
        //return this.httpClient.get(this.utilsService.CONSTANTS.MOOKENDPOINT.INTEGRATIONSMESSAGELOGS  + "1.json")
    }

    saveFilter(filterObj: FilterObj) {
        if (filterObj.URL == 'flow-group-panel') {
            return this.httpClient.post(`${environment.apiUrl}${this.utilsService.CONSTANTS.ENDPOINT.FILTER}`, filterObj);
        } else {
            let userName = 'omni.linker';
            let password = '@Admin00'
            const httpHeaders = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': `Basic ${btoa(`${userName}:${password}`)}`,
                'Token': `${this.storageService.getStorageToken()}`
            });
            return this.httpClient.post(`${environment.apiUrl}${this.utilsService.CONSTANTS.ENDPOINT.FILTER}`, filterObj, { headers: httpHeaders });
        }
        // const httpHeaders = new HttpHeaders ({
        //     'Content-Type': 'application/json',
        //     'Token': `${this.storageService.getStorageToken()}` 
        // });        
        // return this.httpClient.post(`${environment.apiUrl}${this.utilsService.CONSTANTS.ENDPOINT.FILTER}`, filterObj, { headers: httpHeaders });        




        //
    }

    getAllFilters(url, userId) {

        //?InstanceId=${this.utilsService.getInstanceLoginId()}&UserId=1&URL=flow-group-panel
        if (url == 'flow-group-panel') {
            return this.httpClient.get(`${environment.apiUrl}${this.utilsService.CONSTANTS.ENDPOINT.FILTER}?InstanceId=${this.utilsService.getInstanceLoginId()}&UserId=${userId}&URL=${url}`)
        } else {
            let userName = 'omni.linker';
            let password = '@Admin00'
            const httpHeaders = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': `Basic ${btoa(`${userName}:${password}`)}`,
                'Token': `${this.storageService.getStorageToken()}`
            });

            return this.httpClient.get(`${environment.apiUrl}${this.utilsService.CONSTANTS.ENDPOINT.FILTER}?InstanceId=${this.utilsService.getInstanceLoginId()}&UserId=${userId}&URL=${url}`, { headers: httpHeaders })
        }
    }

    deleteFilters(Id: number, url: string) {
        if (url == 'flow-group-panel') {
            return this.httpClient.delete(`${environment.apiUrl}${this.utilsService.CONSTANTS.ENDPOINT.FILTER}?Id=${Id}`);
        }
        else {
            let userName = 'omni.linker';
            let password = '@Admin00'
            const httpHeaders = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': `Basic ${btoa(`${userName}:${password}`)}`,
                'Token': `${this.storageService.getStorageToken()}`
            });
            return this.httpClient.delete(`${environment.apiUrl}${this.utilsService.CONSTANTS.ENDPOINT.FILTER}?Id=${Id}`, { headers: httpHeaders });
        }

    }
}