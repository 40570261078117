import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CONSTANTS } from 'src/app/core/constants/constants';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { environment } from '../../../../environments/environment';


export interface InstanceSystem {
  Id?: number,
  Name?: string,
  DevelopmentId?: number,
  DevelopmentName?: string,
  InstanceId?: number,
  InstanceName?: string,
  StandardSystemId?: number,
  StandardSystemName?: string
}

@Injectable({
  providedIn: 'root'
})
export class InstanceSystemService {

  constructor(
    private httpClient: HttpClient,
    private utilsService: UtilsService
  ) { }

  getInstanceSystemListByInstance() {
    return this.httpClient.get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.INSTANCESYSTEM}?InstanceId=${this.utilsService.getInstanceLoginId()}`, { observe: 'response' })
  }

  getInstanceSystemList(page: string) {
    
    if (!page) {
      page = `?InstanceId=${this.utilsService.getInstanceLoginId()}&Page=1`
    }

    return this.httpClient.get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.INSTANCESYSTEM}${page}`, { observe: 'response' })
  }

  getInstanceSystemListAll() {
    return this.httpClient.get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.INSTANCESYSTEM}`, { observe: 'response' })
  }

  getInstanceSystem(instanceSystem: InstanceSystem, page: string) {
    
    let filtroFinal
    if (page) {
      filtroFinal = page
    } else {
      filtroFinal = `?InstanceId=${this.utilsService.getInstanceLoginId()}&Page=1&Name=${(instanceSystem.Name ? instanceSystem.Name : ``)}&DevelopmentId=${(instanceSystem.DevelopmentId != null ? instanceSystem.DevelopmentId : ``)}&StandardSystemId=${(instanceSystem.StandardSystemId ? instanceSystem.StandardSystemId : ``)}`
    }
    return this.httpClient.get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.INSTANCESYSTEM}${filtroFinal}`, { observe: 'response' })
  }

  updateInstanceSystem(instanceSystem: InstanceSystem) {
    let tempInstance: InstanceSystem = this.deleteInstanceSystemProperties(instanceSystem, 'put')
    tempInstance.InstanceId = this.utilsService.getInstanceLoginId()
    return this.httpClient.put(`${environment.apiUrl}${CONSTANTS.ENDPOINT.INSTANCESYSTEM}`, tempInstance, { observe: 'response' })
  }

  deleteInstanceSystem(Id: number) {
    return this.httpClient.delete(`${environment.apiUrl}${CONSTANTS.ENDPOINT.INSTANCESYSTEM}?Id=${Id}`, { observe: 'response' })
  }

  postInstanceSystem(instanceSystem: InstanceSystem) {
    
    let tempInstance: InstanceSystem = this.deleteInstanceSystemProperties(instanceSystem, 'post')
    tempInstance.InstanceId = this.utilsService.getInstanceLoginId()
    return this.httpClient.post(`${environment.apiUrl}${CONSTANTS.ENDPOINT.INSTANCESYSTEM}`, tempInstance, { observe: 'response' })
  }


  private deleteInstanceSystemProperties(instanceSystem: InstanceSystem, metodo: string): InstanceSystem {

    // if (metodo == 'post') {
    //   delete instanceSystem.Id;
    // }

    delete instanceSystem.DevelopmentName;
    delete instanceSystem.StandardSystemName;
    delete instanceSystem.InstanceName;
    (instanceSystem.DevelopmentId == null ? delete instanceSystem.DevelopmentId : '')


    return instanceSystem
  }

  private createFiltroGetInstanceSystem(instanceSystem: InstanceSystem, page: string): string {
    let filtroFinal
    if (page) {
      filtroFinal = page.replace('?', '&')
    }else{
      filtroFinal = `&Name=${(instanceSystem.Name ? instanceSystem.Name : ``)}&DevelopmentId=${(instanceSystem.DevelopmentId != null ? instanceSystem.DevelopmentId : ``)}&StandardSystemId=${(instanceSystem.StandardSystemId ? instanceSystem.StandardSystemId : ``)}`
    }

    
    return filtroFinal
  }
}
