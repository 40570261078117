import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GalleryComponent } from './gallery.component';

@NgModule({
  declarations: [ GalleryComponent ],
  exports: [ GalleryComponent ],
  imports: [
    CommonModule
  ]
})

export class AppGalleryModule { }