import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CONSTANTS } from 'src/app/core/constants/constants';
import { InstanceSystem } from '../instance-system/instance-system.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { environment } from '../../../../environments/environment';

// import jsonIcon from '../../../../assets/mockJson/fontAwesome-icon-list.json'



export interface Icon {
  name: string,
  class: string,
}

export interface InstanceFlow {
  Id: string,
  Name: string,
  Code: string,
  InstanceId: number,
  InstanceName: string,
  SourceSysId: number,
  SourceSysName: string,
  TargetSysId: number,
  TargetSysName: string,
  GroupId: number,
  GroupName: string
}

@Injectable({
  providedIn: 'root'
})
export class InstanceFlowService {
 


  constructor(
    private httpClient: HttpClient,
    private utilsService: UtilsService
  ) { }

  getInstanceFlowListByInstance() {
    return this.httpClient
      .get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.INSTANCEFLOW}?InstanceId=${this.utilsService.getInstanceLoginId()}`, { observe: 'response' })
  }

  getInstanceFlowListByInstanceByFlowGroup(groups: any[]) {
    let filtro = ""
    if(groups.length > 0){
       filtro = `&NotGroupIds=${groups.join(',')}`
    }
    return this.httpClient
      .get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.INSTANCEFLOW}?InstanceId=${this.utilsService.getInstanceLoginId()}${filtro}`, { observe: 'response' })
  }

  getInstanceFlowListAll() {
    return this.httpClient
      .get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.INSTANCEFLOW}?InstanceId=${this.utilsService.getInstanceLoginId()}`, { observe: 'response' })
  }


  getInstanceFlowList(page: string) {
    if (!page) {
      page = `?InstanceId=${this.utilsService.getInstanceLoginId()}&Page=1`
    }

    return this.httpClient
      .get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.INSTANCEFLOW}${page}`, { observe: 'response' })
  }

  getInstanceFlow(instanceFlow: InstanceFlow, page) {
    let filtro = this.createFiltroGetInstanceFlow(instanceFlow, page)
    return this.httpClient.get<any>(`${environment.apiUrl}${CONSTANTS.ENDPOINT.INSTANCEFLOW}?InstanceId=${this.utilsService.getInstanceLoginId()}${filtro}`, { observe: 'response' });
  }

  getFonAwesomeIconList() {
    return this.httpClient.get<any>(`/assets/mockJson/${CONSTANTS.MOOKENDPOINT.FONTAWESOMELIST}`);
  }


  private createFiltroGetInstanceFlow(instanceFlow: InstanceFlow, page: string): string {
    let filtroFinal
    if (page) {
      filtroFinal = page.replace('?', '&')
    } else {
      filtroFinal = `&Name=${(instanceFlow.Name ? instanceFlow.Name : ``)}&Code=${(instanceFlow.Code ? instanceFlow.Code : '')}&SourceSysId=${(instanceFlow.SourceSysId ? instanceFlow.SourceSysId : ``)}&TargetSysId=${(instanceFlow.TargetSysId ? instanceFlow.TargetSysId : ``)}&GroupId=${(instanceFlow.GroupId ? instanceFlow.GroupId : ``)}`
    }

    return filtroFinal
  }

  updateInstanceFlow(instanceFlow: InstanceFlow) {
    let tempInstanceFlow = this.deleteInstanceFlowProperties(instanceFlow, 'put')
    return this.httpClient.put(`${environment.apiUrl}${CONSTANTS.ENDPOINT.INSTANCEFLOW}`, tempInstanceFlow, { observe: 'response' });
  }

  deleteInstanceFlow(Id: string) {
    return this.httpClient.delete(`${environment.apiUrl}${CONSTANTS.ENDPOINT.INSTANCEFLOW}?Id=${Id}`, { observe: 'response' });
  }

  postInstanceFlow(instanceFlow: InstanceFlow) {

    let tempInstanceFlow = this.deleteInstanceFlowProperties(instanceFlow, 'post')
    tempInstanceFlow.InstanceId = this.utilsService.getInstanceLoginId()
    return this.httpClient.post(`${environment.apiUrl}${CONSTANTS.ENDPOINT.INSTANCEFLOW}`, tempInstanceFlow, { observe: 'response' });
  }

  private deleteInstanceFlowProperties(instanceFlow: InstanceFlow, metodo: string): InstanceFlow {

    if (metodo == 'post') {
      delete instanceFlow.Id;
    }

    delete instanceFlow.InstanceName;
    delete instanceFlow.GroupName;
    delete instanceFlow.SourceSysName;
    delete instanceFlow.TargetSysName;
    // (instanceFlow.DevelopmentId == null ? delete instanceFlow.DevelopmentId : '')


    return instanceFlow
  }

}