import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fechar-dialog',
  templateUrl: './fechar-dialog.component.html'
})
export class FecharDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
