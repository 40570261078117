import { Component, OnInit, Input, ViewChild, ElementRef, SimpleChanges, SimpleChange } from '@angular/core';
import * as Highcharts from 'highcharts';

import { ChartsConfig } from '../config-chart-bar-simple';

export interface DataChart {
  title: string
  labels: Array<string>
  series: any
}

declare let require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  selector: 'app-simplebar-chart',
  templateUrl: './bar-chart.component.html',  
})

export class SimpleBarChartComponent implements OnInit {
  public _chartIndex = 'chartsimplebar-' + Math.random().toString().slice(2, 18)
  public _validData: boolean = true;
  public _titulo: string = '';

  @Input() indexador: string;
  @Input() height: number;
  @Input() data: any;
  @Input() title: string;
  @Input() ylabel: string;

  @ViewChild("chart", { static: true }) lineChart: ElementRef;

  public chartConfig = new ChartsConfig()

  constructor() { }

  ngOnInit() {
    this.validData(this.data)
    this.carregaStrings();
  }
  
  ngAfterViewInit () {
    this.fillChart();
  }

  fillChart() {
    if (this._validData) {
      this.chartConfig.options.series[0].data = this.data.series
      this.chartConfig.options.tooltip.pointFormat = this.chartConfig.options.tooltip.pointFormat + ' ' + this.data.typeValue
      Highcharts.chart(this.indexador, this.chartConfig.options);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.fillChart();
  }

  carregaStrings () {
    if (this.title) {
      this._titulo = this.title
    }
    if (this.ylabel) {
      this.chartConfig.options.yAxis.title.text = this.ylabel
      this.chartConfig.options.tooltip.pointFormat = this.ylabel + ': <b>{point.y:.1f}</b>'
    }
  }

  validData(data: DataChart) {
    if (data) {
      if (this.isDataChartValid(data)) {
        this._validData = true
      } else {
        this._validData = false
      }
    } else {
      this._validData = false
    }
  }


  isDataChartValid(value: any) {
    
    //verifica se o tamanho do data e do labels tem o mesmo tamanho
    if (value.series) {
      return true
    } else {
      return false
    }
  }

}