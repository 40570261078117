import { Directive, HostListener, Input, ElementRef } from '@angular/core';

@Directive({
  selector: '[appDateMask]',
  host: {"(input)": 'onInputChange($event)'}
})

export class DateMaskDirective {

  @Input('appDateMask') params: string;
  
  constructor(public el: ElementRef) { }

  @HostListener('ngModelChange', ['$event'])
  ngOnInit(): void {
    this.el.nativeElement.maxLength = 10
  }
  
  onInputChange(event) {
    if (typeof event == 'string') {
      this.setInputChange(event, false);
    } else {
      this.setInputChange(event.target.value, false);
    }
  }

  /** @description verifica se o mes tem 31 dias retorna true ou false
	* @param {mes} mes como string ex: 02
	*/
  mesLongo(mes: string) {
    if (mes == '01' || mes == '03' || mes == '05' || mes == '07' || mes == '08' || mes == '10' || mes == '12') {
      return true
    }
    return false
  }

  /** @description verifica se o ano é bisesto
	* @param {fullyear} fullyear como string ex: 02
	*/
  isLeapYear (fullyear: any) {
    let ano = fullyear.substr(4,4)
    let day = fullyear.substr(0,2)
    let mouth = fullyear.substr(2,2)
    let leapYear = ((ano % 4 == 0) && ((ano % 100 != 0) || (ano % 400 == 0)))
    if (day > 28 && mouth == '02' && !leapYear) {
      return true
    }
    return false;
  }

  setInputChange(event, backspace) {
    let newVal = event.replace(/\D/g, '');
    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 1) { // valida primeiro digito do dia
      if (newVal > 3) {
        newVal = newVal.replace(/[4-9]/g, '0' + newVal).replace(/^(\d{0,2})/, '$1');
      } else {
        newVal = newVal.replace(/^(\d{0,2})/, '$1');
      }
    } else if (newVal.length <= 2) { // valida segundo digito do dia
      if (newVal.substr(0,1) > 0 && newVal.substr(0,1) < 3) {
        newVal = newVal.replace(/^(\d{0,2})/, '$1');
      } else if (newVal.substr(0,1) == 0) {
        if (newVal.substr(1,1) == 0) {
          newVal = newVal.substr(0,1).replace(/^(\d{0,2})/, '$1');
        } else {
          newVal = newVal.replace(/^(\d{0,2})/, '$1');
        }
      } else {
        if (newVal.substr(1,1) > 1) {
          newVal = newVal.substr(0,1).replace(/^(\d{0,2})/, '$1');
        } else {
          newVal = newVal.replace(/^(\d{0,2})/, '$1');
        }
      }
    } else if (newVal.length == 3) { // valida primeiro digito do mes
      if (newVal.substr(2,1) > 1) {
        newVal = newVal.substr(0,2).replace(/^(\d{0,2})(\d{0,2})/, '$1/$2');
      } else {
        newVal = newVal.replace(/^(\d{0,2})(\d{0,2})/, '$1/$2');
      }
    } else if (newVal.length == 4) { // valida segundo digito do mes
      if (newVal.substr(2,2) === '00') {
        newVal = newVal.substr(0,3).replace(/^(\d{0,2})(\d{0,2})/, '$1/$2');
      } else if (newVal.substr(0,2) > 29 && newVal.substr(2,2) == '02') {
        newVal = newVal.substr(0,3).replace(/^(\d{0,2})(\d{0,2})/, '$1/$2');
      } else {
        if (newVal.substr(0,2) > 30) {
          if (this.mesLongo(newVal.substr(2,2))) {
            newVal = newVal.replace(/^(\d{0,2})(\d{0,2})/, '$1/$2');
          } else {
            newVal = newVal.substr(0,3).replace(/^(\d{0,2})(\d{0,2})/, '$1/$2');
          }
        } else {
          newVal = newVal.replace(/^(\d{0,2})(\d{0,2})/, '$1/$2');
        }
      }
    } else {
      if (newVal.length == 8) {
        if (this.isLeapYear(newVal)) { // valida se a data é válida referente ao ano bissexto
          newVal = newVal.substr(0,7).replace(/^(\d{0,2})(\d{0,2})(\d{0,4})/, '$1/$2/$3');
        } else {
          newVal = newVal.replace(/^(\d{0,2})(\d{0,2})(\d{0,4})/, '$1/$2/$3');
        }
      } else {
        newVal = newVal.replace(/^(\d{0,2})(\d{0,2})(\d{0,4})/, '$1/$2/$3');
      }
    } 
    this.el.nativeElement.value = newVal
  }

}