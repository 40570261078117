import { Component } from '@angular/core';
// I import Location so that I can query the current path
import { Location } from "@angular/common";
// I also import Router so that I can subscribe to events
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})

export class BreadcrumbsComponent  {
  rotas: any = [];

  constructor(
    location: Location, 
    router: Router,
    activatedRoute: ActivatedRoute
  ) { 
    router.events.subscribe(val => {

      if (location.path() != "") {        
        
        let rotas = router.routerState.snapshot.url.split("/")

        this.rotas = rotas.filter((x) => {
          if (x != '' && x != 'app') {
            return x
          }
        }).map((x) => {
          return x.replace(/-/g, " ")
        })

      } else {
        //this.route = "Home";
      }
    });
  }

}
