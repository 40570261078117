<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{popupLabel}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true" class="text-light">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        {{popupContent}}
    </div>
</ng-template>

<!-- botao abrir modal -->
<button class="btn btn-info btn-sm ml-2" (click)="open(content)">{{popupLabel}}</button>