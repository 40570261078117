<form [formGroup]="qtdMessages" class="mt-2">
  <div class="form-group d-flex flex-row align-items-center">
    <label class="m-0 p-0">{{ 'DEFAULT.CHANGEQTY' | translate}}</label>
    <mat-form-field class="ml-2" style="width: 70px;">
      
      <mat-select formControlName="number" (selectionChange)="configureQtdPerPage($event.value)">
        <mat-option *ngFor="let num of pageOptions" [value]="num">{{num}}</mat-option>

      </mat-select>
    </mat-form-field>
  </div>
</form>
<!-- 

   <mat-select formControlName="ProfileId" (selectionChange)="getLevelList($event.value)"
                [compareWith]="utilsService.compareValues">
                <mat-option *ngFor="let profileType of profileTypes" [value]="profileType.Id">
                 
                  {{ language == 'pt' ? profileType.BrName : profileType.UsName }}

                </mat-option>
              </mat-select>
 -->