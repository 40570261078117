import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

// import { Observable } from 'rxjs';
// import { Store, select } from '@ngrx/store';
// import { ClearFilter, AddFilter, Remove, Clear, Add } from './reducers/filter.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  //filter$: Observable<any>;

  constructor(
    private router: Router,
    public translate: TranslateService,
    //private store: Store<any>
  ) { 
    translate.addLangs(['en', 'pt']);
    translate.setDefaultLang('pt');
    const browserLang = translate.getBrowserLang();
    //translate.use('pt');
    translate.use(browserLang.match(/en|pt/) ? browserLang : 'pt');
    localStorage.setItem('appLanguage','pt') 
    //this.filter$ = store.pipe(select('filter'));
  }

  // add() {
  //   let item = {teste: "foi", idade: 5}
  //   this.store.dispatch(Add(item));
  // }

  // addFilter() {
  //   let item = {
  //     period: 12,
  //     intergrationsId: [1,2,3],
  //     sites: [1,2,3]
  //   }
  //   this.store.dispatch(AddFilter(item));
  // }
  
  title = 'projetobase';
}
