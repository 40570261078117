<!-- <div class="row">
    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <div class="form-group">
            <mat-form-field>
                <mat-select (selectionChange)="changeLanguage($event)">
                    <mat-option *ngFor="let language of languages" [value]="language.id"><img src="/assets/images/{{language.id}}.jpeg" alt=""> {{language.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div> -->
<li class="form-group pb-0 w-100  d-flex align-items-center">

    <mat-form-field [class]="selectedLanguage">
        <mat-select (selectionChange)="changeLanguage($event)" [(ngModel)]="selectedLanguage">

            <mat-option *ngFor="let language of languages" [value]="language.id" class="txt-language">
                <img width="30" height="21" src="/assets/images/{{language.id}}.jpeg" alt=""> {{language.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>

</li>