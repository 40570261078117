import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// modulos
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
// import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';

import { CoreModule } from './core/core.module';
import { AngularMaterialModule } from './shared/angular-material.module';
import { NgxLoadingModule } from 'ngx-loading';
import { SharedModule } from './shared/shared.module';


//components

// serviços
import { LoaderService } from './components/loader/loader.service';
import { RequestInterceptor } from './core/auth/request.interceptor';


///em teste
import { StoreModule } from '@ngrx/store';
import { filterReducer, integrationsReducer } from './reducers';
//import { reducers, metaReducers } from './reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { filterLogReducer } from './reducers/filter-logs.reducer';
import { instanceLoginReducer } from './reducers/instance-login.reducer';
import { filterFlowGroupPanelReducer } from './reducers/filter-flow-group-panel.reducer';
import { API_BASE_URL, ConfigFactory, ConfigService } from './services/config.service';

///em teste

registerLocaleData(localePt, 'pt-BR');

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [ AppComponent],
  imports: [
    CoreModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    AngularMaterialModule,
    SharedModule,
    NgbModule,
    StoreModule.forRoot({
      // filterLogs: filterLogReducer,
      // // filterFlowGroupPanel: filterFlowGroupPanelReducer,
      // filter: filterReducer,
      // integrations: integrationsReducer,
      // instanceLogin: instanceLoginReducer
    }),
    // StoreModule.forRoot(reducers, {
    //   metaReducers,
    //   runtimeChecks: {
    //     strictStateImmutability: true,
    //     strictActionImmutability: true,
    //     strictActionSerializability: true,
    //     strictStateSerializability:true
    //   }
    // }),
    StoreDevtoolsModule.instrument({ maxAge: 5, logOnly: environment.production }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    NgxMaterialTimepickerModule.setLocale('pt-BR') ,
    NgxLoadingModule.forRoot({})
  ],
  exports: [
    AngularMaterialModule,
    // SharedModule
  ],
  providers: [
    CookieService,
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    //{ provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: 'CONFIGPATH', useValue: '/assets/config.json' },
    { provide: 'APIURL-VAR', useValue: 'API_BASE_URL' },
    {
      provide: API_BASE_URL, useFactory: ConfigFactory,
      deps: [ConfigService, 'CONFIGPATH', 'APIURL-VAR']
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }