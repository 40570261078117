import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { CONSTANTS } from 'src/app/core/constants/constants';
import { SearchFilterMessageLog } from 'src/app/models/SearchFilterMessageLog';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/core/storage/storage.service';
import { Instance } from '../../cadastros/instancia/instancia.component';
import { ReferenceFieldType } from 'src/app/models/ReferenceFieldType';
import { VisualizationTypes } from './filter-logs/filter-logs.component';
import { InstanceFlowRefField } from '../../cadastros/instance-flow-reference-field/instance-flow-reference-field.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { MonitorService } from '../monitor.service';
import { environment } from '../../../../environments/environment';


export interface MessageLogTotalGroup {
  SearchFilterMessageLog?: SearchFilterMessageLog
  VisualizationTypes?: VisualizationTypes
  InstanceFlowGroupId?: number,
  InstanceFlowGroupIcone?: string,
  InstanceFlowGroupName?: string,
  InstanceFlowName?: string,
  InstanceFlowId?: number,
  TotalErro?: number,
  SourceSysId?: number
  SourceSysName?: string
  TargetSysId?: number,
  TargetSysName?: string
  Color?: string,
  BrCampos?: any
  UsCampos?: any
}

export interface MessageLogTotalResult {
  InstanceFlowGroupID?: number
  InstanceFlowId?: string,
  InstanceFlowIcone?: string,
  InstanceFlowGroupName?: string,
  InstanceFlowName?: string,
  SourceSysName?: string,
  TargetSysName?: string,
  TotProcessing?: number,
  TotSuccess?: number,
  TotRetryable?: number,
  TotFinished?: number,
  TotError?: number,
  TotDiscarded?: number,
  Total?: number,
  Color?: string
  Campos?: any
}

//Descarte
export enum TipoPesquisa {
  TOTALGROUP = 'TotalGrupo',
  TOTAL = 'Total',
  DETAILS = 'Detalhe',
  DETAILSMESSAGE = 'DetalheMensagem'
}

@Injectable({
  providedIn: 'root'
})
export class MessageLogService {

  instanceLoginId: number = 1

  constructor(
    private httpClient: HttpClient,
    private store: Store<any>,
    private router: Router,
    private storage: StorageService,
    private utilsService: UtilsService
  ) {
    // this.store.pipe(select('instanceLogin'))
    //   .subscribe(data => {
    //     
    //     if(data.instanceId){
    //       this.instanceLoginId = data.instanceId
    //     }else{
    //       this.router.navigate['login/instance-login']
    //     }

    //   })
  }

  getMessageLogDetails(filtro?: SearchFilterMessageLog, page?: string) {    
    let filtroFinal
    if (page) {
      
      filtroFinal = page.replace('?', '&')
    } else {
      if (!filtro) {
        
        filtroFinal = `&Page=1&Tipo=${TipoPesquisa.DETAILSMESSAGE}`
      } else {
        
        filtroFinal = this.createMessageLogFilter(filtro, filtro.statusIds && filtro.statusIds.find(e => e == 8) ? TipoPesquisa.DETAILS : TipoPesquisa.DETAILSMESSAGE)
      
      }
    }

    return this.httpClient.get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.MESSAGELOG}?InstanceId=${this.utilsService.getInstanceLoginId()}${filtroFinal}`, { observe: 'response' })
  }

  getMessageLog(tipoPesquisa: TipoPesquisa, filtro?) {

    if (!filtro) {
      filtro = `&Tipo=${tipoPesquisa}`
    } else {
      filtro = this.createMessageLogFilter(filtro, tipoPesquisa)
    }

    return this.httpClient.get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.MESSAGELOG}?InstanceId=${this.utilsService.getInstanceLoginId()}${filtro}`, { observe: 'response' })
  }

  createMessageLogFilter(filtro: SearchFilterMessageLog, tipo: string): string {
    let filtroFinal = `&Tipo=${tipo}`
    if (filtro.dateStart || filtro.dateEnd || filtro.hourStart || filtro.hourEnd) {
      filtro.dateStart ? filtroFinal += `&DataInicial=${this.configureDateFilter(filtro.dateStart)}` : ''
      filtro.dateEnd ? filtroFinal += `&DataFinal=${this.configureDateFilter(filtro.dateEnd)}` : ''
      filtro.hourStart ? filtroFinal += `&HoraInicial=${filtro.hourStart + ':00'}` : ''
      filtro.hourEnd ? filtroFinal += `&HoraFinal=${filtro.hourEnd + ':59'}` : ''
    } else {
      filtro.interval ? filtroFinal += `&Interval=${filtro.interval}` : ''
      filtro.intervalUnity != null ? filtroFinal += `&IntervalUnity=${filtro.intervalUnity}` : '' 
    }
    filtro.QtdeLin ? filtroFinal += `&QtdeLin=${filtro.QtdeLin}` : ''
    filtro.siteGroups ? filtroFinal += `${this.utilsService.removeAllOptionsV2(filtro.siteGroups,'SiteGroupIds')}` : ''
    filtro.sites ? filtroFinal += `${this.utilsService.removeAllOptionsV2(filtro.sites,'SiteIds')}` : ''
    filtro.flowGroups ? filtroFinal += `${this.utilsService.removeAllOptionsV2(filtro.flowGroups,'InstanceFlowGroupIds')}` : ''
    filtro.instanceFlows ? filtroFinal += `${this.utilsService.removeAllOptionsV2(filtro.instanceFlows,'InstanceFlowIds')}` : ''
    filtro.instanceSystemSource ? filtroFinal += `${this.utilsService.removeAllOptionsV2(filtro.instanceSystemSource,'SourceSysIds')}` : ''
    filtro.instanceSystemTarget ? filtroFinal += `${this.utilsService.removeAllOptionsV2(filtro.instanceSystemTarget,'TargetSysIds')}` : ''
    filtro.statusIds ? filtroFinal += `${this.utilsService.removeAllOptionsV2(filtro.statusIds,'StatusIds')}` : ''
    filtro.eventTypeIds ? filtroFinal += `${this.utilsService.removeAllOptionsV2(filtro.eventTypeIds,'EventTypeIds')}` : ''
    filtro.instanceFlowRefFieldList ? filtroFinal += `${this.createFilterInstanceFlowRefField(<InstanceFlowRefField[]>filtro.instanceFlowRefFieldList)}` : ''
    filtro.refFieldTypeList ? filtroFinal += `${this.createFilterRefFieldType(<ReferenceFieldType[]>filtro.refFieldTypeList)}` : ''
    return filtroFinal
    
  }

  createFilterInstanceFlowRefField(instanceFlowRefFieldList: InstanceFlowRefField[]): string {

    let filtroInstanceFlowRefField = ''
    if (instanceFlowRefFieldList && instanceFlowRefFieldList.length) {
      //filtra os campos que tem algum valor associado
      instanceFlowRefFieldList = instanceFlowRefFieldList.filter(instanceFlowRefField =>
        instanceFlowRefField.Value != null && instanceFlowRefField.Value != ''
      )
    }

    //verifica novamente se há campos com valores
    if (instanceFlowRefFieldList && instanceFlowRefFieldList.length) {
      let typesIds: any[] = instanceFlowRefFieldList.map(instanceFlowRefField => instanceFlowRefField.Id)

      let FieldValues: any[] = instanceFlowRefFieldList.map(instanceFlowRefField => instanceFlowRefField.Value)
      filtroInstanceFlowRefField = `&ReferenceFieldIds=${[...typesIds]}&FieldValues=${[...FieldValues]}`
    }

    return filtroInstanceFlowRefField
  }

  createFilterRefFieldType(refFieltType: ReferenceFieldType[]) {
    let filtroRefFieldType = ''
    if (refFieltType && refFieltType.length) {
      //filtra os campos que tem algum valor associado
      refFieltType = refFieltType.filter(instanceFlowRefField =>
        instanceFlowRefField.Value != null && instanceFlowRefField.Value != ''
      )
    }

    //verifica novamente se há campos com valores
    if (refFieltType && refFieltType.length) {
      let typesIds: any[] = refFieltType.map(instanceFlowRefField => instanceFlowRefField.Id)

      let FieldValues: any[] = refFieltType.map(instanceFlowRefField => instanceFlowRefField.Value)
      filtroRefFieldType = `&TypeIds=${[...typesIds]}&ReferenceFieldTypeValues=${[...FieldValues]}`
    }

    return filtroRefFieldType
  }

  createFilterReferenceField(referenceFieldsList: ReferenceFieldType[]): string {

    let filtroReferenceField = ''
    if (referenceFieldsList && referenceFieldsList.length) {
      //filtra os campos que tem algum valor associado
      referenceFieldsList = referenceFieldsList.filter(referenceField =>
        referenceField.Value != null && referenceField.Value != ''
      )
    }

    //verifica novamente se há campos com valores
    if (referenceFieldsList && referenceFieldsList.length) {
      let typesIds: any[] = referenceFieldsList.map(referenceField => referenceField.Id)

      let FieldValues: any[] = referenceFieldsList.map(referenceField => referenceField.Value)
      filtroReferenceField = `&TypeIds=${[...typesIds]}&FieldValues=${[...FieldValues]}`
    }

    return filtroReferenceField
  }

  configureDataInicialFilter(dataInicial: string) {
    return dataInicial ? `&DataInicial=${this.configureDateFilter(dataInicial)}` : ''
    //return `&DataInicial=${(dataInicial ? this.configureDateFilter(dataInicial) : '')}`
  }

  configureDataFinalFilter(dataFinal: string) {
    return dataFinal ? `&DataFinal=${this.configureDateFilter(dataFinal)}` : ''
    //return `&DataFinal=${(dataFinal ? this.configureDateFilter(dataFinal) : '')}`
  }

  configureDateFilter(date: string) {
    let formatedDate = moment(date).format('YYYY-MM-DD');
    return formatedDate;
  }

  discardMessage(messageIds) {
    return this.httpClient.patch(`${environment.apiUrl}${CONSTANTS.ENDPOINT.MESSAGELOG}`, messageIds)
  }

  reprocessMessage(messageIds) {
    return this.httpClient.patch(`${environment.apiUrl}${CONSTANTS.ENDPOINT.MESSAGELOG}`, messageIds)
  }

}
