import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import * as Highcharts from 'highcharts';

import { ChartsConfig } from '../config-chart-line';

export interface DataChart {
  title: string
  labels: Array<string>
  series: any
}

declare let require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  selector: 'app-lines-chart',
  templateUrl: './lines-chart.component.html'  
})

export class LinesChartComponent implements OnInit, OnChanges {
  public _chartIndex = 'chartline-' + Math.random().toString().slice(2, 18)
  public _validData: boolean = true;
  public _titulo: string = '';

  @Input() height: number;
  @Input() data: any;
  private dataInterna: any;
  @Input() title: string;
  @Input() xlabel: string;
  @Input() ylabel: string;

  @ViewChild("chart", { static: true }) lineChart: ElementRef;

  public chartConfig = new ChartsConfig()

  constructor() { }

  ngOnInit() {
    this.validData(this.data)
    this.carregaStrings()
  }

  ngOnChanges(changes: SimpleChanges) {
    const data: SimpleChange = changes.pageConfig;
    //data.currentValue.title = data.currentValue.title
    //this.dataInterna = data.currentValue;

    this.validData(this.dataInterna)
    if (this._validData) {
      this.chartConfig.options.series[0].data = this.dataInterna.series;
      Highcharts.chart('chartline-21', this.chartConfig.options);
    } else {
      Highcharts.chart('chartline-21', {});
    }
  
  }

  carregaStrings () {
    
    if (this.title) {
        this._titulo = this.title
    }
    if (this.xlabel) {
        this.chartConfig.options.xAxis.title.text = this.xlabel
    }
    if (this.ylabel) {
        this.chartConfig.options.yAxis.title.text = this.ylabel
    }
  }
  
  
  ngAfterViewInit () {
    if (this._validData) {
      this.chartConfig.options.series = this.data.series
      this.chartConfig.options.xAxis.categories = this.data.labels
      Highcharts.chart('chartline-21', this.chartConfig.options);
    }
  }

  validData(data: DataChart) {
    
    if (data) {
      if (this.isDataChartValid(data)) {
        this._validData = true
      } else {
        this._validData = false
      }
    } else {
      this._validData = false
    }
  }

  validateDataLabels(dataSize: number, labelsSize: number) {
    if (dataSize == labelsSize) {
      return true
    } else {
      return false
    }
  }

  isDataChartValid(value: any) {
    
    //verifica se o tamanho do data e do labels tem o mesmo tamanho
    if (value.series && value.labels) {
      let validaDataLabels = this.validateDataLabels(value.series[0].data.length, value.labels.length)
      if (validaDataLabels) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }
}