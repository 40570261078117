<div class="faq-section">

    <div class="container-fluid py-2" [ngClass]="[colorBgFaq]" *ngIf="titleSectionFaq">
        <p class="mb-0" [ngClass]="[colorTitleFaq]">{{titleSectionFaq}}</p>
    </div>
    <div id="accordion-{{faqIndex}}" class="accordion">
        <div class="card" *ngFor="let item of faqContent">
            <div class="card-header" id="heading{{faqContent.indexOf(item)}}">
                <h5 class="mb-0">
                    <a data-toggle="collapse" [attr.data-target]="'#collapse-'+faqIndex+'-'+faqContent.indexOf(item)" aria-expanded="false" [attr.aria-controls]="'collapse-'+faqIndex+'-'+faqContent.indexOf(item)">
                        {{faqContent.indexOf(item)+1}})                
                        {{item.pergunta}}                      
                    </a>
                </h5>
            </div>
            <div id="collapse-{{faqIndex}}-{{faqContent.indexOf(item)}}" class="collapse" [attr.aria-labelledby]="'heading'+faqContent.indexOf(item)" [attr.data-parent]="'#accordion-'+faqIndex">
                <div class="card-body">
                    {{item.resposta}}
                </div>
            </div>
        </div>
    </div>
</div>