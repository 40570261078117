import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatDatepickerModule, MatNativeDateModule, 
  MatSelectModule, MatOptionModule, MatTableModule, MatTooltipModule, MatSortModule, MatAutocompleteModule, MatInputModule,
} from '@angular/material';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClient } from '@angular/common/http';

// plugins
import { TextMaskModule } from 'angular2-text-mask';
import { NgxUploaderModule } from 'ngx-uploader';
import { NgxMaskModule } from 'ngx-mask';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// componentes
import { AppBreadcrumbsModule, AppFaqModule,  PaginationModule,
  PageTitleModule, StretchCardModule, AppfullScreenModule, BackButtonModule, AppPopUpModule, TimerModule
} from '../components/ui'

// diretivas
import { PhoneMaskDirective } from '../directives/phone-mask.directive';
import { DateMaskDirective } from '../directives/date-mask.directive';

// pipes
import { TelPipe } from '../pipes/telefone.pipe'
import { CnpjPipe } from '../pipes/cnpj.pipe'
//import { FecharDialogComponent } from '../components/ui/fechar-dialog/fechar-dialog.component';
import { FecharDialogModule } from '../components/ui/fechar-dialog/fechar-dialog.module';
import { ThemeDirective } from '../directives/theme.directive';
import { NewPaginationModule } from '../components/ui/new-pagination/new-pagination.module';
import { LoaderComponent } from '../components/loader/loader.component';
import { AngularMaterialModule } from './angular-material.module';
import { LinesChartComponent } from '../components/charts/lines-chart/lines-chart.component';
import { PieChartComponent } from '../components/charts/pie-chart/pie-chart.component';
import { SimpleBarChartComponent } from '../components/charts/bar-chart/bar-chart.component';
import { DateMaskFilterDirective } from '../directives/date-mask-filter.directive';
import { TabsContainerComponent } from '../components/ui/tabs-container/tabs-container.component';
import { TabComponent } from '../components/ui/tabs-container/tab/tab.component';
import { TranslateLanguageComponent } from '../components/header/translate-language/translate-language/translate-language.component';
import { FilterComponentComponent } from 'src/app/features/monitor/filter-component/filter-component.component'
import { NgxMaterialTimepickerModule  } from 'ngx-material-timepicker';
import { NumPaginationComponent } from '../components/ui/num-pag/num-pag.component';


export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
    declarations: [
        TelPipe,
        PhoneMaskDirective,
        DateMaskDirective,
        CnpjPipe,
        ThemeDirective,
        LoaderComponent,
        LinesChartComponent,
        PieChartComponent,
        SimpleBarChartComponent,
        DateMaskFilterDirective,
        TabsContainerComponent,
        TranslateLanguageComponent,
        TabComponent,
        FilterComponentComponent,
        NumPaginationComponent
    ],
    imports: [
        CommonModule,
        TextMaskModule,
        NgxUploaderModule,
        FormsModule, ReactiveFormsModule,
        MatDatepickerModule, MatNativeDateModule,
        MatSelectModule, MatOptionModule,
        NgxMaskModule.forRoot(),
        PaginationModule,
        AppBreadcrumbsModule,
        AppfullScreenModule,
        BackButtonModule,
        StretchCardModule,
        AppFaqModule,
        NewPaginationModule,
        PageTitleModule,
        FecharDialogModule,
        AppPopUpModule,
        TimerModule,
        MatTableModule,
        MatTooltipModule,
        NgxMaterialTimepickerModule,
        MatSortModule,
        AngularMaterialModule,
        MatAutocompleteModule,
        MatInputModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
    ],
    exports: [
        TextMaskModule,
        NgxUploaderModule,
        FormsModule, ReactiveFormsModule,
        MatDatepickerModule, MatNativeDateModule,
        MatSelectModule, MatOptionModule,
        NgxMaskModule,
        PaginationModule,
        AppBreadcrumbsModule,
        AppfullScreenModule,
        BackButtonModule,
        StretchCardModule,
        AppFaqModule,
        PageTitleModule,
        NewPaginationModule,
        FecharDialogModule,
        AppPopUpModule,
        TimerModule,
        TelPipe,
        CnpjPipe,
        PhoneMaskDirective,
        DateMaskDirective,
        TranslateModule,
        ThemeDirective,
        MatTableModule,
        MatTooltipModule,
        MatSortModule,
        LoaderComponent,
        MatAutocompleteModule,
        MatInputModule,
        LinesChartComponent,
        PieChartComponent,
        SimpleBarChartComponent,
        TabsContainerComponent,
        TranslateLanguageComponent,
        TabComponent,
        AngularMaterialModule,
        FilterComponentComponent,
        NumPaginationComponent
    ]
})
export class SharedModule { }