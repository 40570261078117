import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from '../../../shared/angular-material.module';


import { PopUpComponent } from './popup.component';


@NgModule({
  imports: [
    CommonModule,
    AngularMaterialModule,
    
  ],
  declarations: [ PopUpComponent ],
  exports: [ PopUpComponent ],
  bootstrap: [PopUpComponent]
})

export class AppPopUpModule { }