import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable } from 'rxjs';

import { StorageService } from '../storage/storage.service';
import { UtilsService } from '../../services/utils/utils.service';

@Injectable({ providedIn: 'root' })
export class AppGuard implements CanActivate {

  constructor(
    private utilsService: UtilsService,
    private router: Router,
    private storageService: StorageService
  ) { }

  /** @description verifica se o usuario esta logado para liberar acesso a rota ou redirecionar para tela de login
*/
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    //verifica se tem token e instancia selecionada
    if (this.storageService.hasToken() && this.storageService.getInstanceLogin()) {
      return true
    } 

    return false;
  }

}