import { createAction, props } from '@ngrx/store';
import { SearchFilterMessageLog } from '../models/SearchFilterMessageLog';

export enum FilterFlowPanelActionTypes {
    ADDFILTER = '[Filter Flow Panel] Add Filter',
    CLEARFILTER = '[Filter Flow Panel] Clear Filter',
}

export const addFilter = createAction(
    FilterFlowPanelActionTypes.ADDFILTER,
    props<{ searchFilterFlowPanel: SearchFilterMessageLog }>()
)

export const clearFilter = createAction(
    FilterFlowPanelActionTypes.CLEARFILTER,
    props<{ searchFilterFlowPanel: null }>()
)
