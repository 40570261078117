import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, SimpleChanges, AfterViewInit, OnChanges, DoCheck, AfterViewChecked } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';


//import { Observable } from 'rxjs';

import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { Store, select } from '@ngrx/store';
import { Site, SiteService } from 'src/app/features/cadastros/site/site.service';
import { CanalService } from 'src/app/features/cadastros/canal/canal.service';
import { FlowGroupService } from 'src/app/features/cadastros/flowGroup/flowGroup.service';
import { Channel } from 'src/app/features/cadastros/canal/canal.component';
import { InstanceSystemService, InstanceSystem } from 'src/app/features/cadastros/instance-system/instance-system.service';

import { InstanceFlowService, InstanceFlow } from 'src/app/features/cadastros/instance-flow/instance-flow.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { FlowGroup } from 'src/app/features/cadastros/flowGroup/flowGroup.component';
import { SiteGroupService, SiteGroup } from 'src/app/features/cadastros/siteGroup/sitegroup.service';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker/public_api';
import { FilterLogsActions } from 'src/app/actions/action-types';
import { SearchFilterMessageLog } from 'src/app/models/SearchFilterMessageLog';
import { Subscription, zip, Observable, of, fromEvent } from 'rxjs';
import { State, STATES, Status } from 'src/app/features/cadastros/status/status.service';
import { ReferenceFieldTypeService } from 'src/app/features/cadastros/reference-field-type/reference-field-type.service';
import { InstanceFlowReferenceFieldService, InstanceFlowRefField } from 'src/app/features/cadastros/instance-flow-reference-field/instance-flow-reference-field.service';
import { MatDialog, MatDatepickerInputEvent, MatDatepicker } from '@angular/material';
import { ReferenceFieldModalComponent } from './reference-field-modal/reference-field-modal.component';
import { ReferenceFieldType } from 'src/app/models/ReferenceFieldType';
import { debounceTime, map, startWith, distinctUntilChanged, take } from 'rxjs/operators';
import { AddFilter } from 'src/app/reducers/filter.actions';
import { FilterLogsModal } from 'src/app/models/FilterLogs';
import { MessageLogTotalResult } from '../message-log.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { AppStore } from 'src/app/layout/interno/apploged.store';
import { StorageService } from 'src/app/core/storage/storage.service';
import { LoaderService } from 'src/app/components/loader/loader.service';
import { ActivatedRoute } from '@angular/router';
import { InstanceFlowRefFieldModalComponent } from './instance-flow-ref-field-modal/instance-flow-ref-field-modal.component';
import { InstanceInterval } from 'src/app/features/cadastros/instance-interval/instance-interval.service';
import { MonitorService, FilterObj } from '../../monitor.service';

import { EventTypeService, EventType, Icon } from '../../../cadastros/event-type/event-type.service';
import { debug } from 'console';
import Swal from 'sweetalert2'

interface FilterSubscriptions {
  updateFilterSubscription?: Subscription,
  deleteFilterSubscription?: Subscription
  getFilterListSubscription?: Subscription
  getFilterSubscription?: Subscription
  postFilterSubscription?: Subscription
}


interface FilterLogsSubscriptions {
  getSiteListSubscription?: Subscription
  getSiteGroupSubscription?: Subscription
  getChannelListAllSubscription?: Subscription
  getFlowGroupListAllSubscription?: Subscription
  getInstanceFlowListAllSubscription?: Subscription
  getInstanceSystemListSubscription?: Subscription
  getReferenceFieldTypesSubscription?: Subscription
  getInstanceFlowRefFieldSubscription?: Subscription
}

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    popup: 'container-swal',
    confirmButton: 'btn btn-sm btn-success ',
    cancelButton: 'btn btn-sm btn-danger ml-2',
  },
  width: 350,
  buttonsStyling: false,
})
export enum VisualizationTypes {
  FLOWGROUPPANEL,
  FLOWPANEL,
  MESSAGELOGDETAILS,
}

@Component({
  selector: 'app-filter-logs',
  templateUrl: './filter-logs.component.html',
  styleUrls: ['./filter-logs.component.scss']
})

export class FilterLogsComponent implements OnInit, AfterViewInit {
  @Input() previousFilter: FilterLogsModal;
  @Input() searchFilterMessageLog: SearchFilterMessageLog
  @Input() origin: string
  @Input() setVisualization: VisualizationTypes
  @Output() pressFilter = new EventEmitter();
  @Output() filterEvent = new EventEmitter();
  @Output() filterMessageLogEvent = new EventEmitter();
  @Output() filterSearchLog = new EventEmitter<SearchFilterMessageLog>();



  @ViewChild('inputDateStart') inputDateStart: ElementRef
  @ViewChild('dateStart') public dateStart: MatDatepicker<any>
  // set dateStartContent(content: ElementRef) {
  //   if (content) { // initially setter gets called with undefined
  //     this.dateStart = content;
  //   }
  // }

  @ViewChild('dateEnd') public dateEnd: MatDatepicker<any>;
  // set dateEndContent(content: ElementRef) {
  //   if (content) { // initially setter gets called with undefined
  //     this.dateEnd = content;
  //   }
  // }

  @ViewChild('initialTime') initialTime: ElementRef;
  set initialTimeContent(content: ElementRef) {
    if (content) { // initially setter gets called with undefined
      this.initialTime = content;
    }
  }

  @ViewChild('allFlowGroupSelected') private allFlowGroupSelected
  set flowGrouptContent(content: ElementRef) {
    if (content) { // initially setter gets called with undefined
      this.allFlowGroupSelected = content;
    }
  }

  @ViewChild('allInstanceFlowSelected') private allInstanceFlowSelected
  set instanceFlowContent(content: ElementRef) {

    if (content) { // initially setter gets called with undefined

      this.allInstanceFlowSelected = content;
    }
  }

  @ViewChild('allTargetSelected') private allTargetSelected
  set targetContent(content: ElementRef) {
    if (content) { // initially setter gets called with undefined
      this.allTargetSelected = content;
    }
  }

  @ViewChild('allSourceSelected') private allSourceSelected
  set sourceContent(content: ElementRef) {

    if (content) { // initially setter gets called with undefined
      this.allSourceSelected = content;
    }
  }

  // @ViewChild('allChannelSelected', { static: false }) private allChannelSelected
  // set channelContent(content: ElementRef) {
  //   
  //   if (content) { // initially setter gets called with undefined
  //     this.allChannelSelected = content;
  //   }
  // }

  @ViewChild('allSiteSelected') private allSiteSelected
  set siteContent(content: ElementRef) {
    if (content) { // initially setter gets called with undefined
      this.allSiteSelected = content;
    }
  }

  @ViewChild('allStateSelected') private allStateSelected
  set stateContent(content: ElementRef) {
    if (content) { // initially setter gets called with undefined
      this.allStateSelected = content;
    }
  }

  @ViewChild('allStatusSelected') private allStatusSelected
  set statusContent(content: ElementRef) {
    if (content) { // initially setter gets called with undefined
      this.allStatusSelected = content;
    }
  }

  @ViewChild('allEventTypeSelected') private allEventTypeSelected
  set eventTypeContent(content: ElementRef) {
    if (content) { // initially setter gets called with undefined
      this.allEventTypeSelected = content;
    }
  }

  @ViewChild('allInstanceFlowRefFieldSelected') private allInstanceFlowRefFieldSelected
  set instanceFlowRefFieldContent(content: ElementRef) {
    if (content) { // initially setter gets called with undefined
      this.allInstanceFlowRefFieldSelected = content;
    }
  }

  @ViewChild('allRefFieldtypeSelected') private allRefFieldtypeSelected
  set refFieldtypeSelected(content: ElementRef) {
    if (content) { // initially setter gets called with undefined
      this.allRefFieldtypeSelected = content;
    }
  }

  @ViewChild('allReferenceFieldSelected') private allReferenceFieldSelected
  set referenceFieldContent(content: ElementRef) {
    if (content) { // initially setter gets called with undefined
      this.allReferenceFieldSelected = content;
    }
  }

  @ViewChild('allSiteGroupSelected') private allSiteGroupSelected
  set siteGroupContent(content: ElementRef) {
    if (content) { // initially setter gets called with undefined
      this.allSiteGroupSelected = content;
    }
  }

  subscriptions: Subscription = new Subscription()
  btnHide = false;
  maxDate: Date;
  prevFilter: FilterLogsModal;
  searchLogsForm: FormGroup;
  storeSubscription: Subscription;
  states: State[] = STATES
  referenceFields: ReferenceFieldType[] = []
  referenceFieldsAuxiliary: ReferenceFieldType[] = []
  instanceFlowRefField: InstanceFlowRefField[];
  refFieldType: ReferenceFieldType[];
  instanceFlowRefFieldAuxiliary: InstanceFlowRefField[]
  instanceFlowRefFieldModal: InstanceFlowRefField[] = [];
  instanceFlowRefFieldOptions = new FormControl();
  refFieldTypeOptions = new FormControl();
  instanceFlowRefFieldsAllOption: InstanceFlowRefField;
  refFieldsTypeAllOption: ReferenceFieldType;
  status: Status[] = []
  accordionType = false
  accordionRefField = false
  //obj que popula drop de integrações 'flowGroup'
  flowGroups = []
  showCard: boolean = false;

  //obj que popula drop instanceFlows
  instanceFlows: InstanceFlow[] = []
  //obj que serve para ajudar no filtro pelo FlowGroup
  instanceFlowAuxiliary: InstanceFlow[] = []

  //obj que popula drop de sites
  sites: Site[] = []
  //obj que serve para ajudar no filtro pelo SiteGroup
  sitesAuxiliary: Site[] = []

  //obj que popula drop de siteGroups
  siteGroups: SiteGroup[] = []
  refFieldTypes: any
  //obj que popula o select de canais
  canais: Channel[] = []
  //obj que popula o select de Origem
  instanceSystemSource: InstanceSystem[] = []
  //obj que popula o select de Origem
  instanceSystemTarget: InstanceSystem[] = []

  // obj que serve para ajudar no filtro pelo Flow
  instanceSystemAuxiliary: InstanceSystem[] = []
  listaFavoritos: SearchFilterMessageLog[] = []
  //obj que popula drop de Campos de referencia
  enableReferenceFild = []

  referenceFieldList: FormArray;
  referenceFieldListModal: ReferenceFieldType[] = [];
  filterSubscriptions: FilterSubscriptions = {}
  listaRefFieldsRemove: any = [];
  // instanceSystemSource = instanceSystems;
  // instanceSystemTarget = instanceSystems;
  // instanceSystemChannel = instanceSystems;
  visualizationTypes = VisualizationTypes
  allSubscriptions: Subscription = new Subscription()
  messageLogTotal: MessageLogTotalResult;
  instanceInterval: InstanceInterval = new InstanceInterval();
  instanceFlowRefFieldsSelected: InstanceFlowRefField[] = [];
  refFieldTypesSelected: ReferenceFieldType[] = []
  idiomaAtual: string // guarda o idioma atual
  showFlowGroupBreadCrumbLabel = true // flag para apontar se a opção todos do Grupo de Fluxo foi selecionada
  flowGroupNames: string;// o nome dos Grupo de Fluxo foi selecionados para mostrar na label 
  showFlowBreadCrumbLabel = true// flag para apontar se a opção todos do Fluxo foi selecionada
  flowNames: string // o nome dos Fluxos foi selecionados para mostrar na label 
  flowGroupIdsAuxiliary = [] // guarda os Ids dos FlowGroups selecionados anteriormente


  instanceSystemSourceName: string
  instanceSystemTargetName: string

  eventTypeList = new Array
  searchFieldSelected = []
  public maskTime = [/[1-9]/, /\d/, ':', /\d/, /\d/]

  //theme for ngx-timer
  darkTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#424242',
      buttonColor: '#fff'
    },
    dial: {
      dialBackgroundColor: '#555',
    },
    clockFace: {
      clockFaceBackgroundColor: '#555',
      clockHandColor: '#9fbd90',
      clockFaceTimeInactiveColor: '#fff'
    }
  };

  constructor(
    private eventTypeService: EventTypeService,
    public utilsService: UtilsService,
    public translate: TranslateService,
    private store: Store<any>,
    private fb: FormBuilder,
    private siteService: SiteService,
    private siteGroupService: SiteGroupService,
    public loaderService: LoaderService,
    private channelService: CanalService,
    private flowGroupService: FlowGroupService,
    private instanceFlowService: InstanceFlowService,
    private instanceSystemService: InstanceSystemService,
    private referenceFieldTypeService: ReferenceFieldTypeService,
    private instanceFlowReferenceFieldService: InstanceFlowReferenceFieldService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    public sessionService: StorageService,
    private storageService: StorageService,

    private monitorService: MonitorService
  ) {

    const browserLang = localStorage.getItem('appLanguage')
    this.idiomaAtual = browserLang
    // 
    //     this.router.getCurrentNavigation().extras.state.data;

    //inicializa formulario
    this.searchLogsForm = this.fb.group({
      interval: new FormControl(),
      intervalUnity: new FormControl(),
      hourStart: new FormControl(),
      hourEnd: new FormControl(),
      dateStart: new FormControl(),
      dateEnd: new FormControl(),
      integrationsId: new FormControl(),
      states: new FormControl(),
      statusIds: new FormControl(),
      eventTypeIds: new FormControl(),
      sites: new FormControl(),
      siteGroups: new FormControl(),
      flowGroups: new FormControl(),
      instanceFlows: new FormControl(),
      referenceField: new FormControl(),
      referenceFieldList: new FormControl([]),
      refFieldTypeList: new FormControl([]),
      instanceFlowRefFieldList: new FormControl([]),
      refFieldList: new FormControl([]),
      instanceSystemSource: new FormControl(''),
      instanceSystemTarget: new FormControl(''),
      instanceSystemChannel: new FormControl(''),
      instanceFlowRefField: new FormControl()
    });


    this.searchLogsForm.valueChanges.subscribe(value => {

      this.searchFieldSelected = []
      for (let key in value) {
        if (key == 'sites' || key == 'siteGroups' || key == 'flowGroups' || key == 'instanceFlows' || key == 'statusIds' || key == 'eventTypeIds' || key == 'instanceSystemSource' || key == 'instanceSystemTarget' || key == 'refFieldList' || key == 'instanceFlowRefFieldList') { //&& value[key] != null
          if (value[key] != null && value[key] != "" && value[key].filter(x => x == 0)[0] != 0) {
            this.searchFieldSelected.push(key)
          }
        }
        if (key == 'hourStart' || key == 'hourEnd' || key == 'dateStart' || key == 'dateEnd') {
          if (value[key] != null && value[key] != "") {
            this.searchFieldSelected.push(key)
          }
        }
      }
    })

  }


  async getEventListAll() {
    let result = await this.eventTypeService.getEventTypeListAll().toPromise()
    try {
      if (result.status == 200) {
        this.eventTypeList = result.body['result']
        this.searchLogsForm.get('eventTypeIds').value ? '' : this.searchLogsForm.controls.eventTypeIds.patchValue([0, ...this.eventTypeList.map(item => item.Id)]);
      }
    } catch (error) {
      this.utilsService.showAlertRequestError(error)
    }
  }

  async getEventFilter(statusIds) {


    let result = await this.eventTypeService.getEventTypeByStatus(statusIds).toPromise()
    try {
      if (result.status == 200) {
        let teste = result.body['result'].map(stats => stats.Id)
        let selectedEventType = this.searchLogsForm.get('eventTypeIds').value

        if (selectedEventType) {

          let found = teste.some(r => selectedEventType.indexOf(r) >= 0)
          if (!found) {

            //this.searchLogsForm.get('instanceFlows').reset();
            //this.searchLogsForm.get('eventTypeIds').value
            this.searchLogsForm.get('eventTypeIds').reset();
          }


        }


        this.eventTypeList = result.body['result']
      }
    } catch (error) {
      this.utilsService.showAlertRequestError(error)
    }
  }

  async ngOnInit() {
    this.instanceFlowRefFieldsAllOption = {
      Id: 0,
      Code: '',
      Name: '',
      Value: ''
    }

    this.refFieldsTypeAllOption = {
      Id: 0,
      Code: '',
      Name: '',
      Value: '',
      Status: null,
      UpdatedAt: null
    }

    this.refFieldTypes = await this.referenceFieldTypeService.getReferenceFieldTypeListAll().toPromise()
    if (this.refFieldTypes) {
      this.refFieldTypes = this.refFieldTypes.body.result
    } else {
      this.refFieldTypes = []
    }


    //observa a mudança de idioma para alterar o campo select de Status
    this.translate.onLangChange
      .subscribe((event: LangChangeEvent) => {
        this.idiomaAtual = event.lang
      });

    this.getEventListAll()

    this.maxDate = new Date()
    this.onFlowGroupChange(false);


    this.searchLogsForm.get('siteGroups').value ? '' : this.searchLogsForm.controls.siteGroups.patchValue([0, ...this.siteGroups.map(item => item.Id)])
    this.searchLogsForm.get('sites').value ? '' : this.searchLogsForm.controls.sites.patchValue([0, ...this.sites.map(item => item.Id)]);
    this.searchLogsForm.get('instanceFlows').value ? '' : this.searchLogsForm.controls.instanceFlows.patchValue([0, ...this.instanceFlows.map(item => item.Id)]);
    this.searchLogsForm.get('statusIds').value ? '' : this.searchLogsForm.controls.statusIds.patchValue([0, ...this.status.map(item => item.Id)]);
    this.searchLogsForm.get('instanceSystemSource').value ? '' : this.searchLogsForm.controls.instanceSystemSource.patchValue([0, ...this.instanceSystemSource.map(item => item.Id)]);
    this.searchLogsForm.get('instanceSystemTarget').value ? '' : this.searchLogsForm.controls.instanceSystemTarget.patchValue([0, ...this.instanceSystemTarget.map(item => item.Id)]);

    //this.searchLogsForm.controls.eventTypeIds.patchValue([0, ...this.eventTypeList.map(item => item.Id)]);

  }

  ngAfterViewInit() {

    const flowGroupIds: [] = this.searchLogsForm.get('flowGroups').value
    let flowGroupsSelected: FlowGroup[] = []
    flowGroupsSelected = this.filtraFlowGroups(flowGroupIds)
    this.configuraLabelFlowGroupBreadcrumb(flowGroupIds, flowGroupsSelected)

    const instanceFlowIds: [] = this.searchLogsForm.get('instanceFlows').value
    let instanceFlowSelected: InstanceFlow[] = []
    instanceFlowSelected = this.filtraInstanceFlow(instanceFlowIds)

    // if(instanceFlowSelected.length == 1){
    //   this.allInstanceFlowSelected.select();
    // }

    this.configuraLabelFlowBreadcrumb(instanceFlowIds, instanceFlowSelected)
    this.onInstanceFlowChange(false)
    // this.configuraLabelFlowBreadcrumb();
    // this.tosslePerOneInstanceFlow()
    // this.toggleAllSelected('instanceFlow')
  }

  //habilita/desablita o btn group
  tooggleBtnGroup(): boolean {

    const dateStart = this.searchLogsForm.get('dateStart').value;
    const hourStart = this.searchLogsForm.get('hourStart').value;
    const hourEnd = this.searchLogsForm.get('hourEnd').value;
    const dateEnd = this.searchLogsForm.get('dateEnd').value;

    const result = dateStart || hourStart || hourEnd || dateEnd
    return result
  }
  clearTime(field) {
    this.searchLogsForm.get(`${field}`).setValue(null);

  }
  clearInterval() {
    this.searchLogsForm.get('interval').setValue(null);
    this.searchLogsForm.get('intervalUnity').setValue(null);
  }

  openCalendar(type: string) {
    this.clearInterval();
    if (type == 'dateStart') {
      this.dateStart.open()
    } else {
      this.dateEnd.open()
    }
  }


  /**
   * @description configura o allSourceSelected conforme o usuário seleciona uma opção 
   */
  tosslePerOneSource(all) {

    if (this.allSourceSelected.selected) {
      this.allSourceSelected.deselect();
      return false;
    }

    if (this.searchLogsForm.controls.instanceSystemSource.value.length == this.instanceSystemSource.length)
      this.allSourceSelected.select();

  }

  /**
   * @description configura o allFlowGroupSelected conforme o usuário seleciona uma opção 
   */
  tosslePerOneFlowGroup() {

    if (this.allFlowGroupSelected.selected) {
      this.allFlowGroupSelected.deselect();
      this.instanceFlowRefField = [];
      return false;
    }

    if (this.searchLogsForm.controls.flowGroups.value.length == this.flowGroups.length)
      this.allFlowGroupSelected.select();
    this.instanceFlowRefField = [];
  }

  /**
  * @description configura o allInstanceFlowSelected conforme o usuário seleciona uma opção 
  */
  tosslePerOneInstanceFlow() {
    if (this.allInstanceFlowSelected.selected) {
      this.allInstanceFlowSelected.deselect();
      return false;
    }

    if (this.searchLogsForm.controls.instanceFlows.value.length == this.instanceFlows.length) {
      this.allInstanceFlowSelected.select();
    }
  }


  /**
   * @description configura o allSiteSelected conforme o usuário seleciona uma opção 
   */
  tosslePerOneSite() {
    if (this.allSiteSelected.selected) {
      this.allSiteSelected.deselect();
      return false;
    }

    if (this.searchLogsForm.controls.sites.value.length == this.sites.length)
      this.allSiteSelected.select();
  }


  /**
  * @description configura o allStateSelected conforme o usuário seleciona uma opção 
  */
  tosslePerOneState() {
    if (this.allStateSelected.selected) {
      this.allStateSelected.deselect();
      return false;
    }

    if (this.searchLogsForm.controls.states.value.length == this.states.length)
      this.allStateSelected.select();
  }

  /**
  * @description configura o allstatusSelected conforme o usuário seleciona uma opção 
  */
  tosslePerOneStatus() {
    if (this.allStatusSelected.selected) {
      this.allStatusSelected.deselect();
      return false;
    }

    if (this.searchLogsForm.controls.statusIds.value.length == this.status.length)
      this.allStatusSelected.select();
  }

  /**
  * @description configura o allEventTypeSelected conforme o usuário seleciona uma opção 
  */
  tosslePerOneEventType() {

    if (this.allEventTypeSelected.selected) {
      this.allEventTypeSelected.deselect();
      return false;
    }

    if (this.searchLogsForm.controls.eventTypeIds.value.length == this.eventTypeList.length)
      this.allEventTypeSelected.select();
  }
  /**
 * @description configura o allReferenceFieldSelected conforme o usuário seleciona uma opção 
 */
  tosslePerOneRerenceField() {
    if (this.allReferenceFieldSelected.selected) {
      this.allReferenceFieldSelected.deselect();
      return false;
    }

    if (this.searchLogsForm.controls.referenceField.value.length == this.referenceFields.length)
      this.allReferenceFieldSelected.select();
  }

  /**
   * @description configura o allSiteGroupSelected conforme o usuário seleciona uma opção 
   */
  tosslePerOneSiteGroup() {

    if (this.allSiteGroupSelected.selected) {
      this.allSiteGroupSelected.deselect();
      return false;
    }

    if (this.searchLogsForm.controls.siteGroups.value.length == this.siteGroups.length)
      this.allSiteGroupSelected.select();
  }


  // /**
  //  * @description configura o allTargetSelected conforme o usuário seleciona uma opção 
  //  */
  tosslePerOneTarget(all) {

    if (this.allTargetSelected.selected) {
      this.allTargetSelected.deselect();
      return false;
    }

    if (this.searchLogsForm.controls.instanceSystemTarget.value.length == this.instanceSystemTarget.length)
      this.allTargetSelected.select();

  }

  tosslePerOneInstanceFlowRefField(referenceField: InstanceFlowRefField) {
    // verifica se o campo já foi incluido no array referenceFieldsSelected. Se sim, o remove caso contrário o inclui
    if (this.instanceFlowRefFieldsSelected.some(instanceFlowRefFieldArray => instanceFlowRefFieldArray.Code == referenceField.Code)) {
      this.instanceFlowRefFieldsSelected = this.instanceFlowRefFieldsSelected.filter(referenceFieldArray => referenceFieldArray.Code != referenceField.Code)
    } else {
      const referenceFieldSelected = this.instanceFlowRefField.find(referenceFieldArray => referenceFieldArray.Code == referenceField.Code)
      this.instanceFlowRefFieldsSelected.push(referenceFieldSelected)
    }
    if (this.allInstanceFlowRefFieldSelected.selected) {
      this.allInstanceFlowRefFieldSelected.deselect();
      return false;
    }
    if (this.instanceFlowRefFieldsSelected.length == this.instanceFlowRefField.length) {
      this.allInstanceFlowRefFieldSelected.select();
    }
  }
  //accordionType = !accordionType
  //accordionRefField = !accordionRefField


  controlAccordion(type) {

    if (type == 'type') {


      this.accordionRefField = false
      this.accordionType = true
      this.searchLogsForm.controls.instanceFlowRefFieldList.setValue([])
      this.instanceFlowRefFieldsSelected = []
      this.instanceFlowRefFieldOptions.patchValue([])



    } else if (type == 'ref') {

      this.accordionRefField = true
      this.onInstanceFlowChange(false)
      this.accordionType = false
      this.searchLogsForm.controls.refFieldTypeList.setValue([])
      this.refFieldTypesSelected = []
      this.refFieldTypeOptions.patchValue([])


    }
  }
  tosslePerOneRefFieldType(referenceFieldType: ReferenceFieldType) {

    if (this.allRefFieldtypeSelected.selected) {
      this.allRefFieldtypeSelected.deselect();
      return false;
    }
    if (this.refFieldTypesSelected.length == this.refFieldTypes.length) {
      this.allRefFieldtypeSelected.select();
    }


  }

  /**
 * @description configura o tosslePerOneChannel conforme o usuário seleciona uma opção 
 */
  // tosslePerOneChannel(all) {
  //   
  //   if (this.allChannelSelected.selected) {
  //     this.allChannelSelected.deselect();
  //     return false;
  //   }

  //   if (this.searchLogsForm.controls.instanceSystemChannel.value.length == this.canais.length)
  //     this.allChannelSelected.select();

  // }

  tosslePerOne(selected, formControlName) {
    if (this[selected].selected) {
      this[selected].deselect();
      return false;
    }

    if (this.searchLogsForm.controls[formControlName].value.length == this[formControlName].length)
      this[selected].select();
  }


  toggleAllSelectedNew(selected, formControlName) {

    if (this[selected].selected) {
      this.searchLogsForm.controls[formControlName]
        .patchValue([0, ...this[formControlName].map(item => item.Id)]);
    } else {
      this.searchLogsForm.controls[formControlName].patchValue([]);
    };
  }

  tosslePerOneReferenceField(referenceField: InstanceFlowRefField) {


    if (this.allInstanceFlowRefFieldSelected.selected) {
      this.allInstanceFlowRefFieldSelected.deselect();
      return false;
    }
    //      

    if (this.instanceFlowRefFieldsSelected.length == this.instanceFlowRefField.length) {
      this.allInstanceFlowRefFieldSelected.select();
    }
  }

  tosslePerOneReferenceField2(referenceField: InstanceFlowRefField) {

    if (this.instanceFlowRefFieldsSelected.some(instanceFlowRefFieldArray => instanceFlowRefFieldArray.Code == referenceField.Code)) {

      this.instanceFlowRefFieldsSelected = this.instanceFlowRefFieldsSelected.filter(referenceFieldArray => referenceFieldArray.Code != referenceField.Code)

    } else {

      const referenceFieldSelected = this.instanceFlowRefField.find(referenceFieldArray => referenceFieldArray.Code == referenceField.Code)
      this.instanceFlowRefFieldsSelected.push(referenceFieldSelected)

    }

    if (this.allInstanceFlowRefFieldSelected.selected) {
      this.allInstanceFlowRefFieldSelected.deselect();
      return false;
    }
    //      

    if (this.instanceFlowRefFieldsSelected.length == this.instanceFlowRefField.length) {
      this.allInstanceFlowRefFieldSelected.select();
    }

  }

  toggleAllSelectedReferenceField() {

    if (this.allInstanceFlowRefFieldSelected.selected) {
      this.instanceFlowRefFieldsSelected = [...this.instanceFlowRefField]
      this.instanceFlowRefFieldOptions.patchValue([this.instanceFlowRefFieldsAllOption, ...this.instanceFlowRefField]);
    } else {
      this.instanceFlowRefFieldsSelected = []
      this.instanceFlowRefFieldOptions.patchValue([])

    }
  }

  toggleAllSelectedReferenceFieldType() {

    if (this.allRefFieldtypeSelected.selected) {
      this.refFieldTypesSelected = [...this.refFieldTypes]
      this.refFieldTypeOptions.patchValue([this.refFieldsTypeAllOption, ...this.refFieldTypes]);
    } else {
      this.refFieldTypesSelected = []
      this.refFieldTypeOptions.patchValue([])

    }
  }

  toggleAllSelected(filter) {
    // if (this.allSourceSelected.selected) {
    //   this.searchLogsForm.controls.instanceSystemSource
    //     .patchValue([0, ...this.instanceSystemSource.map(item => item.Id)]);
    // } else {
    //   this.searchLogsForm.controls.instanceSystemSource.patchValue([]);
    // };

    switch (filter) {

      case 'eventTypes':
        if (this.allEventTypeSelected.selected) {
          this.searchLogsForm.controls.eventTypeIds
            .patchValue([0, ...this.eventTypeList.map(item => item.Id)]);
        } else {
          this.searchLogsForm.controls.eventTypeIds.patchValue([]);
        };
        break;

      case 'status':
        if (this.allStatusSelected.selected) {
          this.searchLogsForm.controls.statusIds
            .patchValue([0, ...this.status.map(item => item.Id)]);
        } else {

          this.searchLogsForm.controls.statusIds.patchValue([]);
        };
        break;

    }

  }

  /** @description observa mudanças de estado no inputs do component 
  */
  async ngOnChanges(simpleChange?: SimpleChanges) {

    this.instanceFlowRefFieldOptions.valueChanges
      .subscribe(
        (data) => {

          this.instanceFlowRefFieldModal = [...data]
          this.instanceFlowRefFieldsSelected = [...data]
          this.searchLogsForm.get('instanceFlowRefFieldList').setValue([...data])

        }
      )

    this.refFieldTypeOptions.valueChanges
      .subscribe(
        (data) => {

          this.refFieldTypesSelected = [...data]
          //refFieldTypeList
          this.searchLogsForm.get('refFieldList').setValue([...data])
          this.searchLogsForm.get('refFieldTypeList').setValue([...data])

        }
      )



    this.searchLogsForm.get('instanceFlows').valueChanges
      .pipe(
        take(1)
      )
      .subscribe(
        (data) => {


          this.onInstanceFlowChange(false)
        }
      )

    this.searchLogsForm.get('flowGroups').valueChanges
      .pipe(
        take(1)
      )
      .subscribe(
        (data) => {
          this.onFlowGroupChange(false)
        }
      )
    this.onInstanceFlowChange(false)



    // this.setPeriod(24)
    const siteList = this.activatedRoute.snapshot.data['siteList'];
    const siteGroupList = this.activatedRoute.snapshot.data['siteGroupList'];
    const flowGroupListAll = this.activatedRoute.snapshot.data['flowGroupListAll'];
    const instanceFlowListAll = this.activatedRoute.snapshot.data['instanceFlowListAll'];
    const instanceSystemList = this.activatedRoute.snapshot.data['instanceSystemList'];
    // const referenceFieldTypes = this.activatedRoute.snapshot.data['referenceFieldTypes'];
    const instanceFlowRefField = this.activatedRoute.snapshot.data['instanceFlowRefField'];
    const statusList = this.activatedRoute.snapshot.data['statusList'];
    const instanceIntervals = this.activatedRoute.snapshot.data['instanceInterval'];
    this.setSiteList(siteList);
    this.setSiteGroup(siteGroupList);
    this.setFlowGroupListAll(flowGroupListAll);
    this.setInstanceFlowListAll(instanceFlowListAll);
    this.setInstanceSystemList(instanceSystemList);
    // this.setReferenceFieldTypes(referenceFieldTypes);
    this.setInstanceFlowRefField(instanceFlowRefField);
    this.setStatusList(statusList);
    this.setInstanceInterval(instanceIntervals);
    //verifica se as informações do filtro foi passado via rota
    //caso contrário preenche o formulário com as informações iniciais

    if (simpleChange['searchFilterMessageLog'] && simpleChange['searchFilterMessageLog'].currentValue) {
      let searchFilterMessageLog: SearchFilterMessageLog = simpleChange['searchFilterMessageLog'].currentValue

      this.store.dispatch(FilterLogsActions.addFilter({ searchFilterMessageLog }))
    }

    // recupera dados do store e faz a pesquisa conforme o filtro, se houver
    this.storeSubscription = this.store.pipe(
      select('filterMessageLogFeature'),
      take(1)
    )
      .subscribe(
        (data: any) => {
          if (data.filterMessageLog) {
            this.searchLogsForm.patchValue(data.filterMessageLog);
            const { interval, intervalUnity } = data.filterMessageLog
            this.searchLogsForm.get('interval').setValue(interval);
            this.searchLogsForm.get('intervalUnity').setValue(intervalUnity);
            // const interval = data.filterMessageLog.interval
            // this.setPeriod(interval)
            this.search()
          } else {
            this.searchLogsForm.get('flowGroups').setValue([0, ...this.flowGroups.map(item => item.Id)])
            this.search()
          }
        }
      )

  }

  /** @description retorna um elemento de form
  * @param {name} nome do campo que sera criado
  */
  createItem(name: string): FormGroup {
    return this.fb.group({
      field: name,
      value: '',
    });
  }



  /** @description adiciona um elemento no form array de referenceFieldList
  * @param {name} nome do campo que sera criado
  */
  addItem(name: string): void {
    this.referenceFieldList = this.searchLogsForm.get('referenceFieldList') as FormArray;
    this.referenceFieldList.push(this.createItem(name));
  }

  /** @description remove um elemento especifico do form array de referenceFieldList
  * @param {name} nome do campo que sera removido
  */
  removeItem(name: string): void {
    this.referenceFieldList = this.searchLogsForm.get('referenceFieldList') as FormArray;
    let indexOfFormArray = this.referenceFieldList.value.findIndex(i => i.field === name);

    this.referenceFieldList.removeAt(indexOfFormArray);
  }

  removeAllItem(): void {
    this.referenceFieldList = this.searchLogsForm.get('referenceFieldList') as FormArray;
    while (this.referenceFieldList.length > 0) {
      this.referenceFieldList.removeAt(0);
    }

    //this.referenceFieldList.removeAt(indexOfFormArray);
  }

  getField(formControlItem) {

    return formControlItem.controls.field.value
  }

  setValueRefField(index) {
    this.instanceFlowRefFieldModal = [...this.instanceFlowRefFieldsSelected]
    this.searchLogsForm.get('instanceFlowRefFieldList').setValue([...this.instanceFlowRefFieldsSelected])
  }

  setValueRefFieldType(index) {
    //this.instanceFlowRefFieldModal = [...this.instanceFlowRefFieldsSelected]
    this.searchLogsForm.get('refFieldTypeList').setValue([...this.refFieldTypesSelected])
  }

  createInitialFilter(simpleChange?: SimpleChanges) {

    this.searchLogsForm.patchValue(simpleChange);
    this.search()

  }

  clear() {
    //this.searchFieldSelected = []
    this.instanceFlows = [...this.instanceFlowAuxiliary];
    this.sites = [...this.sitesAuxiliary];
    this.instanceFlowRefFieldModal = []
    this.instanceFlowRefField = []
    // this.searchLogsForm.reset();  
    this.searchLogsForm.controls['interval'].reset()
    this.searchLogsForm.controls['intervalUnity'].reset()
    this.searchLogsForm.controls['hourStart'].reset()
    this.searchLogsForm.controls['hourEnd'].reset()
    this.searchLogsForm.controls['dateStart'].reset()
    this.searchLogsForm.controls['dateEnd'].reset()
    this.allSiteGroupSelected.select();
    this.allSiteSelected.select();
    this.allFlowGroupSelected.select();
    this.allInstanceFlowSelected.select();
    this.allSourceSelected.select();
    this.allTargetSelected.select();
    this.allEventTypeSelected.select();
    this.allStatusSelected.select();
    this.toggleAllSelectedNew('allSiteGroupSelected', 'siteGroups');
    this.toggleAllSelectedNew('allSiteSelected', 'sites');
    this.toggleAllSelectedNew('allFlowGroupSelected', 'flowGroups');
    this.toggleAllSelectedNew('allInstanceFlowSelected', 'instanceFlows');
    this.toggleAllSelectedNew('allSourceSelected', 'instanceSystemSource');
    this.toggleAllSelectedNew('allTargetSelected', 'instanceSystemTarget');
    this.toggleAllSelected('eventTypes')
    this.toggleAllSelected('status');
    this.store.dispatch(FilterLogsActions.clearFilter({ searchFilterMessageLog: null }))
    this.searchLogsForm.controls.instanceFlowRefFieldList.setValue([])
    this.instanceFlowRefFieldsSelected = []
    this.instanceFlowRefFieldOptions.patchValue([])
    this.searchLogsForm.controls.refFieldTypeList.setValue([])
    this.refFieldTypesSelected = []
    this.refFieldTypeOptions.patchValue([])
  }

  setInstanceFlowRefField(data: InstanceFlowRefField[]) {
    this.instanceFlowRefField = [...data]
    this.instanceFlowRefFieldAuxiliary = [...data]
  }

  getInstanceFlowRefField() {

    const subscription = this.instanceFlowReferenceFieldService.getInstanceFlowRefFieldListByInstance()
      .subscribe(
        (data: HttpResponse<any>) => {
          if (data.status == 200) {
            this.instanceFlowRefField = data.body['result']
            this.instanceFlowRefFieldAuxiliary = data.body['result']
          }
        },
        this.utilsService.showAlertRequestError
      )

    this.subscriptions.add(subscription)
  }

  setStatusList(data: Status[]) {
    this.status = data
  }

  setSiteGroup(data: SiteGroup[]) {
    this.siteGroups = data
  }

  setReferenceFieldTypes(data: ReferenceFieldType[]) {
    this.referenceFields = data
    this.referenceFieldsAuxiliary = data
  }

  getReferenceFieldTypes() {
    const subscription = this.referenceFieldTypeService.getReferenceFieldTypeListByInstance()
      .subscribe(
        (data: HttpResponse<any>) => {
          if (data.status == 200) {
            this.referenceFields = data.body['result']
            this.referenceFieldsAuxiliary = data.body['result']
          }
        },
        (err: HttpErrorResponse) => {
          // console.log(err.status)
        }
      )

    this.subscriptions.add(subscription)
  }

  setInstanceSystemList(data: InstanceSystem[]) {
    this.instanceSystemSource = data
    this.instanceSystemTarget = data
    this.instanceSystemAuxiliary = data
  }

  setInstanceInterval(instanceIntervals: InstanceInterval) {
    this.instanceInterval.Interval = instanceIntervals.Interval

    let menorIntervalo = this.monitorService.verificaMenorIntervalo([...instanceIntervals.Interval])

    this.searchLogsForm.get('interval').setValue(menorIntervalo.Value);
    this.searchLogsForm.get('intervalUnity').setValue(menorIntervalo.Unity);
  }

  getChannelListAll() {
    const channelListAllSubscription = this.channelService.getChannelListAll()
      .subscribe(
        (data) => {

          if (data.status == 200) {
            this.canais = data.body['result'];
          }
        },
        this.utilsService.showAlertRequestError
      ).add(() => {
        // this.loaderService.hide()
      })

    this.subscriptions.add(channelListAllSubscription)
  }

  setInstanceFlowListAll(data: InstanceFlow[]) {

    this.instanceFlows = data
    this.instanceFlowAuxiliary = data
  }


  setFlowGroupListAll(data: FlowGroup[]) {
    this.flowGroups = data
  }

  setSiteList(data: Site[]) {
    this.sites = data
    this.sitesAuxiliary = data
  }

  filtraInstanceFlow(instanceFlowIds: []) {

    let instanceFlowSelected: InstanceFlow[] = []
    instanceFlowIds.forEach((id) => {
      let instanceFlow = this.instanceFlowAuxiliary.filter((instanceFlow: InstanceFlow) => {
        return instanceFlow.Id == id;
      })

      //verifica se encontrou algo antes de atribuir ao InstanceFlowSelected
      if (instanceFlow.length) {
        instanceFlowSelected = [...instanceFlowSelected, ...instanceFlow]
      }
    })

    this.instanceFlowRefFieldModal = []

    return [...instanceFlowSelected]
  }

  sourceTargetCheck() {
    this.instanceSystemSourceName
    this.instanceSystemTargetName
  }
  onInstanceFlowChange(event: boolean) {
    //obtem o Id dos InstanceFlow selecionados

    let filterMessageLogInstanceFlows: []

    let instanceFlowIds: [] = this.searchLogsForm.get('instanceFlows').value ? this.searchLogsForm.get('instanceFlows').value : filterMessageLogInstanceFlows

    try {

      if (!event) {

        let instanceFlowSelected: InstanceFlow[] = []

        instanceFlowSelected = this.filtraInstanceFlow(instanceFlowIds)

        this.configuraLabelFlowBreadcrumb(instanceFlowIds, instanceFlowSelected)

        // inicio configuração dos InstanceSystem
        let instanceSystemSource: InstanceSystem[] = []
        let instanceSystemTarget: InstanceSystem[] = []
        //verifica InstanceFlowSelected possui algo antes de continuar

        if (instanceFlowSelected.length) {
          instanceFlowSelected.forEach(instanceFlow => {
            //Lógica para localizar o InstanceSystemSource
            let instanceSystemSources = this.instanceSystemAuxiliary.filter(instanceSystem => {
              return instanceSystem.Id == instanceFlow.SourceSysId
            })
            if (instanceSystemSources) {
              instanceSystemSource = [...instanceSystemSource, ...instanceSystemSources]
            }
            //Lógica para localizar o InstanceSystemTarget
            let instanceSystemTargets = this.instanceSystemAuxiliary.filter(instanceSystem => {
              return instanceSystem.Id == instanceFlow.TargetSysId
            })
            if (instanceSystemTargets) {
              instanceSystemTarget = [...instanceSystemTarget, ...instanceSystemTargets]
            }
          })
        }

        if (instanceSystemSource.length) {
          //lógica para não permitir instanceSystem duplicados
          this.instanceSystemSource = [...new Map(instanceSystemSource.map(item => [item.Id, item])).values()]
          // this.instanceSystemSource = [...instanceSystemSource]
        }

        if (instanceSystemTarget.length) {
          //lógica para não permitir instanceSystem duplicados
          this.instanceSystemTarget = [...new Map(instanceSystemTarget.map(item => [item.Id, item])).values()]
          // this.instanceSystemTarget = [...instanceSystemTarget]
        }
        // fim configuração dos InstanceSystem


        if (instanceFlowSelected.length) {

          //verifica se recuperou alguma informaçao do InstanceFlowRefField
          this.configuraInstanceFlowRefField(instanceFlowSelected)

        }
      }

    } catch (e) {

      //this.instanceFlows = [...this.instanceFlowAuxiliary]
      this.instanceFlowRefField = []

      //this.onFlowGroupChange(false);   
    }


  }

  configuraInstanceFlowRefField(instanceFlowSelected: InstanceFlow[]) {

    let instanceFlowRefFieldsFiltered: InstanceFlowRefField[] = []

    //seleciona os instanceFlowRefFields de cada InstanceFlow selecionado no drop
    instanceFlowSelected.forEach(instanceFlow => {
      let instanceFlowRefFields = this.instanceFlowRefFieldAuxiliary.filter(instanceFlowRefField => {
        return instanceFlowRefField.InstanceFlowId == instanceFlow.Id;
      })

      //verifica se encontrou algo antes de atribuir ao instanceFlowRefFieldsFiltered
      if (instanceFlowRefFields.length) {
        instanceFlowRefFieldsFiltered = [
          ...instanceFlowRefFieldsFiltered,
          ...instanceFlowRefFields
        ]
      }
    })

    // verifica ha campos de referencia para os fluxos selecionados
    // caso contrário zera o array instanceFlowRefField

    if (instanceFlowRefFieldsFiltered.length) {

      this.instanceFlowRefField = [...instanceFlowRefFieldsFiltered.filter(active => active.Active == 1)]
    } else {
      this.instanceFlowRefField = []
    }

  }

  configuraRerenceFieldType(instanceFlowSelected: InstanceFlow[]) {

    let instanceFlowRefFieldsFiltered: InstanceFlowRefField[] = []

    //seleciona os instanceFlowRefFields de cada InstanceFlow selecionado no drop
    instanceFlowSelected.forEach(instanceFlow => {
      let instanceFlowRefFields = this.instanceFlowRefField.filter(instanceFlowRefField => {
        return instanceFlowRefField.InstanceFlowId == instanceFlow.Id;
      })

      //verifica se encontrou algo antes de atribuir ao instanceFlowRefFieldsFiltered
      if (instanceFlowRefFields.length) {
        instanceFlowRefFieldsFiltered = [
          ...instanceFlowRefFieldsFiltered,
          ...instanceFlowRefFields
        ]
      }
    })

    //filtra os referenceFieldType através do instanceFlowRefFieldsFiltered
    let referenceFieldTypeFiltered: ReferenceFieldType[] = []
    if (instanceFlowRefFieldsFiltered.length) {
      //como pode haver instanceFlowRefFields repetidos, o passo a baixo elimina os repetidos, comparando-os pelo nome
      instanceFlowRefFieldsFiltered = [...new Map(instanceFlowRefFieldsFiltered.map(item => [item.TypeName, item])).values()]


      instanceFlowRefFieldsFiltered.forEach(instanceFlowRefField => {
        let referenceFieldType = this.referenceFieldsAuxiliary.filter(referenceField =>
          instanceFlowRefField.TypeId == referenceField.Id
        )

        if (referenceFieldType.length) {
          referenceFieldTypeFiltered = [...referenceFieldTypeFiltered, ...referenceFieldType]
        }
      })

      if (referenceFieldTypeFiltered.length) {
        //this.referenceFields é o array que popula o drop da tela
        this.referenceFields = [...referenceFieldTypeFiltered]

        // this.criaRerenceFieldTypeInputs(this.referenceFields)
      }

    }
  }

  get getReferenceFieldList(): FormArray {
    return this.searchLogsForm.get('referenceFieldList') as FormArray;
  }

  criaRerenceFieldTypeInputs(referenceFields: ReferenceFieldType[]) {

    referenceFields.forEach(referenceField => {
      this.getReferenceFieldList.push(this.fb.group({
        Id: referenceField.Id,
        Name: referenceField.Name
      }))
    })
  }

  //filtra os FlowGroups pelo Id e inclui num array
  filtraFlowGroups(flowGroupIds: number[]) {
    let flowGroupsSelected: FlowGroup[] = []

    flowGroupIds.forEach((id) => {
      let flowGroup = this.flowGroups.filter((flowGroup: FlowGroup) => {
        return flowGroup.Id == id;
      })

      if (flowGroup) {
        flowGroupsSelected = [...flowGroupsSelected, ...flowGroup]
      }
    })

    return [...flowGroupsSelected]
  }



  onFlowGroupChange(event: boolean) {

    //obtem o Id dos flowGroups selecionados

    let flowGroupIds: [] = this.searchLogsForm.get('flowGroups').value
    try {
      //inicio configura a drop do InstanceFlow
      if (!event) {


        let flowGroupsSelected: FlowGroup[] = []
        flowGroupIds.forEach((id) => {
          let flowGroup = this.flowGroups.filter((flowGroup: FlowGroup) => {
            return flowGroup.Id == id;
          })

          if (flowGroup) {
            flowGroupsSelected = [...flowGroupsSelected, ...flowGroup]
          }
        })

        this.configuraLabelFlowGroupBreadcrumb(flowGroupIds, flowGroupsSelected)

        let instanceFlowsTemp = []
        //filtra o InstanceFlow de cada FlowGroup
        if (flowGroupsSelected.length) {

          if (JSON.stringify(this.flowGroupIdsAuxiliary) != JSON.stringify(flowGroupIds)) {
            this.flowGroupIdsAuxiliary = [...flowGroupIds]

            this.instanceFlows = []
            this.searchLogsForm.get('instanceFlows').reset();
          }


          flowGroupsSelected.forEach(flowGroup => {
            let instaceFlow = this.instanceFlowAuxiliary.filter(instFlow => {
              return instFlow.GroupId == flowGroup.Id
            })

            if (instaceFlow.length) {
              instanceFlowsTemp = [...instanceFlowsTemp, ...instaceFlow]
            }
          })
        } else {
          // this.configuraLabelFlowGroupBreadcrumb(flowGroupIds)
          this.searchLogsForm.get('instanceFlows').reset();
          this.instanceFlows = [...this.instanceFlowAuxiliary]
        }

        //verifica se há fluxos para o(s) Grupo(s) de Fluxo(s) selecionado(s)
        if (instanceFlowsTemp.length) {
          this.instanceFlows = [...instanceFlowsTemp]
        }

        //verifica se há fluxos para o(s) Grupo(s) de Fluxo(s) selecionado(s)
        if (flowGroupsSelected.length && !instanceFlowsTemp.length) {
          this.instanceFlows = []
        }

      }
      //fim configura a drop do InstanceFlow

    } catch (e) {


      this.instanceFlows = [...this.instanceFlowAuxiliary]
    }
    this.onSiteGroupDropClose(false)
  }

  /**
   * Configura a label que fica abaixo do breadcrumb, mostra a opção "Todos" ou o nome dos Grupo de Fluxo
   * @param {number[]} flowGroupIds - Id dos Grupos de Fluxos selecionados no select
   * @param {FlowGroup[]} flowGroupsSelected -  Grupos de Fluxos selecionados no select
   */
  configuraLabelFlowGroupBreadcrumb(flowGroupIds: number[], flowGroupsSelected?: FlowGroup[],) {

    //verifica se apção "Todos" (id = 0) foi selecionado, ou se array de flowGroupIds está vazio, e seta breadCrumbLabel como true para informar que todos foram selecionados. Obs: no back quando não é passado informação nenhuma nesse campo ele considera a opção todos
    if (flowGroupIds[0] == 0 || !flowGroupIds.length) {
      this.showFlowGroupBreadCrumbLabel = true
    } else {
      this.showFlowGroupBreadCrumbLabel = false
      this.flowGroupNames = flowGroupsSelected.map((flowGroup) => flowGroup.Name).join(', ')
    }
  }

  /**
  * Configura a label que fica abaixo do breadcrumb, mostra a opção "Todos" ou o nome dos Grupo de Fluxo
  * @param {number[]} flowIds - Id dos Grupos de Fluxos selecionados no select
  * @param {FlowGroup[]} flowSelected -  Grupos de Fluxos selecionados no select
  */
  configuraLabelFlowBreadcrumb(flowIds: number[], flowSelected?: InstanceFlow[]) {

    //verifica se apção "Todos" (id = 0) foi selecionado, ou se array de flowIds está vazio, e seta breadCrumbLabel como true para informar que todos foram selecionados. Obs: no back quando não é passado informação nenhuma nesse campo ele considera a opção todos
    if (flowIds[0] == 0 || !flowIds.length) {
      this.showFlowBreadCrumbLabel = true
    } else {
      this.showFlowBreadCrumbLabel = false
      this.flowNames = flowSelected.map((flow) => flow.Name).join(', ')
    }
  }



  onSiteGroupDropClose(event: boolean) {

    if (!event) {
      //obtem o Id dos flowGroups selecionados
      let siteGroupIds: [] = this.searchLogsForm.get('siteGroups').value

      if (siteGroupIds && siteGroupIds.length) {
        //filtra os SiteGroups pelo Id e inclui num array
        let siteGroupSelected: SiteGroup[] = []
        siteGroupIds.forEach((id) => {
          let siteGroup = this.siteGroups.filter((siteGroup: SiteGroup) => {
            return siteGroup.Id == id;
          })

          if (siteGroup) {
            siteGroupSelected = [...siteGroupSelected, ...siteGroup]
          }
        })


        let sitesTemp = []
        //filtra o Site de cada SiteGroup
        if (siteGroupSelected.length) {
          siteGroupSelected.forEach(siteGroup => {
            let site = this.sitesAuxiliary.filter(site => {
              return site.GroupId == siteGroup.Id
            })

            if (site) {
              sitesTemp = [...sitesTemp, ...site]
            }
          })
        }

        if (sitesTemp.length) {
          this.sites = [...sitesTemp]
        }
      } else {
        this.sites = [...this.sitesAuxiliary]
      }


    }
  }

  /** @description retorna lista de campos de referencia
  */
  setDropReferenceFilds() {

    const result = [];
    const map = new Map();
    let completeList = true;

    let formraw = this.searchLogsForm.getRawValue();

    if (formraw.integrationsId) {
      if (formraw.integrationsId.length > 0) {
        completeList = false
      }
    }

    this.removeAllItem()
    if (completeList) {
      this.flowGroups.forEach(x => {
        for (const item of x.Reference_Fild) {

          if (!map.has(item.Id)) {
            map.set(item.Id, true);    // set any value to Map
            result.push({
              Id: item.Id,
              FieldDescription: item.FieldDescription
            });
          }
        }
      })
    } else {
      formraw.integrationsId.forEach(x => {
        let index = this.arrayObjectIndexOf(this.flowGroups, x, 'Id')
        for (const item of this.flowGroups[index].Reference_Fild) {

          if (!map.has(item.Id)) {
            map.set(item.Id, true);    // set any value to Map
            result.push({
              Id: item.Id,
              FieldDescription: item.FieldDescription
            });
          }
        }
      })
    }

    return result
  }

  arrayObjectIndexOf(myArray, searchTerm, property) {
    for (let i = 0, len = myArray.length; i < len; i++) {
      if (myArray[i][property] === searchTerm) return i;
    }
    return -1;
  }

  /** @description observa mudanças feitas nos campos de datapiker (usado junto com a função 'bindData' para corrigir mascara do angular material)
  */
  onDataChanges() {

  };

  /** @description aplica mudanças feita no datepicker no campo data no formbuilder
  */
  bindData(event, picker) {

    if (event.target.value) {
      if (event.target.value.length == 10) {
        if (picker == 'dateStart') {
          this.searchLogsForm.get('dateStart').setValue(this.utilsService.formatStringToDate(event.target.value))
        } else {
          this.searchLogsForm.get('dateEnd').setValue(this.utilsService.formatStringToDate(event.target.value))
        }
      }
    }
  }

  /** @description inclui no objeto do filtro a opção de horas selecionada no filtro usada porque esta opção não faz parte do formbuilder
  * @param {hs} hs horas informadas no filtro
  */
  setPeriod({ Value, Unity }) {


    this.searchLogsForm.get('interval').setValue(Value);
    this.searchLogsForm.get('intervalUnity').setValue(String(Unity));
    this.searchLogsForm.get('hourStart').setValue(null);
    this.searchLogsForm.get('hourEnd').setValue(null);
    this.searchLogsForm.get('dateStart').setValue(null);
    this.searchLogsForm.get('dateEnd').setValue(null);
    this.search()

  }

  /** @description popula objeto com infos do filtro salva no store novo filtro e emit para comonent container
  */
  search() {

    //this.searchFieldSelected = []
    let filterSearchLog: SearchFilterMessageLog = this.searchLogsForm.getRawValue();    
    
    filterSearchLog['statusIds'][0] == 0 ? delete filterSearchLog.statusIds : ''

    // Object.keys(filterSearchLog).forEach((key) => (filterSearchLog[key] == null || filterSearchLog[key] == "") && delete filterSearchLog[key]);
    //Object.keys(filterSearchLog).forEach((key) => filterSearchLog[key] != '' && filterSearchLog[key] != null && key != 'interval' && key != 'intervalUnity' ? this.searchFieldSelected.push(key) : '');

    //se o campo referenceField estiver vazio, zera o referenceFieldList
    if (this.searchLogsForm.get('referenceField').value && !this.searchLogsForm.get('referenceField').value.length) {
      filterSearchLog.referenceFieldList = []
    }

    this.store.dispatch(FilterLogsActions.addFilter({ searchFilterMessageLog: filterSearchLog }))

    this.filterSearchLog.emit(filterSearchLog);
    this.btnHide = false;

  }

  ngOnDestroy() {

    // this.storeSubscription.unsubscribe()
    this.subscriptions.unsubscribe()
  }
  // favoritos
  saveFavorite() {
    let language
    switch (localStorage.getItem('appLanguage')
    ) {
      case 'en':
        language = require("../../../../../assets/i18n/en.json")
        break;
      case 'pt':
        language = require("../../../../../assets/i18n/pt.json")
        break;
      default:
        console.log(`Sorry, we are out of.`);
    }
    swalWithBootstrapButtons.fire({
      title: language.DEFAULT.FAVORITEFILTER,
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: language.REGISTER.ACTIONS.CONFIRMDELETE,
      cancelButtonText: language.REGISTER.ACTIONS.CANCELDELETE,
      showLoaderOnConfirm: true,
    }).then(async (result) => {
      if (result.value) {
        // this.loaderService.show()
        let filterLocation = 'message-logs'
        let instanceId = this.sessionService.getInstanceLogin().Id;
        let userId = this.storageService.getStorageUser();
        let fav = this.searchLogsForm.getRawValue()

        for (let item in fav) {
          !fav[item] && item != 'intervalUnity' ? delete fav[item] : ''
          fav[item] && fav[item].length == 0 ? delete fav[item] : ''
          fav[item] && fav[item][0] == 0 && item != 'intervalUnity' ? fav[item].shift() : ''
        }
        // se statusId 8 DETAILS = 'Detalhe',.find(stats => stats == 8)
        // DETAILSMESSAGE = 'DetalheMensagem'  

        let saveQuery = `?InstanceId=${instanceId}`
        fav.statusIds && fav.statusIds.find(stats => stats == 8) ? saveQuery += '&Tipo=Detalhe' : saveQuery += '&Tipo=DetalheMensagem'

        fav.interval ? saveQuery += `&Interval=${fav.interval}&IntervalUnity=${fav.intervalUnity}` : ''
        fav.dateStart ? saveQuery += `&DataInicial=${fav.dateStart.toISOString().substring(0, 10)}` : ''
        fav.dateEnd ? saveQuery += `&DataFinal=${fav.dateEnd.toISOString().substring(0, 10)}` : ''
        fav.hourStart ? saveQuery += `&HoraInicial=${fav.hourStart}:00` : ''
        fav.hourEnd ? saveQuery += `&HoraFinal=${fav.hourEnd}:59` : ''
        fav.statusIds ? saveQuery += `&StatusIds=${fav.statusIds.toString()}` : ''
        fav.eventTypeIds ? saveQuery += `&EventTypeIds=${fav.eventTypeIds.toString()}` : ''
        fav.sites ? saveQuery += `&SiteIds=${fav.sites.toString()}` : ''
        fav.siteGroups ? saveQuery += `&SiteGroupIds=${fav.siteGroups.toString()}` : ''
        fav.flowGroups ? saveQuery += `&InstanceFlowGroupIds=${fav.flowGroups.toString()}` : ''
        fav.instanceFlows ? saveQuery += `&InstanceFlowIds=${fav.instanceFlows.toString()}` : ''
        this.instanceSystemSource ? saveQuery += `&SourceSysIds=${this.instanceSystemSource.map((camp) => camp.Id).join(',')}` : ''
        this.instanceSystemTarget ? saveQuery += `&TargetSysIds=${this.instanceSystemTarget.map((camp) => camp.Id).join(',')}` : ''
        if (fav.instanceFlowRefFieldList) {
          let refFieldIds = []
          let fieldValues = []
          for (let instFlow of fav.instanceFlowRefFieldList) {
            refFieldIds.push(instFlow.Id)
            fieldValues.push(instFlow.Value)
          }
          saveQuery += `&ReferenceFieldIds=${refFieldIds.toString()}&FieldValues=${fieldValues.toString()}`
        }
        if (fav.refFieldList) {
          let typeIds = []
          let typeValues = []
          for (let refField of fav.refFieldList) {
            typeIds.push(refField.Id)
            typeValues.push(refField.Value)
          }
          saveQuery += `&TypeIds=${typeIds.toString()}&ReferenceFieldTypeValues=${typeValues.toString()}`
        }

        let filterSave: FilterObj = {
          Name: result.value,
          URL: filterLocation,
          FilterQuery: saveQuery,
          UserId: userId['UserId']
        }
        this.filterSubscriptions.postFilterSubscription = this.monitorService.saveFilter(filterSave)
          .subscribe(
            (data: HttpResponse<any>) => {
              this.utilsService.showAlert('S', data['result'])
              this.getAllFavorites()
            },
            this.utilsService.showAlertRequestError
          ).add(() => {
            this.loaderService.hide();
          })

      } else {
        // alert colcoar nome

        // this.utilsService.showAlert('W', language.DEFAULT.NAMEFILTERERROR)
      }
    })
  }

  favorited(show) {
    this.getAllFavorites()
    if (show == 'open') {
      this.showCard = true;
    } else {
      this.showCard = false;
    }

    // this.listaFavoritos = []
    // let favoritos: SearchFilterMessageLog = this.searchLogsForm.getRawValue();
    // this.listaFavoritos.push(favoritos)      
  }

  getAllFavorites() {

    let userId = this.storageService.getStorageUser();
    this.monitorService.getAllFilters('message-logs', userId['UserId'])
      .subscribe(
        (data: HttpResponse<any>) => {

          this.listaFavoritos = data['result']
        })
  }



  removeFavorite(id, url) {
    let language
    switch (localStorage.getItem('appLanguage')
    ) {
      case 'en':
        language = require("../../../../../assets/i18n/en.json")
        break;
      case 'pt':
        language = require("../../../../../assets/i18n/pt.json")
        break;
      default:
        console.log(`Sorry, we are out of.`);
    }
    swalWithBootstrapButtons.fire({
      title: language.DEFAULT.FILTERDELETE,
      text: language.REGISTER.ACTIONS.TEXTDELETE,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: language.REGISTER.ACTIONS.CONFIRMDELETE,
      cancelButtonText: language.REGISTER.ACTIONS.CANCELDELETE,
    }).then(async (result) => {
      if (result.value) {
        this.loaderService.show()
        this.filterSubscriptions.deleteFilterSubscription = this.monitorService.deleteFilters(id, url)
          .subscribe(data => {
            this.utilsService.showAlert('S', data['result'])
            this.getAllFavorites()
          },
            this.utilsService.showAlertRequestError
          ).add(() => {

            this.loaderService.hide()
          });
        this.allSubscriptions.add(this.filterSubscriptions.deleteFilterSubscription)
      } else {

      }
    })

  }




  setFilterSearchLog(url) {

    this.clear()
    let refFieldTypeAll = []
    let refFieldAll = []
    let filterQuery = url.split("&")
    for (let key in filterQuery) {
      let quary = filterQuery[key].split("=")
      switch (quary[0]) {
        case 'Interval':
          this.searchLogsForm.get('interval').setValue(quary[1]);
          break;
        case 'IntervalUnity':
          this.searchLogsForm.get('intervalUnity').setValue(quary[1]);
          break;
        case 'DataInicial':
          let dateStart = quary[1] + 'T03:00:00.000Z';
          this.searchLogsForm.get('dateStart').setValue(dateStart);
          break;
        case 'DataFinal':
          let dateEnd = quary[1] + 'T03:00:00.000Z';
          this.searchLogsForm.get('dateEnd').setValue(dateEnd);
          break;
        case 'HoraInicial':
          let hourStart = quary[1].substring(0, 5);
          this.searchLogsForm.get('hourStart').setValue(hourStart);
          break;
        case 'HoraFinal':
          let hourEnd = quary[1].substring(0, 5);
          this.searchLogsForm.get('hourEnd').setValue(hourEnd);
          break;
        case 'StatusIds':
          this.searchLogsForm.get('statusIds').setValue(quary[1].split(","))

          break;
        case 'EventTypeIds':
          let eventTypeSelected = quary[1].split(",")
          this.eventTypeList.length == eventTypeSelected.length ? eventTypeSelected.unshift(0) : ''
          this.searchLogsForm.get('eventTypeIds').setValue(eventTypeSelected)

          break;
        case 'SiteIds':
          let siteSelected = quary[1].split(",")
          this.sites.length == siteSelected.length ? siteSelected.unshift(0) : ''
          this.searchLogsForm.get('sites').setValue(siteSelected)

          break;
        case 'SiteGroupIds':
          let siteGroupSelected = quary[1].split(",")
          this.siteGroups.length == siteGroupSelected.length ? siteGroupSelected.unshift(0) : ''
          this.searchLogsForm.get('siteGroups').setValue(siteGroupSelected)

          break;
        case 'InstanceFlowGroupIds':
          let flowGroupSelected = quary[1].split(",")
          this.flowGroups.length == flowGroupSelected.length ? flowGroupSelected.unshift(0) : ''
          this.searchLogsForm.get('flowGroups').setValue(flowGroupSelected)

          break;
        case 'InstanceFlowIds':
          let instanceFlowSelected = quary[1].split(",")
          this.instanceFlows.length == instanceFlowSelected.length ? instanceFlowSelected.unshift(0) : ''
          this.searchLogsForm.get('instanceFlows').setValue(instanceFlowSelected)

          break;
        case 'SourceSysIds':

          let instanceSystemSourceSelected = quary[1].split(",")
          this.instanceSystemSource.length == instanceSystemSourceSelected.length ? instanceSystemSourceSelected.unshift(0) : ''
          this.searchLogsForm.get('instanceSystemSource').setValue(instanceSystemSourceSelected)

          break;
        case 'TargetSysIds':
          let instanceSystemTargetSelected = quary[1].split(",")
          this.instanceSystemTarget.length == instanceSystemTargetSelected.length ? instanceSystemTargetSelected.unshift(0) : ''
          this.searchLogsForm.get('instanceSystemTarget').setValue(instanceSystemTargetSelected)

          break;
        //&TypeIds=28&ReferenceFieldTypeValues=fafafaf  
        case 'TypeIds':
          let TypeIdsSelected = quary[1].split(",")
          for (let i = 0; i < TypeIdsSelected.length; i++) {
            this.refFieldTypes.forEach((element) => {
              element.Id == TypeIdsSelected[i] ? refFieldTypeAll.push(element) : ''
            });
          }
          break;
        case 'ReferenceFieldTypeValues':
          let values = quary[1].split(",")
          for (let i = 0; i < refFieldTypeAll.length; i++) {
            refFieldTypeAll[i].Value = values[i]
          }
          this.refFieldTypeOptions.patchValue(refFieldTypeAll)
          this.refFieldTypesSelected = refFieldTypeAll

          break;
        case 'ReferenceFieldIds':
          this.onInstanceFlowChange(false)
          let refFieldSelected = quary[1].split(",")
          //refFieldAll
          for (let i = 0; i < refFieldSelected.length; i++) {
            this.instanceFlowRefField.forEach((element) => {
              element.Id == refFieldSelected[i] ? refFieldAll.push(element) : ''
            });
          }

          break;
        case 'FieldValues':
          let fieldValues = quary[1].split(",")
          for (let i = 0; i < refFieldAll.length; i++) {
            refFieldAll[i].Value = fieldValues[i]
          }
          this.instanceFlowRefFieldOptions.patchValue(refFieldAll)
          this.instanceFlowRefFieldsSelected = refFieldAll
          break;
        default:
        // code block
      }
    }
  }
}
