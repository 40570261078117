import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { fullScreenComponent } from './full-screen.component';

@NgModule({
  declarations: [ fullScreenComponent ],
  exports: [ fullScreenComponent ],
  imports: [
    CommonModule
  ]
})

export class AppfullScreenModule { }