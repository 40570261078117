import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbsComponent } from './breadcrumbs.component';

@NgModule({
  declarations: [ BreadcrumbsComponent ],
  exports: [ BreadcrumbsComponent ],
  imports: [
    CommonModule
  ]
})

export class AppBreadcrumbsModule { }