import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PaginationComponent } from './pagination.component';

import { AngularMaterialModule } from '../../../shared/angular-material.module';

@NgModule({
  declarations: [ PaginationComponent ],
  exports: [ PaginationComponent ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    FormsModule
  ]
})

export class PaginationModule { }