import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup,FormControl,Validators } from '@angular/forms';
import { UtilsService } from 'src/app/services/utils/utils.service';

export interface Language {
  name: string;
  id: string;
}

export const languages: Language[] = [
  {
    name: "PT/BR",
    id: "pt"
  },
  {
    name: "EN/US",
    id: "en"
  }
]

@Component({
  selector: 'app-translate-language',
  templateUrl: './translate-language.component.html',
  styleUrls: ['./translate-language.component.scss'],
  host: {
    'class' : 'nav-item nav-settings d-lg-flex'
  }
})
export class TranslateLanguageComponent implements OnInit {
 
  selectedLanguage;
  languages = languages
  
  constructor(
    public translate: TranslateService,
    private fb: FormBuilder,
    private utilsService: UtilsService

  ) {
    // const browserLang = translate.getBrowserLang();
	  const browserLang = localStorage.getItem('appLanguage')  
    // localStorage.setItem('appLanguage','pt')
    translate.use(browserLang.match(/en|pt/) ? browserLang : 'en');
  }

  ngOnInit() {
   this.selectedLanguage = localStorage.getItem('appLanguage')  
  }

  changeLanguage({value}) {  
   
    localStorage.setItem('appLanguage',value) 
    this.translate.setDefaultLang(value)
    this.translate.use(value)
    this.utilsService.closeAlertRequest()          

  }
  tste(){
    
  }

}
