<div class="row mt-2" *ngIf="pageConfig.totalItens > 0">

    <div class="col-sm-12 text-right">
        <nav>
            <ul class="pagination pagination-separated d-flex pagination-primary" style="float:right">
                <li class="page-item">
                    <button class="page-link" (click)="activePage('prev')" [disabled]="disabledPrev()">
                        <i class="mdi mdi-chevron-left"></i>
                    </button>
                </li>
                <!-- (ngModelChange)="changeQtPerPage()" -->
                <!-- <li class="page-item" *ngFor="let page of pageConstructor()"><a class="page-link" (click)="activePage(page)">{{page}}</a></li> -->
                <li class="page-item">
                    <button class="page-link" (click)="activePage('next')" [disabled]="disabledNext()">
                        <i class="mdi mdi-chevron-right"></i>
                    </button>
                </li>
            </ul>
            <span class="mt-2 mr-3 ml-3 small" style="float:right">{{initialAmount()}} - {{finalAmount()}} de {{_pageConfig.totalItens}}</span>

            <span class="" style="float:right">
                <form [formGroup]="selectPages" novalidate autocomplete="off">
                    <div class="form-group" style="width: 75px;">
                        <mat-form-field>
                            <mat-select class="form-control-sm" formControlName="qtdPerPage" (ngModelChange)="activePage()">
                                <mat-option value="{{item}}" *ngFor="let item of pageOptions">{{item}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </form>
            </span>
        </nav>
    </div>
</div>