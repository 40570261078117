import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CONSTANTS } from '../../../core/constants/constants'
import { UtilsService } from 'src/app/services/utils/utils.service';
import { environment } from '../../../../environments/environment';


export interface SiteGroup {
    Id?: number
    Name?: string
    InstanceId?: number
    InstanceName?: string
}

@Injectable({ providedIn: 'root' })
export class SiteGroupService {

    constructor(
        private httpClient: HttpClient,
        private utilsService: UtilsService
    ){
        
    }

    getSiteGroupListByInstance() {        
        return this.httpClient
            .get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.SITEGROUP}?InstanceId=${this.utilsService.getInstanceLoginId()}`, { observe: 'response' })
    }

    getSiteGroupListAll() {
        return this.httpClient
            .get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.SITEGROUP}`, { observe: 'response' })
    }

    getSiteGroupList(page: string) {
        if (!page) {
            page = `?InstanceId=${this.utilsService.getInstanceLoginId()}&Page=1`
        }
        // else{
        //     page = page.replace('?', '&')
        // }
        return this.httpClient
        .get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.SITEGROUP}${page}`, { observe: 'response' })
    }

    getSiteGroup(siteGroup: SiteGroup, page) {
      
        let filtroFinal
        if (page) {
          filtroFinal = page
        } else{
          filtroFinal = `?InstanceId=${this.utilsService.getInstanceLoginId()}&Name=${(siteGroup['Name'] ? siteGroup.Name : '')}&Page=1`
        }

        return this.httpClient.get<any>(`${environment.apiUrl}${CONSTANTS.ENDPOINT.SITEGROUP}${filtroFinal}`, { observe: 'response' });
    }

    updateSiteGroup(siteGroup: SiteGroup) {
       siteGroup.InstanceId = this.utilsService.getInstanceLoginId()
        return this.httpClient.put(`${environment.apiUrl}${CONSTANTS.ENDPOINT.SITEGROUP}`, siteGroup, { observe: 'response' });
    }

    postSiteGroup(siteGroup: SiteGroup) {
        siteGroup.InstanceId = this.utilsService.getInstanceLoginId()
        return this.httpClient.post(`${environment.apiUrl}${CONSTANTS.ENDPOINT.SITEGROUP}`, siteGroup, { observe: 'response' });
    }

    deleteSiteGroup(Id: number) {
        return this.httpClient.delete(`${environment.apiUrl}${CONSTANTS.ENDPOINT.SITEGROUP}?Id=${Id}`, { observe: 'response' });
    }
}