<div class="template-demo">
    <div class="col-12">
        <nav class="breadcrumb-container" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <!-- <li class="breadcrumb-item"><a href="#">Home</a></li> -->
                <li class="breadcrumb-item" *ngFor="let item of rotas" >{{item}}</li>
                <!-- <li class="breadcrumb-item active text-secondary" aria-current="page">{{route}}</li> -->
            </ol>
        </nav>
    </div>
</div>