import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {StretchCardComponent} from './stretch-card.component'
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { AngularMaterialModule } from 'src/app/shared/angular-material.module';


@NgModule({
  declarations: [StretchCardComponent],
  exports: [StretchCardComponent],
  imports: [
    CommonModule,
    TranslateModule,
    AngularMaterialModule
  ]
})
export class StretchCardModule { }
