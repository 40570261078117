
<div >
  
  <form [formGroup]="searchLogsForm">
    <div class="row d-flex align-items-center justify-content-between">
      <div class="col-12  mb-2 filter-top-monitor"
        [ngClass]="{'col-md-2':instanceInterval.Interval && instanceInterval.Interval.length==2, 'col-md-3': instanceInterval.Interval && instanceInterval.Interval.length== 3, 'col-md-4': instanceInterval.Interval && instanceInterval.Interval.length== 4, 'col-md-5': instanceInterval.Interval && instanceInterval.Interval.length>= 5}"
        disabled>
        <div class="btn-group w-100 interval-container" role="group" aria-label="Basic example">
          <button type="button" class="btn btn-sm btn-primary d-flex justify-content-center"
            [ngClass]="{'active': searchLogsForm.get('interval').value == interval.Value && searchLogsForm.get('intervalUnity').value == interval.Unity}"
            (click)="setPeriod(interval)" *ngFor="let interval of instanceInterval.Interval">{{interval.Value}}-
            <span *ngIf="interval.Unity == '0'">
              {{(idiomaAtual == 'pt'? instanceInterval.UnityTranslate?.HourBr :
                            instanceInterval.UnityTranslate?.HourEn)}}
            </span>
            <span *ngIf="interval.Unity == '1'">
              {{(idiomaAtual == 'pt'? instanceInterval.UnityTranslate?.DayBr :
                            instanceInterval.UnityTranslate?.DayEn)}}
            </span>
          </button>

        </div>


      </div>

      <div class="col-12 col-md d-flex justify-content-end">
        <button type="submit" class="btn btn-primary btn-sm btn-search filter-btn-pos" (click)="btnHide = !btnHide">
          <div *ngIf="searchFieldSelected?.length != 0" class="btn-notification-instanceFlowRefField dots">
            <span>{{ searchFieldSelected?.length }}</span>
          </div>
          <i class="fas fa-filter"></i>
          {{ "DEFAULT.SEARCHFIELDS" | translate }}
        </button>
      </div>
    </div>

    <div class="modal-bg" *ngIf="btnHide">
      <div class="modal-container" [ngClass]="{'flow-group-panel-top': location == 'flow-group-panel','flow-panel-top': location == 'flow-panel','message-logs-top': location == 'message-logs'}">
        <svg class="close-modal" (click)="btnHide = !btnHide" width="24" height="24" xmlns="http://www.w3.org/2000/svg"
          fill-rule="evenodd" clip-rule="evenodd">
          <path
            d="M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z" />
        </svg>

        <div class="row pt-3 pb-2 bg-section">

          <div class="col-12 col-md-6 d-flex align-items-center justify-content-between mb-1">
            <label class="col-4">{{ "DEFAULT.INITIAL" | translate }}</label>

            <div class="col d-flex">
              <div class="form-group data-message">
                <input class="form-control" (dateChange)="clearInterval()" [max]="maxDate" type="text"
                  [matDatepicker]="dateStart" (click)="openCalendar('dateStart')" formControlName="dateStart"
                  readonly />
                <i *ngIf="searchLogsForm.value.dateStart" class="fas fa-times clear-time"
                  (click)="clearTime('dateStart')"></i>
                <mat-datepicker-toggle class="mat-datepicker-toggle" matSuffix [for]="dateStart">
                </mat-datepicker-toggle>
                <mat-datepicker #dateStart></mat-datepicker>
              </div>

              <div class="form-group hour-message ml-1">
                <div class="d-flex justify-content-between align-items-center" style="position: relative">
                  <input class="form-control form-control-new" (click)="clearInterval()" type="time" id="initialTime"  formControlName="hourStart" />
                  <i *ngIf="searchLogsForm.value.hourStart" class="fas fa-times clear-time"
                    (click)="clearTime('hourStart')"></i>
                  <!-- <input type="time" [ngxTimepicker]="toggleTimepicker" [format]="24" (click)="clearInterval()"
                    id="initialTime" class="form-control" formControlName="hourStart" />
                  <i *ngIf="searchLogsForm.value.hourStart" class="fas fa-times clear-time"
                    (click)="clearTime('hourStart')"></i>
                  <ngx-material-timepicker-toggle class="timepicker-toggle" [for]="toggleTimepicker">
                    <svg (click)="clearInterval()" class="clock-svg" id="Capa_1"
                      enable-background="new 0 0 443.294 443.294" viewBox="0 0 443.294 443.294"
                      xmlns="http://www.w3.org/2000/svg" ngxMaterialTimepickerToggleIcon>
                      <path
                        d="m221.647 0c-122.214 0-221.647 99.433-221.647 221.647s99.433 221.647 221.647 221.647 221.647-99.433 221.647-221.647-99.433-221.647-221.647-221.647zm0 415.588c-106.941 0-193.941-87-193.941-193.941s87-193.941 193.941-193.941 193.941 87 193.941 193.941-87 193.941-193.941 193.941z" />
                      <path d="m235.5 83.118h-27.706v144.265l87.176 87.176 19.589-19.589-79.059-79.059z" />
                    </svg>
                  </ngx-material-timepicker-toggle>
                  <ngx-material-timepicker [theme]="darkTheme" #toggleTimepicker class="mr-auto bg-primary">
                  </ngx-material-timepicker> -->
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 d-flex align-items-center justify-content-between mb-1">
            <label class="col-4">{{ "DEFAULT.FINAL" | translate }}</label>
            <div class="col d-flex">
              <!-- Data Final teste 2 -->
              <div class="form-group data-message">
                <input class="form-control" (dateChange)="clearInterval()" [max]="maxDate" type="text"
                  [matDatepicker]="dateEnd" (click)="openCalendar('dateEnd')" formControlName="dateEnd" readonly />
                <i *ngIf="searchLogsForm.value.dateEnd" class="fas fa-times clear-time"
                  (click)="clearTime('dateEnd')"></i>

                <mat-datepicker-toggle class="mat-datepicker-toggle" matSuffix [for]="dateEnd"></mat-datepicker-toggle>
                <mat-datepicker #dateEnd></mat-datepicker>
              </div>
              <!-- Hora Final -->
              <div class="form-group hour-message ml-1">
                <div class="d-flex justify-content-between align-items-center">
                  <input class="form-control form-control-new" (click)="clearInterval()" type="time" id="endTime"  formControlName="hourEnd" />
                  <i *ngIf="searchLogsForm.value.hourEnd" class="fas fa-times clear-time"
                    (click)="clearTime('hourEnd')"></i>
                  <!-- <input (click)="clearInterval()" type="time" [ngxTimepicker]="toggleTimepicker2" [format]="24"
                    id="endTime" class="form-control" formControlName="hourEnd" />
                  <i *ngIf="searchLogsForm.value.hourEnd" class="fas fa-times clear-time"
                    (click)="clearTime('hourEnd')"></i>

                  <ngx-material-timepicker-toggle class="timepicker-toggle" [for]="toggleTimepicker2">
                    <svg (click)="clearInterval()" class="clock-svg" id="Capa_1"
                      enable-background="new 0 0 443.294 443.294" height="24" viewBox="0 0 443.294 443.294" width="24"
                      xmlns="http://www.w3.org/2000/svg" ngxMaterialTimepickerToggleIcon>
                      <path
                        d="m221.647 0c-122.214 0-221.647 99.433-221.647 221.647s99.433 221.647 221.647 221.647 221.647-99.433 221.647-221.647-99.433-221.647-221.647-221.647zm0 415.588c-106.941 0-193.941-87-193.941-193.941s87-193.941 193.941-193.941 193.941 87 193.941 193.941-87 193.941-193.941 193.941z" />
                      <path d="m235.5 83.118h-27.706v144.265l87.176 87.176 19.589-19.589-79.059-79.059z" />
                    </svg>
                  </ngx-material-timepicker-toggle>
                  <ngx-material-timepicker [theme]="darkTheme" #toggleTimepicker2 class="mr-auto bg-primary">
                  </ngx-material-timepicker> -->
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6 d-flex align-items-center justify-content-between mb-1">
            <label class="col-4">{{ "DEFAULT.SITEGROUP" | translate }}</label>

            <div class="form-group col">
              <mat-form-field>
                <mat-select formControlName="siteGroups" multiple (openedChange)="onSiteGroupDropClose($event)"
                  [compareWith]="utilsService.compareValues">
                  <mat-select-trigger *ngIf="allSiteGroupSelected.selected">
                    {{ allSiteGroupSelected.selected ? ("DEFAULT.ALL" | translate) : '-' }}
                  </mat-select-trigger>
                  <mat-option #allSiteGroupSelected (click)="
                        toggleAllSelectedNew('allSiteGroupSelected', 'siteGroups')
                      " [value]="0">
                    {{ "DEFAULT.SELECTALL" | translate }}</mat-option>
                  <mat-option *ngFor="let siteGroup of siteGroups" [value]="siteGroup.Id"
                    (click)="tosslePerOne('allSiteGroupSelected', 'siteGroups')">{{ siteGroup.Name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="col-12 col-md-6 d-flex align-items-center justify-content-between mb-1">
            <label class="col-4">{{ "DEFAULT.SITE" | translate }}</label>
            <div class="form-group col">
              <mat-form-field>
                <mat-select [disabled]="sites.length == 0" formControlName="sites" multiple [compareWith]="utilsService.compareValues">
                  <mat-select-trigger *ngIf="allSiteSelected.selected">
                    {{ allSiteSelected.selected ? ("DEFAULT.ALL" | translate) : '-' }}
                  </mat-select-trigger>
                  <mat-option #allSiteSelected (click)="toggleAllSelectedNew('allSiteSelected', 'sites')" [value]="0">
                    {{ "DEFAULT.SELECTALL" | translate }}</mat-option>
                  <mat-option *ngFor="let site of sites" [value]="site.Id"
                    (click)="tosslePerOneSite(allSiteSelected.viewValue)">{{ site.Name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="col-12 col-md-6 d-flex align-items-center justify-content-between mb-1">
            <label class="col-4">{{ "DEFAULT.FLOWGROUP" | translate }}</label>
            <div class="form-group col">
              <mat-form-field>
                <mat-select formControlName="flowGroups" multiple (openedChange)="onFlowGroupChange($event)"
                  [compareWith]="utilsService.compareValues">
                  <mat-select-trigger *ngIf="allFlowGroupSelected.selected">
                    {{ allFlowGroupSelected.selected ? ("DEFAULT.ALL" | translate) : '-' }}
                  </mat-select-trigger>
                  <mat-option #allFlowGroupSelected (click)="
                        toggleAllSelectedNew('allFlowGroupSelected', 'flowGroups')
                      " [value]="0">
                    {{ "DEFAULT.SELECTALL" | translate }}</mat-option>
                  <mat-option *ngFor="let item of flowGroups" [value]="item.Id" (click)="
                        tosslePerOneFlowGroup(allFlowGroupSelected.viewValue)
                      ">{{ item.Name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          
          <div class="col-12 col-md-6 d-flex align-items-center justify-content-between mb-1">
            <label class="col-4">{{ "DEFAULT.FLOW" | translate }}</label>
            <div class="form-group col">
              <mat-form-field>
                <mat-select formControlName="instanceFlows" multiple (openedChange)="onInstanceFlowChange($event)"
                  [compareWith]="utilsService.compareValues" [disabled]="!instanceFlows.length">
                  <mat-select-trigger *ngIf="allInstanceFlowSelected.selected">
                    {{ allInstanceFlowSelected.selected ? ("DEFAULT.ALL" | translate) : '-' }}
                  </mat-select-trigger>
                  <mat-option #allInstanceFlowSelected (click)="
                        toggleAllSelectedNew(
                          'allInstanceFlowSelected',
                          'instanceFlows'
                        )
                      " [value]="0">{{ "DEFAULT.SELECTALL" | translate }}</mat-option>
                  <mat-option *ngFor="let item of instanceFlows" [value]="item.Id" (click)="
                        tosslePerOneInstanceFlow(
                          allInstanceFlowSelected.viewValue
                        )
                      ">{{ item.Name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="col-12 col-md-6 d-flex align-items-center justify-content-between mb-1"
            *ngIf="location != 'flow-group-panel'">
            <label class="col-4">{{ "DEFAULT.SOURCE" | translate }}</label>
            <div class="form-group col">
              <mat-form-field>
                <mat-select formControlName="instanceSystemSource" multiple>
                  <mat-select-trigger *ngIf="allSourceSelected.selected">
                    {{ allSourceSelected.selected ? ("DEFAULT.ALL" | translate) : '-' }}
                  </mat-select-trigger>
                  <mat-option #allSourceSelected (click)="
                        toggleAllSelectedNew(
                          'allSourceSelected',
                          'instanceSystemSource'
                        )
                      " [value]="0">
                    {{ "DEFAULT.SELECTALL" | translate }}</mat-option>
                  <mat-option *ngFor="let instanceSystem of instanceSystemSource"
                    (click)="tosslePerOneSource(allSourceSelected.viewValue)" [value]="instanceSystem.Id">
                    {{ instanceSystem.Name }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="col-12 col-md-6 d-flex align-items-center justify-content-between mb-1"
            *ngIf="location != 'flow-group-panel'">
            <label class="col-4">{{ "DEFAULT.TARGET" | translate }}</label>
            <div class="form-group col">
              <mat-form-field>
                <mat-select formControlName="instanceSystemTarget" multiple>
                  <mat-select-trigger *ngIf="allTargetSelected.selected">
                    {{ allTargetSelected.selected ? ("DEFAULT.ALL" | translate) : '-' }}
                  </mat-select-trigger>
                  <mat-option #allTargetSelected (click)="
                        toggleAllSelectedNew(
                          'allTargetSelected',
                          'instanceSystemTarget'
                        )
                      " [value]="0">
                    {{ "DEFAULT.SELECTALL" | translate }}</mat-option>

                  <mat-option *ngFor="let instanceSystem of instanceSystemTarget"
                    (click)="tosslePerOneTarget(allTargetSelected.viewValue)" [value]="instanceSystem.Id">
                    {{ instanceSystem.Name }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="col-12 col-md-6 d-flex align-items-center justify-content-between mb-1">
            <label class="col-4">{{ "DEFAULT.STATUS" | translate }}</label>
            <div class="form-group col">
              <mat-form-field>
                <mat-select formControlName="statusIds" multiple [compareWith]="utilsService.compareValues" (openedChange)="statusChange($event)">
                  <mat-select-trigger *ngIf="allStatusSelected.selected">
                    {{ allStatusSelected.selected ? ("DEFAULT.ALL" | translate) : '-' }}
                  </mat-select-trigger>
                  <mat-option #allStatusSelected (click)="toggleAllSelected('status')" [value]="0">
                    {{ "DEFAULT.SELECTALL" | translate }}</mat-option>
                  <mat-option *ngFor="let statusTemp of status" [value]="statusTemp.Id"
                    (click)="tosslePerOneStatus(allStatusSelected.viewValue)">
                    {{ idiomaAtual == "pt" ? statusTemp.BrName : statusTemp.UsName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="col-12 col-md-6 d-flex align-items-center justify-content-between mb-1"
            *ngIf="location == 'message-logs'">
            <label class="col-4">
              <!-- Tipo de Evento -->
              {{ "DEFAULT.EVENTTYPE" | translate }}
            </label>
            <div class="form-group col">
              <mat-form-field>
                <mat-select [disabled]="eventTypeList.length == 0" formControlName="eventTypeIds" multiple [compareWith]="utilsService.compareValues">
                  <mat-select-trigger *ngIf="allEventTypeSelected.selected">
                    {{ allEventTypeSelected.selected ? ("DEFAULT.ALL" | translate) : '-' }}
                  </mat-select-trigger>
                  <mat-option #allEventTypeSelected (click)="toggleAllSelected('eventTypes')" [value]="0">
                    {{ "DEFAULT.SELECTALL" | translate }}</mat-option>
                  <mat-option *ngFor="let eventTypes of eventTypeList" [value]="eventTypes.Id" (click)="
                        tosslePerOneEventType(allEventTypeSelected.viewValue)
                      ">
                    {{ idiomaAtual == "pt" ? eventTypes.BrName : eventTypes.UsName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="col-12 col-md-12 d-flex align-items-center justify-content-end mb-1">
            <button (click)="saveFavorite()" class="btn btn-sm btn-primary btn-fav"
              aria-label="Example icon button with a star icon">
              <i class="far fa-star"></i>
            </button>
          </div>
        </div>


        <div class="row pt-2 pb-2 m-0" id="accordion" *ngIf="location == 'message-logs'">
          <div class="col-12 col-md-6" *ngIf="refFieldTypes">
            
            <div class="bg-reference-field mb-3">
              <!-- (click)="accordionType = !accordionType"  accordionRefField-->

              <p (click)="controlAccordion('type')" class="pt-1 pb-1 title-reference-field" data-toggle="collapse"
                href="#collapseReferenceFieldType" role="button" aria-expanded="false"
                aria-controls="collapseReferenceFieldType">
                {{ "DEFAULT.FIELDTYPE" | translate }}
                <i [ngClass]="
                      accordionType ? 'fa-chevron-up' : 'fa-chevron-down'
                    " class="fas"></i>
              </p>

              <div *ngIf="refFieldTypesSelected?.length != 0" class="btn-notification-instanceFlowRefField dots">
                <span style="color: white">{{
                  refFieldTypesSelected?.length - 1 ==
                  refFieldTypesSelected?.length
                  ? refFieldTypesSelected?.length - 1
                  : refFieldTypesSelected?.length
                  }}</span>
              </div>

              <div class="collapse collapse-filter" id="collapseReferenceFieldType" data-parent="#accordion">
                <!-- Tipo de Campo de Referencia -->
                <div class="row">
                  <div class="col-12">
                    <mat-form-field>
                      <div class="form-group d-flex align-items-center justify-content-between mb-2">
                        <label class="col-5">{{
                          "DEFAULT.FIELDTYPE" | translate
                          }}</label>
                        <mat-select [formControl]="refFieldTypeOptions" multiple>
                          <!-- <mat-select-trigger *ngIf="allRefFieldtypeSelected.selected">
                            {{ allRefFieldtypeSelected.selected ? ("DEFAULT.ALL" | translate) : '-' }}
                          </mat-select-trigger> -->
                          <!-- <mat-option #allRefFieldtypeSelected (click)="toggleAllSelectedReferenceFieldType()"
                            [value]="refFieldsTypeAllOption">
                            {{ "DEFAULT.SELECTALL" | translate }}
                          </mat-option> -->
                          <mat-option *ngFor="let rfType of refFieldTypes" [value]="rfType"
                            (click)="tosslePerOneRefFieldType(rfType)">
                            {{ rfType.Code }} - {{ rfType.Name }}
                          </mat-option>
                        </mat-select>
                      </div>
                    </mat-form-field>
                  </div>
                </div>
                <form>
                  <div class="row">
                    <!-- [ngClass]="refFieldType.Id == 0 ? 'hide-option-all' : ''" -->
                    <div class="col-12 col-md-12 mb-1" *ngFor="
                          let refFieldType of refFieldTypesSelected;
                          let i = index;
                          trackBy: trackByFn
                        " [ngClass]="
                          refFieldType.Name == '' ? 'hide-option-all' : ''
                        ">
                      <div class="form-group d-flex align-items-center justify-content-between">
                        <label class="col-5">{{ refFieldType.Name }}</label>
                        <input style="padding: 10px" type="text" class="form-control" name="{{ i }}"
                          (change)="setValueRefFieldType($event)" [(ngModel)]="refFieldTypesSelected[i].Value" />
                      </div>

                    </div>
                  </div>
                </form>
                <!-- Tipo de Campo de Referencia Fim-->
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6" *ngIf="instanceFlowRefField && instanceFlowRefField.length > 0">
            <div class="bg-reference-field mb-3">
              <div *ngIf="instanceFlowRefFieldsSelected?.length != 0"
                class="btn-notification-instanceFlowRefField dots">
                <span style="color: white">{{
                  instanceFlowRefFieldsSelected?.length - 1 ==
                  instanceFlowRefField.length
                  ? instanceFlowRefFieldsSelected?.length - 1
                  : instanceFlowRefFieldsSelected?.length
                  }}</span>
              </div>

              <p (click)="controlAccordion('ref')" class="pt-1 pb-1 title-reference-field" data-toggle="collapse"
                href="#collapseReferenceField" role="button" aria-expanded="false"
                aria-controls="collapseReferenceField">
                {{ "DEFAULT.REFERENCEFIELD" | translate }}
                <i [ngClass]="
                      accordionRefField ? 'fa-chevron-up' : 'fa-chevron-down'
                    " class="fas fa-chevron-down"></i>
              </p>
              <div class="collapse collapse-filter" id="collapseReferenceField" data-parent="#accordion">
                <!-- Campos de Referencia -->

                <div class="row">
                  <div class="col-12">
                    <mat-form-field>
                      <div class="form-group d-flex align-items-center justify-content-between mb-2">
                        <label class="col-5">{{
                            "DEFAULT.REFERENCEFIELD" | translate
                          }}</label>
                        <mat-select [disabled]="!instanceFlowRefField.length"
                          [formControl]="instanceFlowRefFieldOptions" multiple>
                          <!-- <mat-select-trigger *ngIf="allInstanceFlowRefFieldSelected.selected">
                            {{ allInstanceFlowRefFieldSelected.selected ? ("DEFAULT.ALL" | translate) : '-' }}
                          </mat-select-trigger>
                          <mat-option #allInstanceFlowRefFieldSelected (click)="toggleAllSelectedReferenceField()"
                            [value]="instanceFlowRefFieldsAllOption">
                            {{ "DEFAULT.SELECTALL" | translate }}
                          </mat-option> -->
                          <mat-option *ngFor="
                                let instanceFlowRefField of instanceFlowRefField
                              " [value]="instanceFlowRefField" (click)="
                                tosslePerOneReferenceField(instanceFlowRefField)
                              ">
                            {{ instanceFlowRefField.Code }} - {{ instanceFlowRefField.Name }}
                          </mat-option>
                        </mat-select>
                      </div>
                    </mat-form-field>
                  </div>
                </div>
                <form>
                  <div class="row">
                    <div class="col-12 col-md-12 mb-1" *ngFor="
                          let instanceFlowRefField of instanceFlowRefFieldsSelected;
                          let i = index;
                          trackBy: trackByFn
                        " [ngClass]="
                          instanceFlowRefField.Name == '' ? 'hide-option-all' : ''
                        ">
                      <div class="form-group d-flex align-items-center justify-content-between">
                        <label class="col-5">{{
                            instanceFlowRefField.Name
                          }}</label>
                        <input style="padding: 10px" type="text" class="form-control" name="{{ i }}"
                          (change)="setValueRefField($event)" [(ngModel)]="instanceFlowRefFieldsSelected[i].Value" />
                      </div>
                    </div>
                  </div>
                </form>
                <!-- Campos de Referencia Fim-->
              </div>
            </div>





          </div>
        </div>

        <div class="row pt-2 pb-2 m-0">
          <div class="col-12 col-md-9">
            <!-- Favoritos -->
            <div class="select-fav m-0" *ngIf="!showCard">
              <!-- btn-select mat-mini-fab mat-button-base mat-accent -->
              <button mat-mini-fab class="btn-select" (click)="favorited('open')">
                <i class="far fa-star"></i>
                <i class="fas fa-angle-down"></i>
              </button>
            </div>

            <div *ngIf="showCard" class="card-favoritos w-100 m-0">
              <div class="d-flex">
                <label for="">Favoritos</label>
                <button (click)="favorited('close')" class="btn-close-card">
                  <i class="fas fa-times"></i>
                </button>
              </div>

              <div class="d-flex justify-content-start flex-wrap filter-list-container">
                <button class="btn btn-sm btn-primary mr-2 mb-2" *ngFor="let fav of listaFavoritos">
                  <span (click)="setFilterSearchLog(fav.FilterQuery)">{{ fav.Name }}</span>
                  <i style="display: inline; margin-left: 5px" (click)="removeFavorite(fav.Id, fav.URL)"
                    class="fas fa-times"></i>
                </button>
              </div>
            </div>
            <!-- Favoritos Fim -->
          </div>
          <div class="col-12 col-md-3">
            <div class="col-12 d-flex justify-content-end">
              <button class="btn btn-sm btn-primary" (click)="clear()">
                {{ "CHANNEL.CLEAR" | translate }}
              </button>
              <button type="submit" (click)="search()" class="btn btn ml-2 btn-primary btn-sm">
                <i class="fas fa-search"></i>
                {{ "DEFAULT.SEARCH" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
