import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CONSTANTS } from 'src/app/core/constants/constants';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { environment } from '../../../../environments/environment';


export interface Site {
  Id: number
  Name: string
  Code?: string
  InstanceId: number
  InstanceName: string
  GroupId: number
  GroupName: string
}

@Injectable({
  providedIn: 'root'
})
export class SiteService {


  constructor(
    private httpClient: HttpClient,
    private utilsService: UtilsService
  ) { }

  getSiteListByInstance() {
    return this.httpClient.get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.SITE}?InstanceId=${this.utilsService.getInstanceLoginId()}`, { observe: 'response' })
  }

  getSiteListAll() {
    return this.httpClient.get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.SITE}`, { observe: 'response' })
  }

  getSiteList(page: string) {
    if (!page) {
      page = `?InstanceId=${this.utilsService.getInstanceLoginId()}&Page=1`
    }

    return this.httpClient.get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.SITE}${page}`, { observe: 'response' })
  }

  getSite(site: Site, page: string) {
    
    let filtroFinal
    if (page) {
      filtroFinal = page
    } else {
      filtroFinal = filtroFinal = `?InstanceId=${this.utilsService.getInstanceLoginId()}&Page=1&Name=${(site.Name ? site.Name : '')}&Code=${(site.Code ? site.Code : '')}&GroupId=${(site.GroupId ? site.GroupId : '')}`
    }

    return this.httpClient.get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.SITE}${filtroFinal}`, { observe: 'response' })
  }

  deleteSite(Id: number) {
    return this.httpClient.delete(`${environment.apiUrl}${CONSTANTS.ENDPOINT.SITE}?Id=${Id}`, { observe: 'response' })
  }

  postSite(site: Site) {
    let tempSite = this.deleteSiteProperties(site, 'post')
    tempSite.InstanceId = this.utilsService.getInstanceLoginId()
    return this.httpClient.post(`${environment.apiUrl}${CONSTANTS.ENDPOINT.SITE}`, tempSite, { observe: 'response' })
  }

  updateSite(site: Site) {
    let tempSite = this.deleteSiteProperties(site, 'put')
    tempSite.InstanceId = this.utilsService.getInstanceLoginId()
    return this.httpClient.put(`${environment.apiUrl}${CONSTANTS.ENDPOINT.SITE}`, tempSite, { observe: 'response' })
  }

  private deleteSiteProperties(site: Site, metodo: string): Site {

    if (metodo == 'post') {
      delete site.Id;
    }

    delete site.GroupName;
    delete site.InstanceName;
    delete site.InstanceName;

    return site
  }

  private createFiltroGetFiltro(site: Site, page: string): string {
    let filtroFinal
    if (page) {
      filtroFinal = page.replace('?', '&')
    } else {
      filtroFinal = `&Page=1&Name=${(site.Name ? site.Name : '')}&Code=${(site.Code ? site.Code : '')}&GroupId=${(site.GroupId ? site.GroupId : '')}`
    }

    return filtroFinal
  }
}
