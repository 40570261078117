import { createAction, props } from '@ngrx/store';
import { SearchFilterMessageLog } from '../models/SearchFilterMessageLog';

export enum FilterComponentActionTypes {
    ADDFILTER = '[Filter Component] Add Filter',
    CLEARFILTER = '[Filter Component] Clear Filter',
}

export const addFilter = createAction(
    FilterComponentActionTypes.ADDFILTER,
    props<{ searchFilterComponent: SearchFilterMessageLog }>()
)

export const clearFilter = createAction(
    FilterComponentActionTypes.CLEARFILTER,
    // props<{ searchFilterComponent: null }>()
)