import { Action } from '@ngrx/store';

export enum ActionTypes {
    AddFilter = 'ADDFILTER',
    ClearFilter = 'CLEFILTER',
    AddIntegrations = 'ADDINTEGRATIONS',
    ClearIntegrations = 'CLEINTEGRATIONS',
}

export const AddFilter = (filter: any) => {
    return <Action>{ type: ActionTypes.AddFilter, payload: filter };
}

export const ClearFilter = () => {
    return <Action>{ type: ActionTypes.ClearFilter, payload: null };
}

export const AddIntegrations = (product: any) => {
    return <Action>{ type: ActionTypes.AddIntegrations, payload: product };
}

export const ClearIntegrations = () => {
    return <Action>{ type: ActionTypes.ClearIntegrations, payload: null };
}