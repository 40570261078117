import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material';
import { FecharDialogComponent } from './fechar-dialog.component';



@NgModule({
  declarations: [FecharDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule
  ],
  exports: [FecharDialogComponent]
})
export class FecharDialogModule { }
