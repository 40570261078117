import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CONSTANTS } from '../../../core/constants/constants'
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { environment } from '../../../../environments/environment';


export interface Status {
  Id?: number
  Code?: string
  BrName: string
  BrShortName: string
  UsName: string
  UsShortName: string
  State?: string,
  Color: string
}

export interface State {
  Id?: number
  Name?: string
  NameBr?: string
}

export const STATES: State[] = [
  {
    Id: 1,
    Name: "Processing",
    NameBr: "Processando"
  },
  {
    Id: 2,
    Name: "Success",
    NameBr: "Sucesso"
  },
  {
    Id: 3,
    Name: "Retryable",
    NameBr: "Erro Retransmitido"
  },
  {
    Id: 4,
    Name: "Finished",
    NameBr: "Encerrado"
  }, {
    Id: 5,
    Name: "Error",
    NameBr: "Erro"
  },
  {
    Id: 6,
    Name: "Discarded",
    NameBr: "Descartado"
  },
  {
    Id: 7,
    Name: "Warning",
    NameBr: "Atenção"
  }
]



@Injectable({ providedIn: 'root' })
export class StatusService {

  constructor(
    private httpClient: HttpClient,
    private utilsService: UtilsService
  ) {

  }

  getStatusList(page: string) {
    if (!page) {
      page = "?Page=1"
    }
    return this.httpClient
      .get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.STATUS}${page}`, { observe: 'response' })
  }

  getStatusListAll() {
    return this.httpClient
      .get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.STATUS}`, { observe: 'response' })
  }

  getStatusListByInstance() {

    return this.httpClient
      .get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.STATUS}?InstanceId=${this.utilsService.getInstanceLoginId()}`, { observe: 'response' })
  }

  getStatus(status: Status, page) {   
    let filtro = ""
    !page ? filtro+="?Page=1" : ''
    status['BrName'] ? filtro+= `&BrName=${status['BrName']}` : ''
    status['BrShortName'] ? filtro+= `&BrShortName=${status['BrShortName']}` : ''
    status['UsName'] ? filtro+= `&UsName=${status['UsName']}` : ''
    status['UsShortName'] ? filtro+= `&UsShortName=${status['UsShortName']}` : ''
    status['Code'] ? filtro+= `&Code=${status['Code']}` : ''
    status['State'] ? filtro+= `&State=${status['State']}` : ''   
    // let filtro = (page ? `${page}` : `?BrName=${(status['BrName'] ? status.BrName : '')}&BrShortName=${(status['BrShortName'] ? status.BrShortName : '')}&UsName=${(status['UsName'] ? status.UsName : '')}&UsShortName=${(status['UsShortName'] ? status.UsShortName : '')}&Code=${(status['Code'] ? status.Code : '')}&Color=${(status.Color ? status.Color : '')}&State=${(status['State'] ? status.State : '')}`)
    return this.httpClient.get<any>(`${environment.apiUrl}${CONSTANTS.ENDPOINT.STATUS}${filtro}`, { observe: 'response' });
  }

  updateStatus(status) {
    return this.httpClient.put(`${environment.apiUrl}${CONSTANTS.ENDPOINT.STATUS}`, status, { observe: 'response' });
  }

  postStatus(status: Status) {
    let statusTemp = this.deleteStatusProperties(status, 'post')
    return this.httpClient.post(`${environment.apiUrl}${CONSTANTS.ENDPOINT.STATUS}`, statusTemp, { observe: 'response' });
  }

  deleteStatus(Id: number) {
    return this.httpClient.delete(`${environment.apiUrl}${CONSTANTS.ENDPOINT.STATUS}?Id=${Id}`, { observe: 'response' });
  }

  deleteStatusProperties(status: Status, method: string): Status {
    if (method == 'post') {
      delete status.Id
    }

    return status
  }
}