import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css'],
})

export class PaginationComponent implements OnInit, OnChanges  {
  selectPages: FormGroup;

  @Input() pageConfig: any;
  private _pageConfig: any;
  @Input() pageOptions: any = ['10','25','50','100'];
  @Output() pressPage = new EventEmitter();
  @Output() pressPrevNext = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    
    this.selectPages = new FormGroup({
			qtdPerPage: new FormControl(''),
    });
    this.selectPages.get('qtdPerPage').setValue(this.pageConfig.quantityPerPage)
  }

  ngOnChanges(changes: SimpleChanges) {
    const pageConfig: SimpleChange = changes.pageConfig;
    pageConfig.currentValue.quantityPerPage = pageConfig.currentValue.quantityPerPage.toString()    
    this._pageConfig = pageConfig.currentValue;
    if ((pageConfig.previousValue.quantityPerPage != pageConfig.currentValue.quantityPerPage) && pageConfig.currentValue.totalItens > 0) {
      this.selectPages.get('qtdPerPage').setValue(pageConfig.currentValue.quantityPerPage)
    }
  
  }

  disabledPrev() {
    if (this._pageConfig.activePage <= 1) {
      return true
    }
    return false
  }

  disabledNext() {
    if (this._pageConfig.quantityPerPage >= this._pageConfig.totalItens) {
      return true
    } else if ((this.finalAmount() - this.initialAmount()) < (this._pageConfig.quantityPerPage - 1)) {
      return true
    }
    return false
  }

  initialAmount() {    
    if (this._pageConfig.activePage < 1) {
      return 0
    } else if (this._pageConfig.activePage == 1) {
      return 1
    } else {
      return (this._pageConfig.quantityPerPage * this._pageConfig.activePage) - (this._pageConfig.quantityPerPage - 1)
    }
  }

  finalAmount() {
    if (this._pageConfig.activePage <= 1) {
      if (this._pageConfig.quantityPerPage >= this._pageConfig.totalItens) {
        return this._pageConfig.totalItens
      } else {
        return this._pageConfig.quantityPerPage
      }
    } else {
      if ((this._pageConfig.quantityPerPage * this._pageConfig.activePage) >= this._pageConfig.totalItens) {
        return this._pageConfig.totalItens
      } else {
        return this._pageConfig.quantityPerPage * this._pageConfig.activePage
      }
    }
  }

  activePage(type?){    
    //this._pageConfig.quantityPerPage = parseInt(this.selectPages.getRawValue().qtdPerPage)
    if(type=='next') {
      this._pageConfig.activePage = this._pageConfig.activePage + 1
      this.pressPrevNext.emit(this._pageConfig);
    } else if(type=="prev") {
      this._pageConfig.activePage = this._pageConfig.activePage - 1
      this.pressPrevNext.emit(this._pageConfig);
    } else {
      this._pageConfig.quantityPerPage = parseInt(this.selectPages.getRawValue().qtdPerPage)
      this.pressPrevNext.emit(this._pageConfig);
    }
  }

  changeQtPerPage() {        
    this._pageConfig.quantityPerPage = parseInt(this.selectPages.getRawValue().qtdPerPage)    
    this.pressPage.emit(this._pageConfig);
  }


  // pageConstructor(){
  //   let listPages = []
  //   for (let x = 1; x <= this._pageConfig; x++) {
  //     listPages.push(x)
  //   }
  //   return listPages
  // }

}