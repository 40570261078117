import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CONSTANTS } from 'src/app/core/constants/constants';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { environment } from '../../../../environments/environment';


export interface ReferenceFieldType {
  Id: number
  Code: string
  Name: string,
  Value: string
}

@Injectable({
  providedIn: 'root'
})
export class ReferenceFieldTypeService {


  constructor(
    private httpClient: HttpClient,
    private utilsService: UtilsService
    ) { }

  deleteRefFieldType(Id: number) {
    return this.httpClient.delete(`${environment.apiUrl}${CONSTANTS.ENDPOINT.REFERENCEFIELDTYPE}?Id=${Id}`, { observe: 'response' })
  }

  getReferenceFieldTypeListByInstance() {
    return this.httpClient.get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.REFERENCEFIELDTYPE}?InstanceId=${this.utilsService.getInstanceLoginId()}`, { observe: 'response' })
  }

  getReferenceFieldTypeListAll() {
    
    return this.httpClient.get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.REFERENCEFIELDTYPE}`, { observe: 'response' })
  }

  getReferenceFieldTypeList(page: string) {
    if (!page) {
      page = "?Page=1"
    }
    return this.httpClient.get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.REFERENCEFIELDTYPE}${page}`, { observe: 'response' })
  }

  getReferenceFieldType(referenceFieldType: ReferenceFieldType, page: any) {

    let filtro = (
      page ? `${page}` :
        `?Name=${(referenceFieldType['Name'] ? referenceFieldType.Name : '')}&Code=${(referenceFieldType['Code'] ? referenceFieldType['Code'] : '')}`
    )

    return this.httpClient.get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.REFERENCEFIELDTYPE}${filtro}`, { observe: 'response' })
  }

  updateRefFieldType(referenceFieldType: ReferenceFieldType) {
    return this.httpClient.put(`${environment.apiUrl}${CONSTANTS.ENDPOINT.REFERENCEFIELDTYPE}`, referenceFieldType, { observe: 'response' })
  }

  postRefFieldType(referenceFieldType: ReferenceFieldType) {
    return this.httpClient.post(`${environment.apiUrl}${CONSTANTS.ENDPOINT.REFERENCEFIELDTYPE}`, referenceFieldType, { observe: 'response' })
  }


}
