import { Injectable, Inject } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent,
  HttpEvent,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from "rxjs/operators";
import { LoaderService } from '../../components/loader/loader.service';
import { API_BASE_URL } from 'src/app/services/config.service';
import { StorageService } from '../storage/storage.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  // importar no core.module.ts para funcionar
  constructor(
    public loaderService: LoaderService,
    private storageService: StorageService,
    @Inject(API_BASE_URL) public baseUrl?: string,
  ) { }

  /** @description esta sendo usado para ativar loader na pagina quando houver requisições 
*/
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent |
    HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    
    let userName = 'omni.linker';
    let password = '@Admin00'

    // let headers = new HttpHeaders({ 
    //   'Authorization': `Basic ${btoa(`${userName}:${password}`)}`
    // })

    //verifica se o usuário possui token
    //  if(this.storageService.hasToken()){
    //   const token = this.storageService.getStorageToken()
    //   headers = headers.append('Token', token)          
    // }
    let dupReq
    
    try {
      if(this.storageService.hasToken() && !req.url.includes('UserNew')){   
        //change-password
        
        if(!req.url.includes('changePassword')){
          dupReq = req.clone({
            setHeaders: {             
              'Authorization': `Basic ${btoa(`${userName}:${password}`)}`,
              'Token': this.storageService.getStorageToken().replace(/(\r\n|\n|\r)/gm,"")
            },
          }); 
        } else {
          dupReq = req.clone({
            setHeaders: { 
              'Authorization': `Basic ${btoa(`${userName}:${password}`)}`,
            },
          });
        }
           
      }else if(this.storageService.hasToken() && req.url.includes('UserNew')){ 
            
        dupReq = req.clone({
          setHeaders: { 
            
            'Authorization': `Basic ${btoa(`${userName}:${password}`)}`,
            'Token': this.storageService.getStorageToken().replace(/(\r\n|\n|\r)/gm,"")
          },
        });      
      }else{
        // troacar senha
        
        dupReq = req.clone({
          setHeaders: { 
            'Authorization': `Basic ${btoa(`${userName}:${password}`)}`,
          },
        });
      }     
    } catch (error) {
      
      dupReq = req.clone({
        setHeaders: { 
          'Authorization': `Basic ${btoa(`${userName}:${password}`)}`,
        },
      });
    }

    // const url= req.url;
    // if (url.includes('Channel') || url.includes('EventType')) {
    //   dupReq = req.clone({
    //     setHeaders: {
    //       'Authorization': `Basic ${btoa(`${userName}:${password}`)}`,
    //       'Token': this.storageService.getStorageToken().replace(/(\r\n|\n|\r)/gm, ""),
    //     },
    //   });
    // } else {
    //   dupReq = req.clone({
    //     setHeaders: {
    //       'Authorization': `Basic ${btoa(`${userName}:${password}`)}`,
    //     },
    //   });
    // }

    return next.handle(dupReq).pipe(
      tap((ev: HttpEvent<any>) => {
        // if (ev instanceof HttpResponse) {
        //     setTimeout(function () {
        //         self.loaderService.hide()
        //     }, 100)
        // }
      })
    );
  }
}