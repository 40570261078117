import { createAction, props } from '@ngrx/store';
import { SearchFilterMessageLog } from '../models/SearchFilterMessageLog';

export enum FilterFlowGroupPanelActionTypes {
    ADDFILTER = '[Filter Flow Group Panel] Add Filter',
    CLEARFILTER = '[Filter Flow Group Panel] Clear Filter',
}


export const addFilter = createAction(
    FilterFlowGroupPanelActionTypes.ADDFILTER,
    props<{ searchFilterFlowGroupPanel: SearchFilterMessageLog }>()
)

export const clearFilter = createAction(
    FilterFlowGroupPanelActionTypes.CLEARFILTER,
    // props<{ searchFilterFlowGroupPanel: null }>()
)
