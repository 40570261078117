export class Grafics {
	title: string
	typeValue?: string | number
}

export class GraficPie extends Grafics  {
	series: PieSeries[]
}

export class GraficLine extends Grafics  {
	series: LineSeries[]
	labels: Array<string | number>
}

export class GraficBar extends Grafics  {
	series: Array<any>
}


export class LineSeries {
	name: string | number
	data: Array<number>
}

export class PieSeries {
	name: string | number
	y: number
}

// bar
// {
// 	title: "Gráfico de Linha",
// 	typeValue: "milhões",
// 	series: [
// 		['São Paulo', 12.0],
// 	]
// }

// pie
// {
// 	title: "Gráfico de Linha",
// 	series: [
// 		{
// 			name: 'Chrome',
// 			y: 61.41
// 		}
// 	]
// }

// line
// {
// 	title: "Gráfico de Linha",
// 	labels: ['2010', '2011', '2012', '2013','2014','2015', '2016','2017'],	
// 	series: [{
// 		name: 'Installation',
// 		data: [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175]
// 	}]
// }