import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

export interface Pagination {
  currentPage?: number
  nextPage?: string,
  previousPage?: string
  nextBtn?: boolean
  prevBtn?: boolean
}

export enum SearchMethod{
    Prev = 'Prev',
    Next = 'Next'
}

export interface PaginationConfig{
  pagination?: Pagination
    method?: string
}

@Component({
  selector: 'app-num-pag',
  templateUrl: './num-pag.component.html',
  styleUrls: ['./num-pag.component.scss']
})
export class NumPaginationComponent implements OnInit, OnChanges {
  qtdMessages: FormGroup
  pageOptions: any = [10,20,30,40,50]
  paginationConfig: PaginationConfig = {}
  @Input() paginationNum: any;
  @Output() qtdPerPage: EventEmitter<any> = new EventEmitter();

  constructor(    private fb: FormBuilder,
    ) {

      this.qtdMessages = this.fb.group({
        number: this.paginationNum ? this.paginationNum : 10
      })
     }

  ngOnInit() {

  }

  ngOnChanges(simpleChanges: SimpleChanges) { 
       
    // this.configurePagination(<Pagination>simpleChanges.pagination.currentValue)
  }

  configurePagination(pagination) {

    // if(pagination.nextPage){
    //   this.pagination.nextPage = pagination.nextPage
    //   this.pagination.nextBtn = true
    // }else{
    //   this.pagination.nextBtn = false
    // }

    // if(pagination.previousPage){
    //   this.pagination.previousPage = pagination.previousPage
    //   this.pagination.prevBtn = true
    // }else{
    //   this.pagination.prevBtn = false
    // }

    // this.paginationConfig.pagination = this.pagination;

   
  }

  configureQtdPerPage(method) {
   
    this.qtdPerPage.emit(method)

  }

}
