import { Component, OnInit, Input  } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {  
  faqIndex = Math.random().toString().slice(2, 18)
  @Input() faqContent = [];  
  @Input() titleSectionFaq: string = '';
  @Input() colorTitleFaq: string = ''
  @Input() colorBgFaq: string = ''  
 
  
  constructor() {

  }

  ngOnInit() {     
   
    if (this.colorBgFaq == ''){
      this.colorBgFaq = 'bg-success'      
    }
    if (this.colorTitleFaq == ''){
      this.colorTitleFaq = 'text-white'      
    }
  }  
}
