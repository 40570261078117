import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pagetitle',
  templateUrl: './pagetitle.component.html'
})

export class PageTitleComponent implements OnInit {

  @Input() titlePage: string;
  @Output() actionClick = new EventEmitter();

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
    //this.userName = JSON.parse(this.storageService.getStorageUser());    
  }

  sendToParent(){
    this.actionClick.emit('dashboard')
  }

}