<div class="stretch-card mb-3" (click)="getMessageLogDetails()" [ngStyle]="verifyStyleEmpty()">
    <div class="card">
        <div class="card-body text-left ">
            <div class="icon-container-chart">
                <div class="d-flex align-items-center stretch-card-title " *ngIf="setVisualization == visualizationTypes.FLOWGROUPPANEL">
                    <i class="mb-1 " [ngClass]="[messageLogTotal.InstanceFlowGroupIcone]"></i>
                    <h5 class="font-weight-medium">
                        {{messageLogTotal.InstanceFlowGroupName}}
                    </h5>
                </div>

                <div class="d-flex align-items-center stretch-card-title" *ngIf="setVisualization == visualizationTypes.FLOWPANEL">
                    <h5 class="m-0">
                        <strong>{{messageLogTotal.InstanceFlowName}}</strong>
                    </h5>

                </div>
                <p *ngIf="setVisualization == visualizationTypes.FLOWPANEL">
                    <small>
                       <b> {{'DEFAULT.FLOWGROUP'| translate}}: {{messageLogTotal.InstanceFlowGroupName}}</b>
                    </small>
                </p>
                <p *ngIf="setVisualization == visualizationTypes.FLOWPANEL">
                    <small>
                        {{'STRETCHCARD.SOURCE'| translate}} {{messageLogTotal.SourceSysName}} <br>
                        {{'STRETCHCARD.TARGET'| translate}}: {{messageLogTotal.TargetSysName}}
                    </small>
                </p>
            </div>
            <!-- <h1 class="font-weight-light">{{messageLogTotal.Name}}</h1> -->

            <div class="mt-4">
                <table class="text-left ">
                    <tr *ngFor="let column of displayedColumns">
                        <td>{{column}}</td>
                        <td>{{dataSource[column]}}</td>
                    </tr>
                </table>
                <!-- <table class="text-left ">
                    <tr>
                        <td>{{'STRETCHCARD.PROCESSING'| translate}}</td>
                        <td>{{messageLogTotal.TotProcessing}}</td>
                    </tr>
                    <tr>
                        <td>{{'STRETCHCARD.SUCCESS'| translate}}</td>
                        <td>{{messageLogTotal.TotSuccess}}</td>
                    </tr>
                    <tr>
                        <td>{{'STRETCHCARD.RETRYABLE'| translate}}</td>
                        <td>{{messageLogTotal.TotFinished}}</td>
                    </tr>
                    <tr>
                        <td>{{'STRETCHCARD.ERROR'| translate}}</td>
                        <td>{{messageLogTotal.TotError}}</td>
                    </tr>
                    <tr>
                        <td>{{'STRETCHCARD.DISCARDED'| translate}}</td>
                        <td>{{messageLogTotal.TotDiscarded}}</td>
                    </tr>
                    <tr>
                        <td>{{'STRETCHCARD.TOTAL'| translate}}</td>
                        <td>{{messageLogTotal.Total}}</td>
                    </tr>
                </table> -->
            </div>

        </div>
        <div class="card-bar" [ngStyle]="{'background-color': messageLogTotal.Color}
        "></div>
        <!-- <div class="card-bar" [ngClass]="{
            'success-border': messageLogTotal.Cor == 1,
            'processing-border': messageLogTotal.Cor == 2,
            'retryable-border': messageLogTotal.Cor == 3,
            'finished-border': messageLogTotal.Cor == 4,
            'error-border': messageLogTotal.Cor == 5,
            'discarded-border': messageLogTotal.Cor == 6
        }"></div> -->
    </div>
</div>

<!-- 
1-Success
2-Processing
3-Retryable
4-Finished
5-Error
6-Discarded 
-->