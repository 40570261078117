import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable } from 'rxjs';

import { StorageService } from '../storage/storage.service';
import { UtilsService } from '../../services/utils/utils.service';

@Injectable({ providedIn: 'root'})
export class AuthGuard implements CanActivate {

    constructor(
        private utilsService: UtilsService,
        private router: Router,
        private storageService: StorageService
    ) {}

    /** @description verifica se o usuario esta logado para liberar acesso a rota ou redirecionar para tela de login
	*/
    canActivate(
        route: ActivatedRouteSnapshot, 
        state: RouterStateSnapshot
    ): boolean | Observable<boolean> | Promise<boolean> {
        if(this.storageService.hasToken()){

            if (state.url === '/login') {
                this.router.navigate(['app']);
                return true;
            } else {
                //this.utilsService.showAlert('W', 'Alerta', 'Você precisa se logar para acessar esta pagina', null);
                return true;
            }
            
        } else {
            if (state.url === '/login') {
                return true;
            } else {
                this.router.navigate(['login']);
                this.utilsService.showAlert('W', 'Alerta', 'Efetue o login para acessar o sistema', null);
                return false;
            }
        }
    }

}