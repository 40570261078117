import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CONSTANTS } from '../../../core/constants/constants'
import { environment } from '../../../../environments/environment';
export interface Channel {
    Name: string;
    Code?: string
    Id?: number
  }

@Injectable({ providedIn: 'root' })
export class CanalService {

    constructor(private httpClient: HttpClient) {

    }

    getChannelList(page:string) {
        if(!page){
            page="?Page=1"
        }
        return this.httpClient
            .get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.CHANNEL}${page}`, {observe: 'response'} )
               
    }

    getChannelListAll(){
        return this.httpClient
            .get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.CHANNEL}`, {observe: 'response'} )
    }
    getChannel(channel: Channel, page){
        let filtro = (page? `${page}`: `?Name=${(channel['Name'] ? channel.Name : '')}&Code=${(channel['Code'] ? channel.Code : '')}`)
        return this.httpClient.get<any>(`${environment.apiUrl}${CONSTANTS.ENDPOINT.CHANNEL}${filtro}`,{observe: 'response'});
    }
    updateChannel(objeto) {
        return this.httpClient.put(`${environment.apiUrl}${CONSTANTS.ENDPOINT.CHANNEL}`, objeto, { observe: 'response' });
    }
    deleteChannel(Id: number) {
        return this.httpClient.delete(`${environment.apiUrl}${CONSTANTS.ENDPOINT.CHANNEL}?Id=${Id}`, { observe: 'response' });
    }

     postChannel(postObj) {        
        
        let channelObj = postObj
        Object.keys(channelObj).forEach((k) => channelObj[k] == "" && delete channelObj[k]);
        return this.httpClient.post(`${environment.apiUrl}${CONSTANTS.ENDPOINT.CHANNEL}`, channelObj, { observe: 'response' });
    }
}