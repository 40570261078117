import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { CONSTANTS } from 'src/app/core/constants/constants';
import { environment } from '../../../../environments/environment';


export interface Interval {
  Value: number,
  Unity: number,
}

export interface UnityTranslate {
  HourBr: string
  HourEn: string
  DayBr: string
  DayEn: string
}
export const UNITYTRANSLATE = {
  HourBr: 'Hora(s)',
  HourEn: 'Hour(s)',
  DayBr: 'Dia(s)',
  DayEn: 'Day(s)'
}
  
  


// export enum UNITYTRANSLATE {
//   HourBr = 'Hora(s)',
//   HourEn = 'Hour(s)',
//   DayBr = 'Dia(s)',
//   DayEn = 'Day(s)'
// }

export class InstanceInterval {
  InstanceId: number
  Interval: Interval[]
  UnityTranslate?: UnityTranslate = UNITYTRANSLATE
}

@Injectable({
  providedIn: 'root'
})
export class InstanceIntervalService {

  constructor(
    private httpClient: HttpClient,
    private utilsService: UtilsService
  ) { }

  postInstanceInterval(instanceInterval: InstanceInterval) {
    instanceInterval.InstanceId = Number(this.utilsService.getInstanceLoginId())
    // instanceInterval.Interval = [...instanceInterval['Interval'][0]]
    return this.httpClient.post(`${environment.apiUrl}${CONSTANTS.ENDPOINT.INTERVAL}`, instanceInterval, { observe: 'response' })
  }

  getInstanceInterval() {
    return this.httpClient.get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.INTERVAL}?InstanceId=${this.utilsService.getInstanceLoginId()}`, { observe: 'response' })
  }

  deleteInstanceInterval({InstanceId}){
    return this.httpClient.delete(`${environment.apiUrl}${CONSTANTS.ENDPOINT.INTERVAL}?InstanceId=${InstanceId}`,  { observe: 'response' })
  }

  updateInterval() {

  }
}
