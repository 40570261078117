import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CONSTANTS } from 'src/app/core/constants/constants';
import { InstanceSystem } from '../instance-system/instance-system.service';
import {environment} from '../../../../environments/environment';

export interface Icon {
  name: string,
  class: string,
}

export interface EventType {
  Id: number,
  BrName: string,
  UsName: string,
  StatusId: number,
  DefaultStatusName: string,
  DefaultStatusState: string,
  Code?: string
}

@Injectable({
  providedIn: 'root'
})
export class EventTypeService {


  constructor(private httpClient: HttpClient) { }

  getEventTypeListAll() {
    return this.httpClient
      .get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.EVENTTYPE}`, { observe: 'response' })
  }
  getEventTypeByStatus(statusIds){
    let filter = `?StatusId=${statusIds}`
    return this.httpClient
    .get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.EVENTTYPE}${filter}`, { observe: 'response' })
  }

  getEventTypeList(page: string) {
    if(!page){
      page="?Page=1"
    }
    
    return this.httpClient
      .get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.EVENTTYPE}${page}`, { observe: 'response' })
  }

  getEventType(eventType: EventType, page) {
    let filtro = this.createFiltroGetEventType(eventType, page)
    return this.httpClient.get<any>(`${environment.apiUrl}${CONSTANTS.ENDPOINT.EVENTTYPE}${filtro}`, { observe: 'response' });
  }




  private createFiltroGetEventType(eventType: EventType, page: string): string {

    if (page) {
      return page;
    }
    let filtro = "";

    filtro = `?Page=1&BrName=${(eventType.BrName ? eventType.BrName : '')}&UsName=${(eventType.UsName ? eventType.UsName : '')}&StatusId=${(eventType.StatusId != null ? eventType.StatusId : ``)}&Code=${(eventType.Code ? eventType.Code : '')}`
    return filtro
  }

  updateEventType(eventType: EventType) {
    let tempEventType = this.deleteEventTypeProperties(eventType, 'put')
    
    return this.httpClient.put(`${environment.apiUrl}${CONSTANTS.ENDPOINT.EVENTTYPE}`, tempEventType, { observe: 'response' });
  }

  deleteEventType(Id: number) {
    return this.httpClient.delete(`${environment.apiUrl}${CONSTANTS.ENDPOINT.EVENTTYPE}?Id=${Id}`, { observe: 'response' });
  }

  postEventType(eventType: EventType) {

    let tempEventType = this.deleteEventTypeProperties(eventType, 'post')
    return this.httpClient.post(`${environment.apiUrl}${CONSTANTS.ENDPOINT.EVENTTYPE}`, tempEventType, { observe: 'response' });
  }

  private deleteEventTypeProperties(eventType: EventType, metodo: string): InstanceSystem {

    if (metodo == 'post') {
      delete eventType.Id;
      delete eventType.DefaultStatusName;
      delete eventType.DefaultStatusState;
    }
    if (metodo == 'put') {
      delete eventType.DefaultStatusName;
      delete eventType.DefaultStatusState;
    }

    return eventType
  }

}