import { createAction, props } from '@ngrx/store';
import { SearchFilterMessageLog } from '../models/SearchFilterMessageLog';

export enum FilterLogsActionTypes {
    ADDFILTER = '[Filter Logs Component] Add Filter',
    CLEARFILTER = '[Filter Logs Component] Clear Filter',
}

export const addFilter = createAction(
    FilterLogsActionTypes.ADDFILTER,
    props<{ searchFilterMessageLog: SearchFilterMessageLog }>()
)

export const clearFilter = createAction(
    FilterLogsActionTypes.CLEARFILTER,
    props<{ searchFilterMessageLog: null }>()
)
