import { environment } from '../../../../environments/environment';
// import { environmentProd } from '../../../../environments/environment.prod';

// C:\Users\Wagner\Documents\Monitor\app\confluence-monitor-frontend\src\environments

const mook = true;

//// rotas local //
let apiMook = "";
let api = "";
// let apiProd = ""
//// rotas local //


// switch (window.location.host) {
//   case 'conf-win-apps.westus.cloudapp.azure.com':
//     // apiMook = "/assets/mockJson/";
//     // api = "mtr";
//     // apiProd = "http://3.209.190.192:9001/"
//     apiProd = "http://conf-iris-docker-hom.westus.cloudapp.azure.com:52774/monitor/api/"
//     break;
//   case 'conf-win-apps.westus.cloudapp.azure.com:8086':
//     // apiMook = "/assets/mockJson/";
//     // api = "mtr";
//     // apiProd = "http://3.209.190.192:9001/"
//     apiProd = "http://conf-iris-docker-hom.westus.cloudapp.azure.com:52773/monitor/api/"
//     break;
//   default:
//     apiMook = "/assets/mockJson/";
//     // api = "http://18.212.30.119/";
//     apiProd = "http://conf-iris-docker-hom.westus.cloudapp.azure.com:52773/monitor/api/"
// }

// const apiProd = environment.apiUrl;


export const ENDPOINT = {
  GENERICROUTE: `${api}`,
  COLABORADOR: `${api}colab`,
  FILIAISEND: `${api}filial`,
  GETLOGIN: `${api}getlog`,


  // Ocorrencia
  OCORRENCIA: `${api}monitor/issue/search`,
  GETDETAIL: `${api}Monitor/Issue/Detail`,

  // Menu
  MENU: `${api}Monitor/Menu`,

  // user
  USUARIOSLISTA: `${api}Monitor/User/Search`,
  USUARIOSUPDATE: `${api}Monitor/User/Update`,
  USUARIOSCREATE: `${api}Monitor/User/Insert`,
  USUARIOSDELETE: `${api}Monitor/User/Delete`,
  GETGROUPTEC: `${api}Monitor/IssueGRP/Search`,
  GETGROUPLOC: `${api}Monitor/MonitoringGRP/Search`,
  GETUSERGROUPTEC: `${api}Monitor/UserXIssueGRP/Search`,
  GETUSERGROUPLOC: `${api}Monitor/UserXMonitoringGRP/Search`,
  MEETUSERGROUPTEC: `${api}Monitor/UserXIssueGRP/Insert`,
  MEETUSERGROUPLOC: `${api}Monitor/UserXMonitoringGRP/Insert`,

  //dashboard
  INTEGRATIONSFLOWS: `${api}Monitor/Dashboard/Integrations`,
  GETINTEGRANTIONSGROUP: `${api}Monitor/Dashboard/Integrations`,
  CHARTPIE: `${api}Monitor/Dashboard/ChartPizza`,
  CHARTLINE: `${api}Monitor/Dashboard/ChartLine`,

  // perfil
  PERFISLISTA: `${api}Monitor/Profile/Search`,
  PERFISUPDATE: `${api}Monitor/Profile/Update`,
  PERFISCREATE: `${api}Monitor/Profile/Insert`,
  PERFISDELETE: `${api}Monitor/Profile/Delete`,
  // Cadastro > Grupo Local Monitoramento
  GROUPMONITORLOCATION: `${api}Monitor/Location/Search`,
  GROUPMONITORINTEG: `${api}Monitor/Integration/Search`,
  GROUPMONITORSEARCH: `${api}Monitor/MonitoringGRP/Search`,
  GROUPMONITORUPDATE: `${api}Monitor/MonitoringGRP/Update`,
  GROUPMONITORCREATE: `${api}Monitor/MonitoringGRP/Insert`,
  GROUPMONITORDELETE: `${api}Monitor/MonitoringGRP/Delete`,

  // Cadastro > Integração
  INTEGSEARCH: `${api}Monitor/Integration/Search`,
  INTEGUPDATE: `${api}Monitor/Integration/Update`,
  INTEGCREATE: `${api}Monitor/Integration/Insert`,
  INTEGDELETE: `${api}Monitor/Integration/Delete`,

  // Cadastro > Local
  LOCALSEARCH: `${api}Monitor/Location/Search`,
  LOCALUPDATE: `${api}Monitor/Location/Update`,
  LOCALCREATE: `${api}Monitor/Location/Insert`,
  LOCALDELETE: `${api}Monitor/Location/Delete`,


  // error
  ERROSLISTA: `${api}Monitor/IssueType/Search`,
  ERROSCREATE: `${api}Monitor/IssueType/Insert`,
  ERROSUPDATE: `${api}Monitor/IssueType/Update`,
  ERROSDELETE: `${api}Monitor/IssueType/Delete`,

  //Channel
  CHANNEL: `Channel/`,
  FILTER: `Filter/`,
  INSTANCE: `Instance/`,

  SITE: `Site/`,
  SITEGROUP: `SiteGroup/`,
  USERSITEGROUP: 'UserSiteGroup/',
  STATUS: `Status/`,

  INSTANCESYSTEM: `InstanceSystem/`,
  EVENTTYPE: `EventType/`,
  //FLOW
  FLOWGROUP: `InstanceFlowGroup/`,
  INSTANCEFLOW: `InstanceFlow/`,
  SYSTEM: `StandardSystem/`,
  REFERENCEFIELDTYPE: `ReferenceFieldType/`,
  INSTANCEFLOWREFFIELD: `InstFlowRefField/`,
  MESSAGELOG: `MessageLog/`,
  MESSAGEPURGE: `PurgeMessage/`,
  FIELDNOTE: `TranslatorTechnicalNotes/`,
  REPROCESSING: `Reprocessing/`,
  NOTIFICATIONTYPE: `NotificationType/`,
  NOTIFICATIONNETWORK: `NotificationNetwork/`,
  NOTIFICATION: `Notification/`,
  NOTIFICACAO: `Notificacao/`,
  CAQUESTOR: `ChartOfAccountsQuestor/`,
  CASAP: `ChartOfAccountsSAP/`,
  PCQUESTOR: `ProfitCenterQuestor/`,
  PCSAP: `ProfitCenterSAP/`,
  PARAMETERIZATION: `QuestorFromTo/`,
  LOGACCESS: `LogAccess/`,
  NOTIFICATIONUSER: `NotificationUser/`,
  FROMTO: `FromTo/`,
  REFERENCEFROMTO: `ReferenceFromTo/`,
  B1LCMBATCH: `B1LcmBatch/`,
  NOTIFICATIONINSTFLOW: `NotificationInstanceFlow/`,
  NOTIFICATIONNETWORKNOT: `NotificationNetworkNotification/`,
  NOTIFICATIONTYPENOT: `NotificationTypeNotification/`,
  USER: `User/`,
  INTERVAL: `Interval/`,
  USERNEW: `UserNew/`,
  USERPROFILELEVELPERMISSION: 'UserProfileLevelPermission/',
  INSTANCENEWUSER: 'UserInstance/',
  USERFIXEDFIELD: 'UserFixedField/fields/',
  USERINSTANCEFLOWGROUP: 'UserInstanceFlowGroup/',
  USERINSTACEFLOW: 'UserInstanceFlow/',
  USERFIXEDFIELDS: 'UserFixedField/',
  // LOGIN: `User/`,
  LOGIN: `Login/`,
  FORGETPASSWORD: `Login/forgetPassword`,
  CHANGEPASSWORD: `Login/changePassword`,
  PROFILEPERMISSION: 'ProfilePermission/',
  LEVELPERMISSION: 'LevelPermission/',
  USERREFFIEL: 'UserRefField/',
  SAVEREPROCESSING: `SaveReprocessing/`,
  SAVEDISCARD: `SaveDiscard/`,
  SEARCHLOG: `Log/`
};

export const MOOKENDPOINT = {
  LOGIN: `${apiMook}login.json`,
  ESTADOS: `${apiMook}estados.json`,
  MONITORAMENTOINTEG: `${apiMook}integracao.json`,
  MONITORAMENTOLOCAL: `${apiMook}local.json`,
  GENERICMOCK: `${apiMook}`,
  GETLOGEDUSER: `${apiMook}login.json`,
  MONITORPANEL: `${apiMook}cadastro/monitorPanel.json`,
  GETENABLEINTEGRATIONS: `${apiMook}integrationsConfig.json`,
  GETENABLESITES: `${apiMook}sites.json`,

  //dash
  INTEGRATIONSFLOWS: `${apiMook}dash/flow`,
  INTEGRATIONSMESSAGELOGS: `${apiMook}dash/messageLog`,

  //cadastro
  MATRIZ: `${apiMook}cadastro/matriz.json`,
  GETSAVEDSEARCH: `${apiMook}savedSearchs.json`,
  PERFISLISTA: `${apiMook}cadastro/perfilLista.json`,
  PERFILCADASTRO: `${apiMook}cadastro/perfilCadastro.json`,
  USUARIOSLISTA: `${apiMook}cadastro/usuarioslista1.json`,
  USUARIOCADASTRO: `${apiMook}cadastro/usuariodet.json`,
  LOCAISLISTA: `${apiMook}cadastro/localLista.json`,
  LOCALCADASTRO: `${apiMook}cadastro/localCadastro.json`,
  MONITORLIST: `${apiMook}cadastro/monitorList.json`,
  MONITOREGISTER: `${apiMook}cadastro/monitorReg.json`,
  GROUPTEC: `${apiMook}cadastro/groupteclist.json`,
  GROUPLOC: `${apiMook}cadastro/grouploclist.json`,

  INTEGRATIONS: `${apiMook}cadastro/integrationlist.json`,
  TYPESERROR: `${apiMook}cadastro/typeserror.json`,
  STATUSLIST: `${apiMook}cadastro/statuslist.json`,
  ERROSLISTA: `${apiMook}cadastro/erroslist.json`,
  ERROSDET: `${apiMook}cadastro/errosdet.json`,

  INTEGRACAOLISTA: `${apiMook}cadastro/integracaoLista.json`,
  INTEGRACAOCADASTRO: `${apiMook}cadastro/integracaoCadastro.json`,
  FONTAWESOMELIST: `${apiMook}fontAwesome-icon-list.json`

};