import { Directive, ElementRef, Renderer2, OnInit, OnChanges, SimpleChanges, Input, HostListener, Host } from '@angular/core';
import { Observable } from 'rxjs';
import { AppStore } from '../layout/interno/apploged.store';

@Directive({
  selector: '[appTheme]'
})
export class ThemeDirective implements OnInit, OnChanges {

  // theme$: Observable<string>
  
  @Input() theme:string;

  constructor(
    private eleRef: ElementRef,
    private renderer: Renderer2,
    private store: AppStore
  ) { 
    
  }

  ngOnInit(): void {    
    //this.theme$ = this.store.getUserSettings()
  }

  ngOnChanges() {
    
    // let container = document.querySelector('.container-scroller')
    // container.classList.add('light-theme')
    if (this.theme == 'light') {      
      this.renderer.addClass(this.eleRef.nativeElement, 'light-theme')
    } else {
      this.renderer.removeClass(this.eleRef.nativeElement, 'light-theme')
    }
  }

}
