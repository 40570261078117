import { Component, OnInit } from '@angular/core';
import { LoaderService } from './loader.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html'  
})
export class LoaderComponent implements OnInit {
  color = 'info';
  mode = 'indeterminate';
  value = 50;
  bufferValue = 75;

  isLoading: Subject<boolean> = this.loaderService.isLoading;

  constructor(
    private loaderService: LoaderService
  ){}

  ngOnInit() {
    // setTimeout(()=>{
    //   this.loaderService.show()
    // }, 100)

    // setTimeout(()=>{
    //   this.loaderService.hide()
    // }, 10000)
  }

}
