import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// model
import { FilterLogsModal } from '../../models/FilterLogs';

@Injectable({
    providedIn: 'root'
})

export class AppStore {

    // Filter logs store
    private _savedFilterLogs: FilterLogsModal = new FilterLogsModal();
    private trackerSavedFilterLogs = new BehaviorSubject<any>(this._savedFilterLogs);

    private _savedSearchs: any = []
    private trackerSavedSearch = new BehaviorSubject<any>(this._savedSearchs);

    private _savedLogsSearchs: any = []
    private trackerLogsSavedSearch = new BehaviorSubject<any>(this._savedLogsSearchs);

    private _savedUserSettings: string = ''
    private trackerUserSettings = new BehaviorSubject<any>(this._savedUserSettings);

    constructor() {}

    // search flow saved
    getResultSearch() {
        return this.trackerSavedSearch.asObservable();
    }
    setResultSearch(newSearch) {   
        this.trackerSavedSearch.next(newSearch)
    }
    resetResultSearch(): void {
        this.trackerSavedSearch.next(this._savedSearchs);
    }

    // search site saved
    getResultLogsSearch() {
        return this.trackerLogsSavedSearch.asObservable();
    }
    setResultLogsSearch(newSearch) {   
        this.trackerLogsSavedSearch.next(newSearch)
    }
    resetResultLogsSearch(): void {
        this.trackerLogsSavedSearch.next(this._savedLogsSearchs);
    } 

    // Filter Logs metodos
    getResFilterLogs() { 
        return this.trackerSavedFilterLogs.asObservable();
    }
    setResFilterLogs(newSearch: FilterLogsModal) {   
        this.trackerSavedFilterLogs.next(newSearch)
    }
    resetResFilterLogs(): void {
        this.trackerSavedFilterLogs.next(this._savedFilterLogs);
    }   

    //User settings methods
    getUserSettings(){
        return this.trackerUserSettings.asObservable();
    }

    setUserSettings(userSettings){
        
        this.trackerUserSettings.next(userSettings);
    }
}