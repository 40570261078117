import { Injectable, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService{

  isLoading = new Subject<boolean>();

  /** @description exibe loader
	*/
  show() {
    this.isLoading.next(true);
   
  }

  /** @description oculta loader
	*/
  hide() {
    this.isLoading.next(false);
   
  }
}