import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { MessageLogTotalGroup, MessageLogTotalResult } from 'src/app/features/monitor/messageLog/message-log.service';
import { VisualizationTypes } from 'src/app/features/monitor/messageLog/filter-logs/filter-logs.component';
import { MatTableDataSource } from '@angular/material';
import { SearchFilterMessageLog } from 'src/app/models/SearchFilterMessageLog';

export enum ColorStatus {
  SUCCESS = 1,
  PROCESSING = 2,
  RETRYABLE = 3,
  FINISHIED = 4,
  ERROR = 5,
  DISCARDED = 6
}



@Component({
  selector: 'app-stretch-card',
  templateUrl: './stretch-card.component.html',
  styleUrls: ['./stretch-card.component.scss']
})
export class StretchCardComponent implements OnInit, OnChanges {

  @Input() messageLogTotal: MessageLogTotalGroup = {};
  @Input() setVisualization: VisualizationTypes
  @Input() nameCard: string = ''
  @Input() titleColor: string = ''
  @Input() iconCard: string = ''
  
  @Input() statusNames: string[] = []

  @Output() messageLogDetails = new EventEmitter<MessageLogTotalGroup>();

  // dataSource: MatTableDataSource<any>;
  dataSource: string[] = []

  displayedColumns: string[] = []
  columnsToDisplay: string[] = []
  ColorStatus = ColorStatus;
  visualizationTypes = VisualizationTypes
  idiomaAtual: string // guarda o idioma atual

  constructor(
    public translate: TranslateService
  ) {
    // const browserLang = translate.getBrowserLang();
    const browserLang = localStorage.getItem('appLanguage')
    this.idiomaAtual = browserLang
    translate.use(browserLang.match(/en|pt/) ? browserLang : 'en');
  }
  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    
  }
  
  ngOnInit() {
    //observa a mudança de idioma para alterar o campo select de Status
    this.translate.onLangChange
      .subscribe((event: LangChangeEvent) => {
        this.idiomaAtual = event.lang        
        this.configuraPropriedades()
    });
    
    this.configuraPropriedades()
  }

  verifyStyleEmpty(){
    return this.messageLogTotal.Color == "#CCC" ? {'cursor': 'not-allowed'  } : {'cursor':'pointer'}
  }

  //configura as propriedades conforme o idioma é alterado
  configuraPropriedades(){
    
    if(this.statusNames){
        const collection = this.messageLogTotal[localStorage.getItem('appLanguage') == 'pt'? 'BrCampos' : 'UsCampos']
        for (const item in collection){
            const  obj = this.statusNames.filter(x => x == item) 
            if(!obj.length && item != "Total"){
              delete collection[item]
            }
        }
    } 

    this.displayedColumns = this.recuperaNomeDasColunas(
      this.idiomaAtual == 'pt' ? this.messageLogTotal.BrCampos : this.messageLogTotal.UsCampos
    )
    
    // this.columnsToDisplay =  this.recuperaNomeDasColunas(
    //   this.idiomaAtual == 'pt' ? this.messageLogTotal.BrCampos : this.messageLogTotal.UsCampos
    // )
    
    this.dataSource = (
      this.idiomaAtual == 'pt' ? this.messageLogTotal.BrCampos : this.messageLogTotal.UsCampos
    )

    
  }

  recuperaNomeDasColunas(campos: any){
    
    const properties = Object.keys(campos)
    return properties
  }



  getMessageLogDetails() {
    // Correção Dashboard caso instanceFlow seja null
    if(this.messageLogTotal.SearchFilterMessageLog.instanceFlows === null){
      this.messageLogTotal.SearchFilterMessageLog.instanceFlows = []
    }
    this.messageLogDetails.emit(this.messageLogTotal)  
  }

}
