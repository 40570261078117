import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
//import { TimerService } from './timer.service';
//import { Subject } from 'rxjs';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html'
})
export class TimerComponent implements OnInit {

  @Input() getTimer: number;
  @Input() restart: boolean;

  color = 'info';
  mode = 'determinate';
  value = 0;
  bufferValue = 75;
  getTimerInterna: number;

  intervalTimer: any;
  amontToIncrease: number

  constructor(
    //private timerService: TimerService
  ){}

  ngOnInit() {
    //this.configLoader();
  }

  /** @description observa mudanças de estado no inputs do component 
	*/
  ngOnChanges(changes: SimpleChanges) {    
    this.getTimerInterna = this.getTimer;
    if(this.restart) {
      this.configLoader();
    }
    
  }

  configLoader(){
    this.value = 0;
    let timeInMinutes = this.getTimerInterna / 60000;
    this.amontToIncrease = 0.83 * timeInMinutes;
    if (this.intervalTimer) {
      clearInterval(this.intervalTimer);
    }
    this.intervalTimer = setInterval(() => {this.incriseLoader()}, 500);
    this.getTimerInterna = 0
    
  }

  incriseLoader() {
    if(this.value < 100) {
      this.value += this.amontToIncrease;
    }
  }

}
