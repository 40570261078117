import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageTitleComponent } from './pagetitle.component';


@NgModule({
  declarations: [ PageTitleComponent ],
  exports: [ PageTitleComponent ],
  imports: [
    CommonModule
  ]
})

export class PageTitleModule { }
