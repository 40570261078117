import { Component, OnInit, Input} from '@angular/core';
import { Location } from "@angular/common";

@Component({
  selector: 'app-back-button',
  templateUrl: './back.component.html',
})

export class BackButtonComponent implements OnInit {

  @Input() textbutton: string;

  constructor(
    private location: Location,
  ) { }

  ngOnInit(
    
  ) {
    if (!this.textbutton) {
      this.textbutton = 'Voltar'
    }
  }

  return() {
    window.history.back();
	}

}